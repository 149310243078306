import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment, IconButton, Stack, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToast } from '../../hooks/useToast';
import { changePassWord, resetPasswordUsingToken } from '../../services/user.service';
import authService from '../../services/auth.service';
import { PASSWORD_REGEX } from '../../utils/regex';
import Iconify from '../iconify';

ChangePassword.propTypes = {
  userId: PropTypes.number,
  oldPass: PropTypes.string,
  token: PropTypes.string,
};
export default function ChangePassword({ userId, oldPass, token = '' }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      oldPassword: oldPass || '',
      token: token || '',
      password: '',
      password2: '',
    },
  });

  const [showPassword, setShowPassword] = useState({ pwd1: false, pwd2: false, pwd3: false });
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const changePWD = (oldPassword, newPassword) => {
    if (token) {
      resetPasswordUsingToken(token, newPassword)
        .then(() => {
          showToast({
            message: t('successMessages.passwordUpdated'),
            severity: 'success',
            props: { hideToast },
          });
          navigate('/login', { replace: true });
        })
        .catch(() => {
          showToast({ message: t('errorMessages.errorUpdatingPassword'), severity: 'error', props: { hideToast } });
        });
    } else {
      changePassWord({ id: userId, oldPassword, newPassword })
        .then(() => {
          showToast({
            message: t('successMessages.passwordUpdated'),
            severity: 'success',
            props: { hideToast },
          });
          authService.logout(userId);
          navigate('/login', { replace: true });
        })
        .catch(() => {
          showToast({ message: t('errorMessages.enterCorrectOldPassword'), severity: 'error', props: { hideToast } });
        });
    }
  };
  const onSubmit = (data) => {
    changePWD(data.oldPassword, data.password);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Stack spacing={5}>
            <Stack spacing={2}>
              {!token && (
                <Box hidden={oldPass}>
                  <TextField
                    name="oldPassword"
                    label={t('pages.usersPage.form.oldPassword')}
                    autoComplete="current-password"
                    sx={{ width: '100%' }}
                    type={showPassword.pwd1 ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword({ pwd1: !showPassword.pwd1 })} edge="end">
                            <Iconify icon={showPassword.pwd1 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.oldPassword}
                    helperText={errors.oldPassword?.message || ''}
                    {...register('oldPassword', {
                      required: t('formControl.enterOldPassword'),
                    })}
                  />
                </Box>
              )}
              <TextField
                name="password"
                label={t('attributes.password')}
                autoComplete="new-password"
                type={showPassword.pwd2 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword({ pwd2: !showPassword.pwd2 })} edge="end">
                        <Iconify icon={showPassword.pwd2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message || ''}
                {...register('password', {
                  required: t('formControl.insertPassword'),
                  minLength: {
                    value: 6,
                    message: t('formControl.passwordMin'),
                  },
                  pattern: {
                    value: PASSWORD_REGEX,
                    message: t('formControl.passwordContains'),
                  },
                })}
              />
              <TextField
                name="password2"
                label={t('pages.usersPage.form.confirmPassword')}
                autoComplete="new-password"
                type={showPassword.pwd3 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword({ pwd3: !showPassword.pwd3 })} edge="end">
                        <Iconify icon={showPassword.pwd3 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password2}
                helperText={errors.password2?.message || ''}
                {...register('password2', {
                  required: t('formControl.confirmPassword'),
                  validate: (val) => {
                    if (watch('password') !== val) {
                      return t('formControl.passwordDontMatch');
                    }
                    return null;
                  },
                })}
              />
            </Stack>

            <Stack justifyContent="center" direction="row">
              <LoadingButton sx={{ px: 5 }} type="submit" variant="contained" disabled={!isValid}>
                {t('buttons.updatePassword')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </form>
    </>
  );
}
