import { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';

SelectInput.propTypes = {
  defaultValue: PropTypes.node,
  labelValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedInput: PropTypes.func,
  removeSection: PropTypes.func,
};

export default function SelectInput({ defaultValue, options, setSelectedInput, ...other }) {
  const [value, setValue] = useState(defaultValue || '');
  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedInput(event);
  };

  return (
    <RadioGroup {...other} value={value} onChange={handleChange}>
      {options?.map((option, index) => (
        <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.text} />
      ))}
    </RadioGroup>
  );
}
