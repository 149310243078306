import axios from 'axios';
import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/departments`;

// fetch departments

export const fetchDepartments = async () => {
  const result = await axios.get(url, { headers: getAuthorizationHeader() });
  return result.data;
};

// delete department

export const deleteDepartment = async (id) => {
  const result = await axios.delete(`${url}/${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};

// create department
export const createDepartment = async ({ name, description, image, createdBy }) => {
  const result = await axios.post(url, { name, description, image, createdBy }, { headers: getAuthorizationHeader() });
  return result.data;
};

// update department
export const updateDepartment = async ({ id, name, description, image, createdBy }) => {
  const result = await axios.put(
    `${url}/${id}`,
    { description, name, image, createdBy },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// get department by ID

export const getDepartmentById = async (depId) => {
  const res = await axios.get(`${url}/${depId}`, { headers: getAuthorizationHeader() });
  return res.data;
};

// Download departments as csv

export const downloadDepartments = async () => {
  const result = await axios.get(`${API_URL}/csv/download/departments`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};
