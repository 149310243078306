// ImageCompressor.js
const compressImage = (file, callback) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const img = new Image();
    img.src = e.target.result;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Adjust canvas dimensions to control the compression level
      canvas.width = img.width / 2; // Adjust as needed
      canvas.height = img.height / 2; // Adjust as needed

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Determine the file type based on the original file's extension
      const fileType = file.type;

      // Convert canvas content to a compressed data URL based on the original file type
      let compressedDataURL;
      if (fileType === 'image/jpeg') {
        compressedDataURL = canvas.toDataURL('image/jpeg', 0.7); // 0.7 is the quality (adjust as needed)
      } else if (fileType === 'image/png') {
        compressedDataURL = canvas.toDataURL('image/png');
      } else if (fileType === 'image/webp') {
        compressedDataURL = canvas.toDataURL('image/webp');
      } else {
        console.warn('Unsupported file type:', fileType);
        return;
      }

      // Invoke the callback with the compressed data URL
      callback(compressedDataURL);
    };
  };

  reader.readAsDataURL(file);
};

export default compressImage;
