import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import {
  Stack,
  Typography,
  TablePagination,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Paper,
  Box,
  Checkbox,
  Popper,
  MenuItem,
} from '@mui/material';
import { format } from 'date-fns';
import { convertLocalTime } from '../../utils/convertTime';
import Label from '../label';
import ModifyTicket from '../ticket/ModifyTicket';
import Scrollbar from '../scrollbar';
import Iconify from '../iconify';

import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { useToast } from '../../hooks/useToast';
import { getComparator, applySortFilter } from '../../utils/filterData';
import { DashboardToolbar } from '../../sections/@dashboard/toolbar';

import { fetchTicketsByDate, updateTicket, deleteTicket } from '../../services/ticket.service';
import { fetchDepartments } from '../../services/department.service';
import { fetchStatuses } from '../../services/status.service';
import { useAuth } from '../../hooks/useAuth';

const TABLE_HEAD = [
  { id: 'id', label: 'ticket.id', alignRight: false },
  { id: 'title', label: 'ticket.title', alignRight: false },
  { id: 'department', label: 'ticket.department', alignRight: false },
  { id: 'priority', label: 'ticket.priority', alignRight: false },
  { id: 'status', label: 'ticket.status', alignRight: false },
  { id: 'category', label: 'ticket.category', alignRight: false },
  { id: 'createdBy', label: 'ticket.createdBy', alignRight: false },
  { id: 'createdAt', label: 'ticket.createdAt', alignRight: false },
  { id: 'updatedAt', name: 'updatedAt', label: 'ticket.updatedAt', alignCenter: true },
  { id: 'options', label: 'ticket.options', alignCenter: true },
];

OnHoldTicketsPage.propTypes = {
  title: PropTypes.string,
};
export default function OnHoldTicketsPage({ title }) {
  const { id: currentUserId, role, departmentId } = useAuth();
  const [ticketsData, setTicketsData] = useState([]);
  const [openModifyTicket, setOpenModifyTicket] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [order, setOrder] = useState('asc');
  const { showToast, hideToast } = useToast();
  const [selected, setSelected] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [orderBy, setOrderBy] = useState('title');
  const [subFilterPopover, setSubFilterPopover] = useState({ department: null, status: null, priority: null });
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState('byTitle');
  const [filterTitle, setFilterTitle] = useState('');
  const [filterPopover, setFilterPopover] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('title');
  const [filterBy, setFilterBy] = useState('title');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusesList, setStatusesList] = useState([]);
  const [ticketsWithStatus, setTicketsWithStatus] = useState([]);
  const navigate = useNavigate();
  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  // fetch tickets
  const fetchData = ({ startDate, endDate, attributeName }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    start.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    fetchTicketsByDate({ startDate: start, endDate: end, attributeName })
      .then((res) => {
        setTicketsData(res);
        setPage(0);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadTicket'), severity: 'error', props: { hideToast } });
      });
  };
  const getThisWeek = () => {
    fetchData({
      startDate: start,
      endDate: end,
      attributeName: 'created',
    });
  };
  const getStatuses = (depId) => {
    fetchStatuses().then((res) => {
      setStatusesList(res.filter((status) => status.departmentId === depId || status.departmentId === 0));
    });
  };
  useEffect(() => {
    getThisWeek();
    getStatuses(departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // edit a ticket
  const modifyTicket = async (newTicketData) => {
    await updateTicket({
      id: newTicketData.id,
      title: newTicketData.title,
      description: newTicketData.description,
      priority: newTicketData.priority,
      createdAt: newTicketData.createdAt,
      status: newTicketData.status,
      department_id: newTicketData.department_id,
      createdBy: newTicketData.createdBy,
    });
    showToast({ message: t('successMessages.successUpdate'), severity: 'success', props: { hideToast } });
    getThisWeek();
    setSelectedItem(newTicketData);
  };
  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setFilterPopover(null);
  };

  const handleOpenSubFilter = (event, category) => {
    if (category === 'department') {
      setSubFilterPopover({ department: event.currentTarget });
    } else if (category === 'status') {
      setSubFilterPopover({ status: event.currentTarget });
    } else if (category === 'priority') {
      setSubFilterPopover({ priority: event.currentTarget });
    }
  };

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ status: null, priority: null, department: null });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredTickets.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDetails = (row) => {
    setSelectedItem(row);
    navigate(`/tickets/${row.id}`);
  };
  // fetch departments

  useEffect(() => {
    const fetchData = () => {
      fetchDepartments()
        .then((res) => {
          setDepartmentData(res);
        })
        .catch(() => {
          showToast({ message: t('errorMessages.couldntLoadTicket'), severity: 'error', props: { hideToast } });
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Function to update items with status
    const updateItemsWithStatus = () => {
      const updatedTickets = ticketsData.map((ticket) => {
        const statusObject = statusesList.find((status) => status.statusId === ticket.status_id);
        return { ...ticket, statusObject };
      });
      setTicketsWithStatus(
        updatedTickets.filter(
          (ticket) => ticket.statusObject?.systemMappedStatus === 'ONHOLD' && ticket.assignedTo === currentUserId
        )
      );
    };

    // Call the function
    updateItemsWithStatus();
  }, [ticketsData, statusesList, currentUserId]);
  const RemoveTicket = async (id) => {
    await deleteTicket(id);
    showToast({ message: t('successMessages.successTicketRemove'), severity: 'success', props: { hideToast } });
    getThisWeek();
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByTitle = (event) => {
    setPage(0);
    setFilterTitle(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ticketsData.length) : 0;

  const filteredTickets = applySortFilter(ticketsWithStatus, getComparator(order, orderBy), filterTitle, filterBy);

  const isNotFound = !filteredTickets.length && !!filterTitle;

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>
      {selectedItem ? (
        <ModifyTicket
          open={openModifyTicket}
          setOpen={setOpenModifyTicket}
          row={selectedItem}
          openEdit={setOpenModifyTicket}
          deleteTicket={RemoveTicket}
          modifyTicket={modifyTicket}
          departmentData={departmentData}
        />
      ) : null}

      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Stack>
        <DashboardToolbar getTicketsByDate={fetchData} start={start} end={end} />
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterTitle}
            filterBy={searchBy}
            handleCloseFilter={handleCloseFilter}
            handleOpenFilter={handleOpenFilter}
            onFilterName={handleFilterByTitle}
            model={t('nav.tickets')}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredTickets.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredTickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      title,
                      department,
                      category,
                      statusObject,
                      priority,
                      createdAt,
                      customerName,
                      updatedAt,
                    } = row;
                    let statusName = '';
                    if (statusObject) {
                      statusName = statusObject.statusId < 6 ? t(`status.${statusObject.name}`) : statusObject.name;
                    }
                    const selectedTicket = selected.indexOf(id) !== -1;
                    const createdAtStr = format(convertLocalTime(new Date(createdAt)), 'd/M/yyyy HH:mm');
                    const updatedAtStr = updatedAt ? format(convertLocalTime(new Date(updatedAt)), 'd/M/yyyy HH:mm') : '';
                    return (
                      <TableRow
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleDetails(row)}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedTicket}
                      >
                        {role.authority !== 'ROLE_AGENT' && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              name="checkBox"
                              checked={selectedTicket}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleClick(event, id);
                              }}
                            />
                          </TableCell>
                        )}
                        <TableCell align="left">#{id}</TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{department}</TableCell>

                        <TableCell align="left">{t(`priority.${priority}`)}</TableCell>

                        <TableCell align="left">
                          <Label color={statusObject?.color || 'info'}>{statusName}</Label>
                        </TableCell>
                        <TableCell align="left">{category}</TableCell>
                        <TableCell align="left">{customerName}</TableCell>

                        <TableCell align="left">{createdAtStr}</TableCell>
                        <TableCell align="center">{updatedAtStr}</TableCell>
                        <TableCell align="center">
                          <Button variant="text" onClick={() => handleDetails(row)}>
                            {t('popover.moreDetails')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            {t('filters.notFound')}
                          </Typography>

                          <Typography variant="body2">
                            {t('filters.noResultsFound', { filterName: filterTitle })}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            labelRowsPerPage={t('filters.rowsPerPage')}
            count={filteredTickets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
      {/* main filter popover */}

      <Popper open={Boolean(filterPopover)} anchorEl={filterPopover} placement="left-start" onClose={handleCloseFilter}>
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,

              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          <MenuItem
            selected={selectedFilter === 'title'}
            onClick={() => {
              setFilterTitle('');
              setFilterBy('title');
              setSearchBy('byTitle');
              setSelectedFilter('title');
              handleCloseFilter();
            }}
          >
            <Typography variant="body2"> {t('filters.byTitle')}</Typography>
          </MenuItem>
          <MenuItem
            selected={selectedFilter === 'id'}
            onClick={() => {
              setFilterTitle('');
              setFilterBy('id');
              setSearchBy('byId');
              setSelectedFilter('id');
              handleCloseFilter();
            }}
          >
            <Typography variant="body2"> {t('filters.byId')}</Typography>
          </MenuItem>
          <MenuItem
            selected={selectedFilter === 'category'}
            onMouseEnter={handleCloseSubFilter}
            onClick={() => {
              setFilterTitle('');
              setFilterBy('category');
              setSearchBy('byCategory');
              setSelectedFilter('category');
              handleCloseFilter();
            }}
          >
            <Typography variant="body2"> {t('filters.byCategory')}</Typography>
          </MenuItem>
          <MenuItem
            selected={selectedFilter === 'department'}
            onMouseEnter={(e) => handleOpenSubFilter(e, 'department')}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon="eva:arrow-ios-back-outline" />
              <Typography variant="body2"> {t('filters.byDepartment')}</Typography>
            </Stack>
          </MenuItem>

          <MenuItem selected={selectedFilter === 'priority'} onMouseEnter={(e) => handleOpenSubFilter(e, 'priority')}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon="eva:arrow-ios-back-outline" />
              <Typography variant="body2"> {t('filters.byPriority')}</Typography>
            </Stack>
          </MenuItem>
        </Card>
      </Popper>
      {/* sub filter popover */}

      <Popper
        open={Boolean(subFilterPopover.department)}
        anchorEl={subFilterPopover.department}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {departmentData.map((department) => (
            <MenuItem
              key={department.id}
              onClick={() => {
                setFilterBy('department');
                setSearchBy('byDepartment');
                setSelectedFilter('department');
                setFilterTitle(department.name);
                handleCloseFilter();
                setPage(0);
              }}
            >
              {department.name}
            </MenuItem>
          ))}
        </Card>
      </Popper>
      <Popper
        open={Boolean(subFilterPopover.priority)}
        anchorEl={subFilterPopover.priority}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {[
            { value: 'LOW', display: t('priority.LOW') },
            { value: 'MEDIUM', display: t('priority.MEDIUM') },
            { value: 'HIGH', display: t('priority.HIGH') },
          ].map((priority, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setFilterBy('priority');
                setSearchBy('byPriority');
                setSelectedFilter('priority');
                setFilterTitle(priority.value);
                handleCloseFilter();
                setPage(0);
              }}
            >
              {priority.display}
            </MenuItem>
          ))}
        </Card>
      </Popper>
    </>
  );
}
