import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

function CSVUploadInstructions() {
   const { t } = useTranslation();
  return (
    <Box bgcolor="#c4ecbc" p={2} borderRadius={4} mb={2} boxShadow={3} color="#41aa35">
    
      <Typography variant="h5" gutterBottom sx={{ marginLeft: '20px' }}>
          {t('inputs.CSV.titleText')}
      </Typography>
      <Typography color="Black" sx={{ marginLeft: '40px' }} variant="body1">
          {t('inputs.CSV.tableDescriptionText')}
      </Typography>
     
      <TableContainer size="small" component={Paper}  sx={{ marginTop: '20px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>AppUserRole</TableCell>
              <TableCell>GivenName</TableCell>
              <TableCell>FamilyName</TableCell>
              <TableCell>PhoneNumber</TableCell>
              <TableCell>IsAvailable</TableCell>
              <TableCell>DepartmentId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>agent1</TableCell>
              <TableCell>agent1@example.com</TableCell>
              <TableCell>ROLE_AGENT</TableCell>
              <TableCell>John</TableCell>
              <TableCell>Doe</TableCell>
              <TableCell>12345678</TableCell>
              <TableCell>TRUE</TableCell>
              <TableCell>1</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CSVUploadInstructions;
