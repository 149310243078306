import { useRoutes } from 'react-router-dom';
// routes
import AdminRoutes from './AdminRoutes';
import AgentRoutes from './AgentRoutes';
import FirstLoginRoutes from './FirstLoginRoute';
import DepartmentManagerRoutes from './DepManagerRoutes';
import CommonRoutes from './CommonRoutes';
import CustomerRoutes from './CustomerRoutes';
import AuthRoutes from './AuthRoutes';
import { useAuth } from '../hooks/useAuth';

// ==============================|| ROUTING RENDER ||============================== //
// here you can choose which routes to return according to user role && if the user is authenticated
export default function Routes() {
  const { role: userRole, firstLogin } = useAuth();
  const role = userRole?.authority;
  let routes = [AuthRoutes];
  if (firstLogin) {
    routes = [FirstLoginRoutes];
  } else {
    if (role === 'ROLE_ADMIN') {
      routes = [AdminRoutes];
    }
    if (role === 'ROLE_CUSTOMER') {
      routes = [CustomerRoutes];
    }
    if (role === 'ROLE_AGENT') {
      routes = [AgentRoutes];
    }
    if (role === 'ROLE_DEPARTMENTMANAGER') {
      routes = [DepartmentManagerRoutes];
    }
    if (role === 'COMMON') {
      routes = [CommonRoutes];
    }
  }
  return useRoutes(routes);
}
