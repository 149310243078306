import { Autocomplete, TextField, Grid, Button, Typography } from '@mui/material';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function AdvancedFilter({ ticketsWithStatus , setFilterByData}) {
  const { t } = useTranslation();

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  // Memoizing Calculations
  const uniqueDepartments = useMemo(() => [...new Set(ticketsWithStatus.map(ticket => ticket.department))], [ticketsWithStatus]);
  const uniquePriorities = useMemo(() => [...new Set(ticketsWithStatus.map(ticket => ticket.priority))], [ticketsWithStatus]);
  const uniqueCategories = useMemo(() => [...new Set(ticketsWithStatus.map(ticket => ticket.category || ""))], [ticketsWithStatus]);
  const uniqueCreators = useMemo(() => [...new Set(ticketsWithStatus.map(ticket => ticket.customerName))], [ticketsWithStatus]);

  const filteredUniqueStats = useMemo(() => {
    const uniqueStats = new Set();
    const filteredStats = [];
    ticketsWithStatus.forEach(ticket => {
      // Create Map of unique status values paired with their ids
      const label = ticket.status_id < 6 ? t(`status.${ticket.statusObject?.name}`) : ticket.statusObject?.name;
      const value = ticket.status_id;
      // Remove duplicate status values
      if (!uniqueStats.has(label)) {
        uniqueStats.add(label);
        filteredStats.push({ label, value });
      }
    });
    return filteredStats;
  }, [ticketsWithStatus, t]);

  const applyFilter = () => {
    // If no filter criteria is selected, return unfiltered data
    if (!selectedDepartment && !selectedPriority && !selectedStatus && !selectedCategory && !selectedCreator) {
      // Set filtered data to the original data and exit the function
      setFilterByData(ticketsWithStatus);
      return;
    }
    // Combine all filter conditions into a single variable expression
    const filtered = ticketsWithStatus.filter(ticket => !(
        (selectedDepartment && ticket.department !== selectedDepartment) ||
        (selectedPriority && ticket.priority !== selectedPriority) ||
        (selectedStatus && ticket.status_id !== selectedStatus.value) ||
        (selectedCategory && selectedCategory !== 'No Options' && ticket.category !== selectedCategory) ||
        (selectedCreator && ticket.customerName !== selectedCreator)
      ));
    // Update the filtered data with the newly filtered results
    setFilterByData(filtered);
  };
  
  const resetFilter = () => {
    setSelectedDepartment(null);
    setSelectedPriority(null);
    setSelectedStatus(null);
    setSelectedCategory(null);
    setSelectedCreator(null);
    setFilterByData(ticketsWithStatus);
  };

  return (
    <div style={{ margin: '0 20px', marginBottom: '-30px' }}>
      <>
        <h3>{t('buttons.applyFilter')}</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>
              {t('attributes.department')}
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-department"
              options={uniqueDepartments}
              value={selectedDepartment}
              onChange={(event, newValue) => setSelectedDepartment(newValue)}
              renderInput={(params) => <TextField {...params} label={t('filterFormControl.departmentFilter')} size="small" />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>
              {t('attributes.priority')}
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-priority"
              options={uniquePriorities}
              value={selectedPriority}
              onChange={(event, newValue) => setSelectedPriority(newValue)}
              renderInput={(params) => <TextField {...params} label={t('filterFormControl.priorityFilter')} size="small" />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>
              {t('attributes.status')}
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-status"
              options={filteredUniqueStats}
              value={selectedStatus}
              onChange={(event, newValue) => setSelectedStatus(newValue)}
              renderInput={(params) => <TextField {...params} label={t('filterFormControl.statusFilter')} size="small" />}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>
              {t('attributes.category')}
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-category"
              options={uniqueCategories}
              value={selectedCategory}
              onChange={(event, newValue) => setSelectedCategory(newValue)}
              renderInput={(params) => <TextField {...params} label={t('filterFormControl.categoryFilter')} size="small" />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>
              {t('attributes.createdBy')}
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-created-by"
              options={uniqueCreators}
              value={selectedCreator}
              onChange={(event, newValue) => setSelectedCreator(newValue)}
              renderInput={(params) => <TextField {...params} label={t('filterFormControl.creatorFilter')} size="small" />}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={applyFilter}>
              {t('buttons.applyFilter')}
            </Button>
            <Button variant="contained" color="secondary" style={{ marginLeft: '10px' }} onClick={resetFilter}>
              {t('buttons.resetFilter')}
            </Button>
          </Grid>
        </Grid>
      </>
    </div>
  );
}
AdvancedFilter.propTypes = {
  ticketsWithStatus: PropTypes.array.isRequired, // Ensure ticketsWithStatus is an array and required
  setFilterByData: PropTypes.func.isRequired  // Ensure setFilterByData is a function and required
};
