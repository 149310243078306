import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { StompSessionProvider } from 'react-stomp-hooks';

// routes
import Routes from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import { ModalProvider } from './hooks/useModal';
import ScrollToTop from './components/scroll-to-top';

import { SnackbarProvider } from './hooks/useToast';
import { AuthProvider } from './hooks/useAuth';
import IdleLogout from './hooks/useIdleLogout';
// ----------------------------------------------------------------------

export default function App() {
  const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}/ws`;

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <AuthProvider>
            <SnackbarProvider>
              <ModalProvider>
                <IdleLogout>
                  <StompSessionProvider url={API_URL}>
                    <Routes />
                  </StompSessionProvider>
                </IdleLogout>
              </ModalProvider>
            </SnackbarProvider>
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
