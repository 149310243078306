import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Stack, Typography, Avatar, Card, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../hooks/useToast';
import { getFileByName } from '../../../services/file.service';
import Iconify from '../../iconify';
import convertLineReturnToHTML from '../../../utils/convertLineReturn';
import fileDownload from '../../../utils/fileDownload';

MediaMessage.propTypes = {
  message: PropTypes.string,
  time: PropTypes.string,
  user: PropTypes.object,
  isMine: PropTypes.bool,
};
export default function MediaMessage({ message, time, user, isMine }) {
  const [fileData, setFileData] = useState();
  const [imgUrl, setImgUrl] = useState('/assets/images/files/fileDefault.png');

  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();

  const extensions = ['.jpeg', '.jpg', '.png', '.gif'];

  const getFile = async () => {
    try {
      const file = await getFileByName(message);
      setFileData(file);
    } catch {
      showToast({
        message: t('errorMessages.cannotGetFile'),
        severity: 'error',
        props: { hideToast },
      });
    }
  };

  useEffect(() => {
    if (fileData) {
      extensions.forEach((ext) => {
        if (message.endsWith(ext)) {
          setImgUrl(URL.createObjectURL(fileData));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData]);

  useEffect(() => {
    getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack justifyContent="space-between">
      <Typography color="#4a4a4a" variant="caption" textAlign="center">
        {time}
      </Typography>
      <Stack direction="row" justifyContent="center" spacing={2}>
        {!isMine && <Avatar src={user.avatar ?? ''} alt="avatarUrl" sx={{ width: 30, height: 30 }} />}

        <Stack sx={{ width: '80%' }} spacing={1}>
          <Typography variant="body1" color="#4a4a4a" sx={{ textAlign: isMine ? 'right' : 'left' }}>
            {user.username}
          </Typography>
          <Card
            sx={{
              bgcolor: isMine ? '#757de8' : 'white',
              alignSelf: isMine ? 'flex-end' : 'flex-start',
              width: '70%',
              wordWrap: 'break-word',
              p: 2,
              borderRadius: 3,
            }}
          >
            {fileData && (
              <Stack spacing={2}>
                <img
                  style={{
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    maxWidth: 200,
                    maxHeight: 150,
                  }}
                  src={imgUrl}
                  alt=""
                />

                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      color: isMine ? 'white' : '#4a4a4a',
                    }}
                    textAlign="center"
                  >
                    {typeof message === 'string' ? convertLineReturnToHTML(message) : message}
                  </Typography>
                  <IconButton onClick={() => fileDownload(fileData, message)}>
                    <Iconify
                      sx={{ color: isMine ? 'white' : '#4a4a4a', height: '60', width: '60' }}
                      icon="material-symbols:download"
                    />
                  </IconButton>
                </Stack>
              </Stack>
            )}
          </Card>
        </Stack>
        {isMine && <Avatar src={user.avatar ?? ''} alt="avatarUrl" sx={{ width: 30, height: 30 }} />}
      </Stack>
    </Stack>
  );
}
