import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// @mui
import { IconButton, Popover, MenuItem, Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../iconify/Iconify';
import { useConfirmModal } from '../../../hooks/useModal';

// ----------------------------------------------------------------------

const StyledFormImage = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  padding: '24px',
});

// ----------------------------------------------------------------------

FormCard.propTypes = {
  form: PropTypes.object,
  removeForm: PropTypes.func,
  setSelectedForm: PropTypes.func,
  setOpenDetailedView: PropTypes.func,
};

export default function FormCard({ form, removeForm, setSelectedForm, setOpenDetailedView }) {
  const [open, setOpen] = useState(null);
  const { t } = useTranslation();
  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(false);
  };

  const { showConfirmModal } = useConfirmModal(
    t('pages.formPage.confirmDelete'),
    `${t('pages.formPage.areYouSure')} ${form.title} ?`,
    () => {
      removeForm(form.id);
      setOpen(false);
    }
  );
  return (
    <div style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <Box
          sx={{ pt: '100%', position: 'relative', cursor: 'pointer' }}
          onClick={() => {
            setSelectedForm(form);
            setOpenDetailedView(true);
          }}
        >
          <StyledFormImage alt={'form'} src={'/assets/icons/form.png'} />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Link
              color="inherit"
              underline="hover"
              onClick={() => {
                setSelectedForm(form);
                setOpenDetailedView(true);
              }}
              sx={{
                maxWidth: '100%', // Set the maximum width for the title
                overflow: 'hidden', // Hide any overflowing text
              }}
              title={form.title} // Set the title attribute to the full name
            >
              <Typography variant="h6">{form.title}</Typography>
            </Link>
            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e)}>
              <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>
          </Stack>
          <Typography variant="body1" noWrap>
            {form.description}
          </Typography>
        </Stack>
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setSelectedForm(form);
            setOpenDetailedView(true);
            handleCloseMenu();
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('popover.edit')}
        </MenuItem>

        <MenuItem onClick={showConfirmModal}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('popover.delete')}
        </MenuItem>
      </Popover>
    </div>
  );
}
