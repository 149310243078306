import { useTranslation } from 'react-i18next';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Box } from '@mui/material';
import { useState } from 'react';
import AllTicketsTab from '../../components/ticketTabs/AllTicketsTab';
import ActiveTicketsTab from '../../components/ticketTabs/ActiveTicketsTab';
import OnHoldTicketsTab from '../../components/ticketTabs/OnHoldTicketsTab';
import authService from '../../services/auth.service';

export default function TabTicketsPage() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('1');
  const role = authService.verifyUserRole();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="tickets tabs">
            <Tab label={t('ticketTabs.allTickets')} value="1" />
            {role === 'ROLE_AGENT' && <Tab label={t('ticketTabs.activeTickets')} value="2" />}
            {role === 'ROLE_AGENT' && <Tab label={t('ticketTabs.onHoldTickets')} value="3" />}
            {role === 'ROLE_ADMIN' && <Tab label={t('ticketTabs.othersTickets')} value="4" />}
            {role !== 'ROLE_CUSTOMER' && <Tab label={t('ticketTabs.myTickets')} value="5" />}
            {role === 'ROLE_DEPARTMENTMANAGER' && <Tab label={t('ticketTabs.unassignedTickets')} value="6" />}
            {role === 'ROLE_AGENT' && <Tab label={t('ticketTabs.departmentTickets')} value="7" />}
          </TabList>
        </Box>
        <TabPanel value="1">
          <AllTicketsTab title={t('ticketTabs.allTickets')} />
        </TabPanel>
        <TabPanel value="2">
          <ActiveTicketsTab title={t('ticketTabs.activeTickets')} />
        </TabPanel>
        <TabPanel value="3">
          <OnHoldTicketsTab title={t('ticketTabs.onHoldTickets')} />
        </TabPanel>
        <TabPanel value="4">
          <AllTicketsTab title={t('ticketTabs.othersTickets')} />
        </TabPanel>
        <TabPanel value="5">
          <AllTicketsTab title={t('ticketTabs.myTickets')} />
        </TabPanel>
        <TabPanel value="6">
          <AllTicketsTab title={t('ticketTabs.unassignedTickets')} />
        </TabPanel>
        <TabPanel value="7">
          <AllTicketsTab title={t('ticketTabs.departmentTickets')} />
        </TabPanel>
      </TabContext>
    </>
  );
}
