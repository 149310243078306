function debounce(callback, delay) {
  let timerId;

  // eslint-disable-next-line func-names
  return function (...args) {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}

export default debounce;
