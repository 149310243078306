import { Navigate, Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import FormPage from '../pages/FormPage';
import NewFormPage from '../pages/Form/NewFormPage';
import UserPage from '../pages/Admin/UserPage';
import LoginPage from '../pages/Common/LoginPage';
import Page404 from '../pages/Common/Page404';
import TicketsPage from '../pages/Common/TicketsPage';
import MyDepartment from '../pages/DepartmentManager/Dashboard';
import ProfilePage from '../pages/Common/ProfilePage';
import AssignmentsCalendar from '../pages/Agent/AssignmentsCalendar';
import TicketDetails from '../pages/Common/TicketDetails';
import Status from '../pages/DepartmentManager/Status';
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute';
import CreateTicketPage from '../pages/Common/CreateTicketPage';
// ----------------------------------------------------------------------

const DepManagerRoutes = {
  path: '/',
  element: <Outlet />,
  children: [
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <MyDepartment /> },
        { path: 'tickets', element: <TicketsPage /> },
        { path: 'user', element: <UserPage title="agents" /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'newTicket', element: <CreateTicketPage /> },
        { path: 'calendar', element: <AssignmentsCalendar /> },
        { path: 'forms', element: <FormPage /> },
        { path: 'NewForm', element: <NewFormPage /> },
        { path: 'Status', element: <Status /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    { path: 'tickets/:ticketId', element: <TicketDetails /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ],
};

export default DepManagerRoutes;
