import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect,useState } from 'react';
import {
  InputLabel,
  Button,
  Typography,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
} from '@mui/material';
import Papa from 'papaparse';
import { useToast } from '../../hooks/useToast';
import Iconify from '../iconify';
import { useConfirmModal } from '../../hooks/useModal';
import CSVUploadInstructions from './CSVUploadInstructions';

CSVInput.propTypes = {
  csvUpload: PropTypes.func,
  hideModal: PropTypes.func,
};

function CSVInput({ csvUpload, hideModal }) {
  const [fileData, setFileData] = useState(null);
  const [CSVPreview, setCSVPreview] = useState([]);
  const [templateInCSV, settemplateInCSV] = useState('');
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const { showConfirmModal } = useConfirmModal(
    t('inputs.CSV.confirmUsersUpload'),
    t('inputs.CSV.areYouSure', fileData ? { fileName: fileData.name } : { fileName: '' }),
    () => handleConfirm()
  );
  useEffect(() => {
    const headers = [
      "username", "email", "appUserRole", "givenName", "familyName", "phoneNumber", "isAvailable", "departmentId"
    ];
    const templateInCSV = Papa.unparse({
      fields: headers
    });
    settemplateInCSV(templateInCSV);
  },[]);
  const handleConfirm = () => {
    const formData = new FormData();
    formData.append('file', fileData);
    csvUpload(formData);
    setFileData(null);
    hideModal();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file && (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel')) {
        setFileData(file);
        Papa.parse(file, {
          worker: true,
          skipEmptyLines: true,
          complete(results) {
            setCSVPreview(results.data);
          },
        });
      } else {
        setFileData(null);
        showToast({ message: t('formControl.validCSV'), severity: 'error', props: { hideToast } });
      }
    }
  };
  const headers = CSVPreview[0];
  const rows = CSVPreview.slice(1);
  return (
    <div >
      <CSVUploadInstructions />
      {fileData ? (
        <>
          <Stack mx={2} spacing={1} direction="row" justifyContent="center" alignItems="center">
            <Iconify icon="bi:filetype-csv" />
            <Typography variant="h6">  {t('inputs.CSV.Uploadedfile')} : {fileData.name}</Typography>
          </Stack>
          <TableContainer
            component={Paper}
            sx={{
              padding: 2,
              my: 2,
              maxHeight: '50vh',
              overflow: 'auto',
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headers?.map((header, i) => (
                    <TableCell key={i}> {header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((rowData, i) => (
                  <TableRow key={i}>
                    {rowData?.map((data, i) => (
                      <TableCell key={i}>{data}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
      <Stack direction="row" justifyContent="center" spacing={2}>
        {fileData && (
          <Button component="span" variant="outlined" onClick={showConfirmModal}>
            {t('buttons.confirm')}
          </Button>
        )}
        <a href={`data:text/csv;charset=utf-8,${templateInCSV}`} download="users_template.csv">
              <Button
                sx={{ marginRight: '1vh' }}
                variant="outlined"
                startIcon={<Iconify icon="material-symbols:download" />}
              >
                {t('buttons.downloadUsersTemplate')}
              </Button>
        </a> 
        <InputLabel>
          <Button component="span" variant="outlined">
            {t('buttons.chooseFile')}
          </Button>
          <input
            hidden
            accept=".csv"
            style={{ display: 'none' }}
            id="image-file-input"
            type="file"
            onChange={handleFileChange}
          />
        </InputLabel>
      </Stack>
    </div>
  );
}

export default CSVInput;
