import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import QuestionCard from './QuestionCard';
import { useFormContext } from '../../../../hooks/useForm';

export default function QuestionList({ id: sectionId }) {
  const { getSectionQuestions, updateSectionQuestions } = useFormContext();
  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    setQuestions(getSectionQuestions(sectionId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSectionQuestions]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newQuestionsOrder = Array.from(questions);
    const [removed] = newQuestionsOrder.splice(result.source.index, 1);
    newQuestionsOrder.splice(result.destination.index, 0, removed);

    setQuestions(newQuestionsOrder);
    updateSectionQuestions(sectionId, newQuestionsOrder);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="question-cards">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {questions?.map((question, index) => (
              <QuestionCard key={question.id} question={question} sectionId={sectionId} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

QuestionList.propTypes = {
  id: PropTypes.string,
};
