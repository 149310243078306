import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// @mui
import { Container, Stack, Typography, Button, Pagination, Paper, Grid } from '@mui/material';

// services
import {
  fetchDepartments,
  deleteDepartment,
  createDepartment,
  updateDepartment,
  downloadDepartments,
} from '../../services/department.service';

import { useToast } from '../../hooks/useToast';
// utils
import { applySortFilter, getComparator } from '../../utils/filterData';
// components
import DepartmentsList from '../../sections/@dashboard/departments/DepartmentsList';
import AddDepartment from '../../components/departments/AddDepartment';
import { UserListToolbar } from '../../sections/@dashboard/user';

import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function DepartmentsPage() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [openAddDepartment, setOpenAddDepartment] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);

  const [filterName, setFilterName] = useState('');
  const [dataInCSV, setDataInCSV] = useState('');

  const { showToast, hideToast } = useToast();

  const itemsPerPage = 6;
  const order = 'asc';
  const orderBy = 'name';

  useEffect(() => {
    downloadDepartments().then((res) => {
      setDataInCSV(res);
    });
  }, []);

  // fetch departments
  const fetchData = () => {
    fetchDepartments()
      .then((res) => {
        setDepartmentData(res);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDep'), severity: 'error', props: { hideToast } });
      });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // created department

  const addNewDepartment = async (newDepartment) => {
    createDepartment({
      name: newDepartment.name,
      description: newDepartment.description,
      createdBy: newDepartment.createdBy,
      image: newDepartment.image,
    })
      .then(() => {
        showToast({ message: t('successMessages.successDepartmentUpdate'), severity: 'success', props: { hideToast } });
        fetchData();
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntCreateDep'), severity: 'error', props: { hideToast } });
      });
  };

  // edit a ticket

  const modifyDepartment = async (newDepartmentData) => {
    updateDepartment({
      id: newDepartmentData.id,
      name: newDepartmentData.name,
      description: newDepartmentData.description,
      image: newDepartmentData.image,
      createdBy: newDepartmentData.createdBy,
    })
      .then(() => {
        const newDepartmentList = departmentData.map((department) =>
          department.id === newDepartmentData.id
            ? {
                ...department,
                name: newDepartmentData.name,
                description: newDepartmentData.description,
                image: newDepartmentData.image,
                createdBy: newDepartmentData.createdy,
              }
            : department
        );
        showToast({ message: t('successMessages.successDepartmentUpdate'), severity: 'success', props: { hideToast } });
        setDepartmentData(newDepartmentList);
      })
      .catch(() =>
        showToast({ message: t('errorMessages.couldntUpdateDep'), severity: 'error', props: { hideToast } })
      );
  };

  // delete department
  const handleDelete = async (id) => {
    deleteDepartment(id)
      .then(() => {
        showToast({ message: t('successMessages.successDepartmentDelete'), severity: 'success', props: { hideToast } });
        const newDepartmentList = departmentData.filter((department) => department.id !== id);
        setDepartmentData(newDepartmentList);
        setPage(0);
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 406) {
            showToast({
              message: t('errorMessages.cantDeleteDepWithUsersOrTickets'),
              severity: 'error',
              props: { hideToast },
            });
          } else {
            showToast({ message: t('errorMessages.couldntDeleteDep'), severity: 'error', props: { hideToast } });
          }
        }
      });
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const filteredDepartments = applySortFilter(departmentData, getComparator(order, orderBy), filterName, 'name');
  const totalPages = Math.ceil(filteredDepartments.length / itemsPerPage);
  const isNotFound = !filteredDepartments.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> {t('nav.departments')} </title>
      </Helmet>
      <AddDepartment open={openAddDepartment} setOpen={setOpenAddDepartment} addDepartment={addNewDepartment} />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">{t('nav.departments')}</Typography>
          <Grid>
            <Button
              variant="contained"
              id="add-department-btn"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setOpenAddDepartment(true)}
              sx={{ marginRight: '1vh' }}
            >
              {t('buttons.newDepartment')}
            </Button>
            <a href={`data:text/csv;charset=utf-8,${dataInCSV}`} download="departments.csv">
              <Button variant="contained" startIcon={<Iconify icon="material-symbols:download" />}>
                {t('buttons.download')}
              </Button>
            </a>
          </Grid>
        </Stack>
        <UserListToolbar
          numSelected={0}
          filterName={filterName}
          onFilterName={handleFilterByName}
          model={t('nav.departments')}
        />
        <DepartmentsList
          departments={filteredDepartments}
          handleDelete={handleDelete}
          editDepartment={modifyDepartment}
          itemsPerPage={itemsPerPage}
          page={page}
        />
        {isNotFound && (
          <Paper
            sx={{
              my: 5,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" paragraph>
              {t('filters.notFound')}
            </Typography>

            <Typography variant="body2">{t('filters.noResultsFound', { filterName })}</Typography>
          </Paper>
        )}

        {filteredDepartments.length > 0 && (
          <Stack alignItems="center" sx={{ mt: 5 }}>
            <Pagination
              component="div"
              color="primary"
              count={totalPages}
              size="large"
              page={page + 1}
              onChange={handleChangePage}
            />
          </Stack>
        )}
      </Container>
    </>
  );
}
