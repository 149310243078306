import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubscription, useStompClient } from 'react-stomp-hooks';

// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Pagination,
  Stack,
} from '@mui/material';
// utils
import { convertLocalTime } from '../../../utils/convertTime';
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
// hooks
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';

// services
import {
  fetchNotificationsByUserId,
  markAllAsReadById,
  markNotificationReadById,
} from '../../../services/notification.service';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { id } = useAuth();
  const { showToast, hideToast } = useToast();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUnRead, setUnread] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const fetchNotifications = () => {
    fetchNotificationsByUserId(id, page, 10).then((res) => {
      setTotalPages(res.notificationPageable.totalPages);
      setNotifications(res.notificationPageable.content);
      setUnread(res.unseenNotifications);
    });
  };

  useSubscription(`/notification/sendNotif-${id}`, (message) => {
    showToast({
      message: JSON.parse(message.body).subject,
      severity: 'info',
      verticalPos: 'bottom',
      horizontalPos: 'right',
      props: { hideToast },
    });
    fetchNotifications();
  });

  const stompClient = useStompClient();
  window.addEventListener('beforeunload', () => {
    const navigationEntries = window.performance.getEntriesByType('navigation');
    const lastEntry = navigationEntries[navigationEntries.length - 1];

    const pageAccessedByReload =
      (window.performance.navigation && window.performance.navigation.type === 1) || lastEntry.type === 'reload';
    if (!pageAccessedByReload && stompClient) {
      stompClient.publish({
        destination: `/app/user-activity-${id}-False`,
      });
    }
  });

  if (stompClient) {
    stompClient.publish({
      destination: `/app/user-activity-${id}-True`,
    });
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleMarkAllAsRead = async (id) => {
    await markAllAsReadById(id);
    fetchNotifications();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleNotificationClick = async (id, ticketId, triggeredBy, type) => {
    await markNotificationReadById(id);
    if (triggeredBy !== 'DELETE' && ticketId !== 0 && ['STATUS', 'TICKET', 'MESSAGE'].includes(type))
      navigate(`/tickets/${ticketId}`);
    fetchNotifications();
  };

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
            height: '80%',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={() => handleMarkAllAsRead(id)}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <List>
          {notifications?.map((notification) => (
            <NotificationItem
              key={`notif${notification.id}`}
              notification={notification}
              handleNotificationClick={handleNotificationClick}
            />
          ))}
        </List>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Stack alignItems="center" sx={{ mt: 5 }}>
            {notifications.length > 0 && (
              <Pagination
                component="div"
                color="primary"
                count={totalPages}
                size="medium"
                page={page + 1}
                onChange={handleChangePage}
              />
            )}
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    isRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    triggerId: PropTypes.number,
    triggeredBy: PropTypes.string,
  }),
  handleNotificationClick: PropTypes.func,
};

function NotificationItem({ notification, handleNotificationClick }) {
  const { avatar, title } = renderContent(notification);
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => {
        handleNotificationClick(notification.id, notification.triggerId, notification.triggeredBy, notification.type);
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(Date.parse(convertLocalTime(notification.createdAt)))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.subject)}
      </Typography>
    </Typography>
  );

  // svg links
  const typeToAvatar = {
    TICKET: '/assets/icons/ic_notification_ticket.svg',
    INFORMATION: '/assets/icons/ic_notification_information.svg',
    FORM: '/assets/icons/ic_notification_form.svg',
    DEPARTMENT: '/assets/icons/ic_notification_department.svg',
    MAIL: '/assets/icons/ic_notification_mail.svg',
    MESSAGE: '/assets/icons/ic_notification_chat.svg',
  };
  const avatarUrl = typeToAvatar[notification.type] || typeToAvatar.INFORMATION;
  const avatar = avatarUrl ? <img alt={notification.title} src={avatarUrl} /> : null;

  return {
    avatar,
    title,
  };
}
