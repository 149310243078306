import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Stack, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import { useAuth } from '../../hooks/useAuth';
import { useFormContext } from '../../hooks/useForm';
import SectionPanel from './formViewer/SectionPanel';

FormPreview.propTypes = {
  disabled: PropTypes.bool,
  addTicket: PropTypes.func,
  handleBackdropOpen: PropTypes.func,
  handleBackdropClose: PropTypes.func,
};

export default function FormPreview({ disabled, addTicket, handleBackdropOpen, handleBackdropClose }) {
  const navigate = useNavigate();
  const { id } = useAuth();
  const { getOrderedSections, updateSectionValidity, getSectionQuestions, getFormData, formatAttachements } =
    useFormContext();
  const [sectionsList, setSections] = useState([]);
  const [submitSection, setSubmitSection] = useState(false);
  const [page, setPage] = useState(0);
  const { showToast, hideToast } = useToast();
  const [formData, setFormData] = useState([]);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const sectionsPerPage = 1;
  const { t } = useTranslation();

  useEffect(() => {
    const updateSections = getOrderedSections();
    setSections(updateSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormDataUpdate = () => {
    const updatedFormData = [
      ...formData,
      {
        sectionId: sectionsList[page].id,
        sectionName: sectionsList[page].name,
        questions: getSectionQuestions(sectionsList[page].id),
      },
    ];
    setFormData(updatedFormData);
    return updatedFormData;
  };

  const submitTicket = () => {
    const isValid = updateSectionValidity(sectionsList[page].id);
    if (!isValid) {
      showToast({
        message: t('formControl.makeSureAllFields'),
        severity: 'error',
        props: { hideToast },
      });
      return;
    }
    const attachements = formatAttachements();
    handleBackdropOpen();
    const updatedData = handleFormDataUpdate();
    const dt = getFormData();
    const { priority, duration, category, agents, autoAssign } = sectionsList[page];
    if (!isDebouncing) {
      setIsDebouncing(true);

      addTicket(
        id,
        updatedData,
        priority,
        duration,
        category,
        attachements,
        dt.formData.ticketDescription,
        dt.formData.department,
        agents,
        autoAssign
      )
        .then(() => {
          handleBackdropClose();
          showToast({ message: t('successMessages.successTicketAdd'), severity: 'success', props: { hideToast } });
          navigate('/dashboard/tickets');
        })
        .catch(() => {
          handleBackdropClose();
          showToast({
            message: t('errorMessages.errorCreatingTicket'),
            severity: 'error',
            props: { hideToast },
          });
        });
      setTimeout(() => {
        setIsDebouncing(false);
      }, 300);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling animation
    });
  };

  const handleNextPage = () => {
    const isValid = updateSectionValidity(sectionsList[page].id);

    if (!isValid) {
      showToast({
        message: t('formControl.makeSureAllFields'),
        severity: 'error',
        props: { hideToast },
      });
      return;
    }
    handleFormDataUpdate();
    setPage((prevPage) => prevPage + 1);
    scrollToTop();
  };

  const handlePrevPage = () => {
    const updatedFormData = formData.slice(0, -1);
    setFormData(updatedFormData);
    setPage((prevPage) => prevPage - 1);
    scrollToTop();
  };

  const totalSections = sectionsList.length;

  useEffect(() => {
    const submit = sectionsList[page]?.nextSection === 'submit';
    setSubmitSection(submit);
  }, [page, sectionsList]);

  return (
    <div>
      {sectionsList?.slice(page * sectionsPerPage, (page + 1) * sectionsPerPage).map((section, index) => (
        <SectionPanel
          key={index}
          section={section}
          sectionsList={sectionsList}
          page={page}
          setSections={setSections}
          setPage={setPage}
        />
      ))}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mt: 5 }} spacing={2}>
          {page > 0 && (
            <Button id="last-section-btn" ariant="contained" color="primary" onClick={handlePrevPage}>
              {t('buttons.back')}
            </Button>
          )}
          {page < Math.ceil(totalSections / sectionsPerPage) - 1 && !submitSection && (
            <Button id="next-section-btn" variant="contained" color="primary" onClick={handleNextPage}>
              {t('buttons.next')}
            </Button>
          )}
          {(page + 1 === sectionsList.length || submitSection) && (
            <Button
              id="submit-form-btn"
              variant="contained"
              sx={{ backgroundColor: '#9c27b0' }}
              onClick={submitTicket}
              disabled={disabled || isDebouncing}
            >
              {t('buttons.submit')}
            </Button>
          )}
        </Stack>
      </Container>
    </div>
  );
}
