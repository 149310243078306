export default function fileDownload(fileData, fileName) {
  // Check if blobData is available
  if (fileData) {
    const blob = new Blob([fileData], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    // Create a temporary anchor element to trigger the download
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up and remove the anchor element
    window.URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
  }
}
