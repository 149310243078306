import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import DateTimePickerInput from './DateTimePickerInput';
import SelectInput from './SelectInput';
import TextAreaInput from './TextAreaInput';
import RadioInput from './RadioInput';
import CheckboxInput from './CheckboxInput';
import FileInput from './FileInput';

const InputComponents = {
  TextInput,
  DateTimePickerInput,
  SelectInput,
  TextAreaInput,
  CheckboxInput,
  RadioInput,
  FileInput,
};

FormInput.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  handleSelect: PropTypes.func,
  onChange: PropTypes.func,
  setSelectedVals: PropTypes.func,
  setFormData: PropTypes.func,
  removeAttachements: PropTypes.func,
};

export default function FormInput({
  input,
  options,
  handleSelect,
  onChange,
  setSelectedVals,
  setFormData,
  removeAttachements,
}) {
  const Input = InputComponents[input.type];

  // Assign props dynamically depending on input type
  const props = useMemo(() => {
    switch (input.type) {
      case 'TextInput':
        return {
          type: 'text',
          defaultValue: input.inputValue || '',
          label: input.text,
          fullWidth: true,
          onChange,
        };
      case 'DateTimePickerInput':
        return {
          type: 'datetime',
          fullWidth: true,
          setSelectedVals,
          defaultValue: input.inputValue || '',
        };
      case 'SelectInput':
        return {
          type: 'select',
          options,
          setSelectedInput: handleSelect,
          fullWidth: true,
          defaultValue: input.inputValue || '',
          labelValue: input.text,
        };
      case 'TextAreaInput':
        return {
          type: 'textarea',
          fullWidth: true,
          defaultValue: input.inputValue || '',
          onChange,
        };
      case 'CheckboxInput':
        return {
          type: 'Checkbox',
          options,
          defaultValue: input.inputValue || [],
          setSelectedVals,
        };
      case 'RadioInput':
        return {
          type: 'radio',
          options,
          defaultValue: input.inputValue || '',

          setSelectedInput: onChange,
        };
      case 'FileInput':
        return {
          type: 'fileInput',
          setFilesList: setSelectedVals,
          setFormData,
          removeAttachements,
        };
      default:
        return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, options]);

  return <Input {...props} required={input.isRequired} />;
}
