import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Paper, Card, Stack, Box, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import { Duration } from '@js-joda/core';
import { format } from 'date-fns';
import { convertLocalTime } from '../../utils/convertTime';
import Label from '../label/Label';
import Iconify from '../iconify';

DetailsContent.propTypes = {
  row: PropTypes.object,
  openEdit: PropTypes.func,
  status: PropTypes.object,
  currentUserRole: PropTypes.string,
  currentUserId: PropTypes.number,
};

export default function DetailsContent({ row, openEdit, status, currentUserRole, currentUserId }) {
  const [updateDate, setUpdateDate] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (row?.createdAt) {
      const creatDate = format(convertLocalTime(new Date(row.createdAt)), 'dd/MM/yyyy HH:mm:ss');
      setCreatedDate(creatDate);
    }
    if (row?.updatedAt) {
      const upDate = format(convertLocalTime(new Date(row.updatedAt)), 'dd/MM/yyyy HH:mm:ss');
      setUpdateDate(upDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);
  let statusName = '';
  let statusDescription = '';
  if (status) {
    statusName = status?.statusId < 6 ? t(`status.${status?.name}`) : status?.name;
    statusDescription = status?.statusId < 6 ? t(`statusDescription.${status?.name}`) : status?.description;
  }
  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  return (
    <>
      <Box height="30%">
        <Stack height="100%" spacing={2} sx={{ pt: 2.5, px: 3 }}>
          <Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                sx={{
                  '@media (max-height: 670px)': {
                    fontSize: '1rem',
                  },
                }}
                px={1}
                variant="h4"
              >
                {row?.title || 'title'}
              </Typography>
              {currentUserRole === 'ROLE_AGENT' && row?.assignedTo !== currentUserId ? null : (
                <IconButton onClick={() => openEdit(true)}>
                  <Iconify
                    sx={{
                      height: 30,
                      width: 30,
                      color: '#212B36',

                      '&:hover': {
                        color: '#3f51b5',
                      },
                      '@media (max-height: 620px)': {
                        height: 20,
                        width: 20,
                      },
                    }}
                    icon={'eva:edit-2-outline'}
                  />
                </IconButton>
              )}
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="center"
              sx={{
                backgroundColor: copied ? '#229A16' : 'transparent',
                transition: 'background-color 0.3s',
                border: copied ? 0 : 1.5,
              }}
              mt={2}
              borderRadius={2.5}
              py={0.5}
              pl={1.5}
              width="80%"
              direction="row"
            >
              {copied ? (
                <Typography color="white" variant="body2">
                  {t('pages.ticketDetails.idCopied')}
                </Typography>
              ) : (
                <Typography variant="button">
                  {' '}
                  {t('pages.ticketDetails.ticketId')} : #{row?.id || ' No ID'}
                </Typography>
              )}

              <IconButton
                onClick={() => {
                  copyToClipboard(`#${row.id}`);
                }}
              >
                {copied ? (
                  <Iconify color="white" icon={'material-symbols:file-copy-outline-rounded'} />
                ) : (
                  <Iconify icon={'material-symbols:file-copy-outline-rounded'} />
                )}
              </IconButton>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Box sx={{}}>
              <Label
                sx={{
                  px: 4,
                  '@media (max-height: 620px)': {
                    height: 18,
                    fontSize: '0.5rem',
                  },
                }}
                color={status?.color}
              >
                {statusName}
              </Label>
            </Box>
            <Stack direction="row" justifyContent="center">
              <Iconify
                sx={{
                  '@media (max-height: 620px)': {
                    height: 17,
                    width: 17,
                  },
                }}
                icon={'eva:arrowhead-up-fill'}
                mr={1}
              />
              <Typography
                sx={{
                  '@media (max-height: 620px)': {
                    fontSize: '0.75rem',
                  },
                }}
                variant="body2"
              >
                {t(`priority.${row?.priority || 'low'}`)} {t('attributes.priority')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box height="70%">
        <Paper
          sx={{
            height: '98%',
            boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: 0,
            bgcolor: '#F9F9F9',
            width: '100%',
            alignSelf: 'center',
          }}
        >
          <Box>
            <Box height="30%">
              <Stack
                spacing={1}
                sx={{
                  '@media (max-height: 670px)': {
                    mb: 1.25,
                  },
                  mb: 5,
                  mt: 2,
                }}
              >
                <Stack sx={{ px: 2 }} direction="row">
                  <Iconify icon={'eva:alert-circle-outline'} sx={{ mr: 1, mt: 0.5 }} />
                  <Typography
                    sx={{
                      '@media (max-height: 620px)': {
                        fontSize: '1rem',
                      },
                    }}
                    variant="h6"
                  >
                    {t('attributes.status')} :
                  </Typography>
                </Stack>
                <Card sx={{ height: '10vh', width: '95%', alignSelf: 'center', p: 1.5, boxShadow: '0.5px 0.5px 2px' }}>
                  <Typography
                    sx={{
                      '@media (max-height: 620px)': {
                        fontSize: '0.75rem',
                      },
                    }}
                    color={status?.color || 'primary'}
                  >
                    {statusDescription}
                  </Typography>
                </Card>
              </Stack>
            </Box>
            <Box height="60%">
              <Stack spacing={1} height="150">
                <Stack sx={{ px: 2 }} height="10%" direction="row">
                  <Iconify icon={'eva:question-mark-circle-outline'} sx={{ mr: 1, mt: 0.5 }} />
                  <Typography
                    sx={{
                      '@media (max-height: 620px)': {
                        fontSize: '1rem',
                      },
                    }}
                    variant="h6"
                  >
                    {t('pages.ticketDetails.ticketInfo')}:
                  </Typography>
                </Stack>
                <Card
                  sx={{
                    width: '90%',
                    height: '30vh',
                    alignSelf: 'center',
                    p: 2,
                    boxShadow: '0.5px 0.5px 2px',
                    overflowY: 'scroll',
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
                    '&::-webkit-scrollbar': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      borderRadius: '3px',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                      },
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Stack spacing={1}>
                    <Stack spacing={2} direction="row">
                      <Typography
                        variant="body 1"
                        sx={{
                          '@media (max-height: 620px)': {
                            fontSize: '0.75rem',
                          },
                        }}
                      >
                        {t('pages.ticketDetails.postedBy')} :
                      </Typography>
                      <Stack spacing={1} direction="row">
                        <Iconify sx={{ mt: 0.4 }} icon="eva:person-outline" />
                        <Typography
                          sx={{
                            '@media (max-height: 620px)': {
                              fontSize: '0.75rem',
                            },
                          }}
                          variant="h6"
                        >
                          {row?.customerName || 'customer'}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack spacing={2} direction="row">
                      <Typography
                        sx={{
                          '@media (max-height: 620px)': {
                            fontSize: '0.75rem',
                          },
                        }}
                        variant="body1"
                      >
                        {t('pages.ticketDetails.creationDate')} :
                      </Typography>
                      {createdDate ? (
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="eva:clock-outline" />
                          <Typography
                            sx={{
                              '@media (max-height: 620px)': {
                                fontSize: '0.75rem',
                              },
                            }}
                          >
                            {createdDate}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                    {updateDate ? (
                      <Stack spacing={2} direction="row">
                        <Typography
                          sx={{
                            '@media (max-height: 620px)': {
                              fontSize: '0.75rem',
                            },
                          }}
                          variant="body1"
                        >
                          {t('pages.ticketDetails.lastUpdate')} :
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="eva:clock-outline" />
                          <Typography
                            sx={{
                              '@media (max-height: 620px)': {
                                fontSize: '0.75rem',
                              },
                            }}
                          >
                            {updateDate}
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : null}

                    <Stack spacing={3} direction="row">
                      <Typography
                        sx={{
                          '@media (max-height: 620px)': {
                            fontSize: '0.75rem',
                          },
                        }}
                        variant="body 1"
                      >
                        {t('pages.ticketDetails.ticketDep')} :
                      </Typography>
                      <Stack spacing={1} direction="row">
                        <Iconify sx={{ mt: 0.4 }} icon="eva:pantone-outline" />
                        <Typography
                          sx={{
                            '@media (max-height: 620px)': {
                              fontSize: '0.75rem',
                            },
                          }}
                          variant="h6"
                        >
                          {row?.department || 'department'}
                        </Typography>
                      </Stack>
                    </Stack>
                    {row?.category && (
                      <Stack spacing={3} direction="row">
                        <Typography
                          sx={{
                            '@media (max-height: 620px)': {
                              fontSize: '0.75rem',
                            },
                          }}
                          variant="body 1"
                        >
                          {t('pages.ticketDetails.ticketCat')}:
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="material-symbols:category-outline-rounded" />
                          <Typography
                            sx={{
                              '@media (max-height: 620px)': {
                                fontSize: '0.75rem',
                              },
                            }}
                            variant="h6"
                          >
                            {row?.category || 'category'}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                    {row && row.status !== 'RESOLVED' && row.status !== 'CLOSED' && (
                      <Stack spacing={2} direction="row">
                        <Typography
                          sx={{
                            '@media (max-height: 620px)': {
                              fontSize: '0.75rem',
                            },
                          }}
                          variant="body1"
                        >
                          {t('tableHeads.ticket.estimatedDuration')}
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="eva:clock-outline" />
                          {row.estimatedDuration ? (
                            <Typography
                              sx={{
                                '@media (max-height: 620px)': {
                                  fontSize: '0.75rem',
                                },
                              }}
                            >
                              {`${Duration.parse(row.estimatedDuration).toHours()} ${t('attributes.hours')}`}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                '@media (max-height: 620px)': {
                                  fontSize: '0.75rem',
                                },
                              }}
                            >
                              {t('pages.ticketDetails.nonDefined')}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    )}
                    {row?.status === 'OPEN' && (
                      <Stack spacing={2} direction="row">
                        <Typography
                          sx={{
                            '@media (max-height: 620px)': {
                              fontSize: '0.75rem',
                            },
                          }}
                          variant="body1"
                        >
                          {t('tableHeads.ticket.estimatedEnd')}
                        </Typography>
                        <Stack spacing={1} direction="row">
                          <Iconify sx={{ mt: 0.4 }} icon="eva:clock-outline" />
                          <Typography
                            sx={{
                              '@media (max-height: 620px)': {
                                fontSize: '0.75rem',
                              },
                            }}
                          >
                            {new Date(row.estimatedEndTime).toLocaleString()}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Card>
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
