import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSubscription, useStompClient } from 'react-stomp-hooks';
import { LoadingButton } from '@mui/lab';
import { Stack, Grid, Card, Avatar, Box, Typography, Button, IconButton } from '@mui/material';
import Papa from 'papaparse';
import AssignTicketModal from '../../components/ticket/AssignTicketModal';
import AssignTicketToSelfModal from '../../components/ticket/AssignToSelfModal';
import DetailsContent from '../../components/ticket/DetailsContent';
import TicketConversation from '../../components/ticket/TicketConversation';
import ModifyTicket from '../../components/ticket/ModifyTicket';
import DelegateTicketModal from '../../components/ticket/DelegateTicketModal';
import { fetchCommentsPerTicketId, fetchMessagesByTicketId } from '../../services/conversation.service';
import { getDepartmentById, fetchDepartments } from '../../services/department.service';
import { AppOrderTimeline } from '../../sections/@dashboard/Charts';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { fetchStatuses } from '../../services/status.service';
import { useModal } from '../../hooks/useModal';
import Iconify from '../../components/iconify';
import { convertLocalTime } from '../../utils/convertTime';

import { updateTicket, getTicketById, getFilesByTicket, assignTicketManually } from '../../services/ticket.service';
import { GetUserById, getUsersByDepartment } from '../../services/user.service';

export default function TicketDetails() {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const { id: currentUserId, role: currentUserRole, username } = useAuth();
  const { showModal, hideModal } = useModal();
  const { showToast, hideToast } = useToast();
  const [ticketData, setTicketData] = useState();
  const [openModifyTicket, setOpenModifyTicket] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({});
  const [customerData, setCustomerData] = useState({
    id: 0,
    username: '',
    email: '',
  });
  const [customerAvatar, setCustomerAvatar] = useState('');
  const [agentData, setAgentData] = useState({
    id: 0,
    username: '',
    email: '',
    departmentId: 0,
  });
  const [agentAvatar, setAgentAvatar] = useState('');
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [ticketFilesList, setTikcetFilesList] = useState([]);
  const [departmentData, setDepartmentData] = useState({
    id: 0,
    name: '',
    description: '',
    image: '',
  });
  const [departmentsData, setDepartmentsData] = useState([]);
  const [dataInCSV, setDataInCSV] = useState('');
  const location = useLocation();
  const { t } = useTranslation();
  const genericApiToaster = (apiCall, args, successMessage, errorMessage, successCallback, errorCallback) => {
    const apiArguments = Array.isArray(args) ? args : [args]; // Convert single argument to an array

    apiCall(...apiArguments)
      .then((res) => {
        showToast({ message: successMessage, severity: 'success', props: { hideToast } });
        if (successCallback) {
          successCallback(res);
        }
      })
      .catch((e) => {
        showToast({ message: errorMessage, severity: 'error', props: { hideToast } });
        if (errorCallback) {
          errorCallback(e);
        }
      });
  };

  useMemo(() => {
    const csvData = [];
    const questionsheaders = [];
    const questionAnswers = [];

    (ticketData?.data || [])
      .flatMap((section) => section.questions || [])
      .forEach(({ text, inputValue }) => {
        questionsheaders.push(text);
        questionAnswers.push(inputValue);
      });

    csvData.push(questionsheaders);
    csvData.push(questionAnswers);
    setDataInCSV(Papa.unparse(csvData));
  }, [ticketData]);
  const normalizePath = (filePath) => filePath.split(/[\\/]/).filter(Boolean);
  // fetch ticket files
  const getTicketFiles = async () => {
    getFilesByTicket(ticketId)
      .then((res) => {
        const fileNames = [];
        res.forEach((filePath) => {
          const parts = normalizePath(filePath); // Split the string by backslashes
          const fileName = parts[parts.length - 1];

          fileNames.push(fileName);
        });
        setTikcetFilesList(fileNames);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadTicketFiles'), severity: 'error', props: { hideToast } });
      });
  };
  const fetchUser = (userId, setState) => {
    if (userId !== -1) {
      GetUserById(userId)
        .then((res) => {
          setState(res);
        })
        .catch(() => {
          showToast({ message: t('errorMessages.couldntLoadUser'), severity: 'error', props: { hideToast } });
        });
    }
  };
  const fetchDepartmentsData = () => {
    fetchDepartments()
      .then((res) => {
        setDepartmentsData(res);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDep'), severity: 'error', props: { hideToast } });
      });
  };

  useEffect(() => {
    fetchDepartmentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useSubscription(`/queue/reply-${ticketId}`, (message) => {
    const newComment = JSON.parse(message.body);
    setCommentsData((prevList) => [...prevList, newComment]);
    getTicketFiles();
  });

  const stompClient = useStompClient();
  // create comments
  const createComment = async (newComment) => {
    if (stompClient) {
      const receiver = ticketData.assignedTo === currentUserId ? ticketData.createdBy : ticketData.assignedTo;
      stompClient.publish({
        destination: `/app/user-message-${ticketId}/${receiver}/${username}`,
        body: JSON.stringify({
          message: newComment.message,
          receiver,
          sender: currentUserId,
          messageType: newComment.messageType,
          ticketId,
        }),
      });
    }
  };

  const assignTicket = async (ticketId, userId, statusId) => {
    genericApiToaster(
      assignTicketManually,
      [parseInt(ticketId, 10), userId, statusId],
      t('successMessages.successTicketAssign'),
      t('errorMessages.errorUpdatingTicket'),
      () => {
        navigate(-1);
      }
    );
  };
  const modifyTicket = async (newTicketData) => {
    genericApiToaster(
      updateTicket,
      newTicketData,
      t('successMessages.successTicketUpdate'),
      t('errorMessages.errorUpdatingTicket'),
      () => {
        fetchTicketData(ticketId);
      }
    );
  };

  const interactions = [];
  const dates = ['createdAt', 'updatedAt', 'rejectedAt', 'acceptedAt', 'openedAt', 'resolvedAt'];
  dates.forEach((propName, index) => {
    if (ticketData) {
      if (ticketData[propName]) {
        interactions.push({
          id: index,
          title: t(`pages.ticketDetails.${propName}`),
          time: convertLocalTime(ticketData[propName]),
        });
      }
    }
  });

  interactions.sort((a, b) => new Date(a.time) - new Date(b.time));

  const getAvatar = async (userId, setPic) => {
    const { avatar } = await GetUserById(userId);
    setPic(avatar);
  };
  useEffect(() => {
    if (customerData.avatar) {
      getAvatar(customerData.id, setCustomerAvatar);
    }
  }, [customerData, ticketData]);

  useEffect(() => {
    if (agentData.avatar) {
      getAvatar(agentData.id, setAgentAvatar);
    }
  }, [agentData, ticketData]);

  const fetchMessages = () => {
    fetchMessagesByTicketId(ticketId)
      .then((res) => {
        setCommentsData(res.data);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadComments'), severity: 'error', props: { hideToast } });
      });
  };

  const getStatuses = (depId) => {
    fetchStatuses().then((res) => {
      setStatusesList(res.filter((status) => status.departmentId === depId || status.departmentId === 0));
    });
  };

  const fetchDepartment = (depId) => {
    getDepartmentById(depId)
      .then((res) => {
        setDepartmentData(res);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDep'), severity: 'error', props: { hideToast } });
      });
  };

  useEffect(() => {
    if (
      ticketData &&
      currentStatus &&
      currentStatus.systemMappedStatus !== 'UNASSIGNED' &&
      agentData.departmentId !== 0
    )
      fetchDepartment(agentData.departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData]);

  const fetchDepAgents = () => {
    getUsersByDepartment(ticketData.departmentId)
      .then((res) => {
        setDepartmentUsers(res.filter((user) => user.appUserRole === 'ROLE_AGENT'));
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDepUsers'), severity: 'error', props: { hideToast } });
      });
  };

  const fetchTicketData = (tickId) => {
    getTicketById(tickId)
      .then((res) => {
        setTicketData(res);
      })
      .catch(() => {
        navigate('/404');
      });
  };
  useEffect(() => {
    fetchTicketData(ticketId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentStatus = (idStatus) => {
    setCurrentStatus(statusesList.find((status) => status.statusId === idStatus));
  };
  useEffect(() => {
    if (ticketData) {
      setCommentsData([]);
      fetchMessages();
      if (currentStatus && currentStatus.systemMappedStatus !== 'UNASSIGNED') {
        fetchUser(ticketData.assignedTo, setAgentData);
      }
      fetchUser(ticketData.createdBy, setCustomerData);
      fetchDepAgents();
      getTicketFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData, currentStatus]);

  useEffect(() => {
    getStatuses(ticketData?.departmentId);
  }, [ticketData]);

  useEffect(() => {
    if (ticketData) {
      getCurrentStatus(ticketData.status_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList, ticketData]);

  const delegateTicket = (delegatedTicketData) => {
    genericApiToaster(
      updateTicket,
      delegatedTicketData,
      t('successMessages.successDelegateTicket'),
      t('errorMessages.couldntDelegateTicket'),
      () => {
        navigate(-1);
      }
    );
  };

  const handleDelegate = () => {
    showModal({
      title: t('pages.delegateTicket.delegateTicketToAnother'),
      Component: DelegateTicketModal,
      props: {
        delegateTicket,
        usersList: departmentUsers.filter((user) => user.id !== currentUserId),
        department: ticketData.department,
        ticketData,
      },
    });
  };
  return (
    <Box sx={{ flexDirection: 'column' }}>
      <ModifyTicket
        open={openModifyTicket}
        setOpen={setOpenModifyTicket}
        row={ticketData}
        modifyTicket={modifyTicket}
        departmentData={departmentsData}
        getStatuses={getStatuses}
        statusesList={statusesList}
        currentRole={currentUserRole.authority}
        departmentUsers={departmentUsers}
        currentUserId={currentUserId}
      />
      <Grid width={'100vw'} columns={24} sx={{ height: '100vh', flexGrow: 1 }} container spacing={1}>
        <Grid item xs={24} lg={7} md={7} sm={24} height="100%" bgcolor="#F5F5F5">
          <Box height="85%">
            <DetailsContent
              currentUserRole={currentUserRole.authority}
              currentUserId={currentUserId}
              row={ticketData}
              openEdit={setOpenModifyTicket}
              status={currentStatus}
            />
          </Box>

          <Stack pt={2} height="15%" justifyContent="center" alignItems="center">
            {currentStatus &&
            currentStatus.systemMappedStatus !== 'UNASSIGNED' &&
            currentUserRole.authority === 'ROLE_AGENT' &&
            ticketData?.assignedTo === currentUserId ? (
              <LoadingButton
                data-testid="accept-ticket-btn"
                size="small"
                sx={{ bgcolor: '#229A16', boxShadow: '0.5px 0.5px 2 px' }}
                variant="contained"
                onClick={handleDelegate}
              >
                {t('pages.delegateTicket.delegateTicketToAnother')}
              </LoadingButton>
            ) : ticketData &&
              currentStatus?.systemMappedStatus === 'UNASSIGNED' &&
              currentUserRole.authority === 'ROLE_AGENT' &&
              ticketData.createdBy !== currentUserId ? (
              <>
                <Typography sx={{ marginBottom: '15px', textAlign: 'center' }}>
                  {t('pages.assignTicket.assignByYourself')}
                </Typography>

                <LoadingButton
                  onClick={() => {
                    showModal({
                      title: t('pages.assignTicket.assignTicketToSelf'),
                      Component: AssignTicketToSelfModal,
                      props: {
                        hideModal,
                        assignTicket,
                        ticketId: parseInt(ticketId, 10),
                        statusesList: statusesList.filter((status) => status.systemMappedStatus === 'OPEN'),
                        currentUserId,
                      },
                    });
                  }}
                  size="small"
                  sx={{
                    bgcolor: '#229A16',
                    boxShadow: '0.5px 0.5px 2 px',
                    display: 'block',
                    margin: 'auto',
                    marginTop: 2,
                  }}
                  variant="contained"
                  startIcon={<Iconify icon="@mui/icons-material/ArrowForward" />}
                >
                  {t('pages.assignTicket.assignTicketToSelf')}
                </LoadingButton>
              </>
            ) : null}
          </Stack>
        </Grid>

        <Grid item xs={24} lg={12} md={12} sm={24} sx={{ bgcolor: '#F5F5F5' }}>
          <TicketConversation
            currentUserId={currentUserId}
            row={ticketData}
            ticketFilesList={ticketFilesList}
            commentsData={commentsData}
            createComment={createComment}
            getTicketFiles={getTicketFiles}
            fetchCommentsPerTicketId={fetchCommentsPerTicketId}
            fetchUser={fetchUser}
            customerAvatar={customerAvatar}
            agentAvatar={agentAvatar}
            currentStatus={currentStatus}
            statusesList={statusesList}
          />
        </Grid>

        <Grid item xs={24} lg={5} md={5} sm={24} sx={{ bgcolor: '#F5F5F5', pr: 2 }} justifyContent="center">
          <Box
            sx={{
              height: '10%',
              '@media (max-height: 620px)': {
                height: '7%',
              },
            }}
          >
            <Stack direction="row" justifyContent="flex-end">
              <IconButton onClick={() => navigate(-1)}>
                <Iconify
                  sx={{
                    height: 40,
                    width: 40,
                    color: 'grey',

                    '&:hover': {
                      color: '#B72136',
                    },
                    '@media (max-height: 620px)': {
                      height: 30,
                      width: 30,
                    },
                  }}
                  icon={'eva:close-square-fill'}
                />
              </IconButton>
            </Stack>
          </Box>

          {currentStatus && currentStatus.systemMappedStatus === 'UNASSIGNED' ? (
            <Card sx={{ boxShadow: '0.5px 0.5px 2px', px: 2, py: 1, height: '40%', bgcolor: '#F9F9F9' }}>
              <Typography
                sx={{
                  '@media (max-height: 620px)': {
                    fontSize: '1rem',
                  },
                }}
                variant="h6"
              >
                {t('pages.ticketDetails.agentInfo')}
              </Typography>

              <Typography
                sx={{
                  '@media (max-height: 620px)': {
                    fontSize: '0.75rem',
                  },
                }}
              >
                {t('pages.ticketDetails.notAssigned')}
              </Typography>
              {(currentUserRole.authority === 'ROLE_ADMIN' ||
                currentUserRole.authority === 'ROLE_DEPARTMENTMANAGER') && (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ mt: 3 }}
                  onClick={() => {
                    showModal({
                      title: t('pages.assignTicket.assignTicketToSelf'),
                      Component: AssignTicketModal,
                      props: {
                        hideModal,
                        assignTicket,
                        ticketId: parseInt(ticketId, 10),
                        usersList: departmentUsers,
                        department: ticketData.department,
                        statusesList: statusesList.filter((status) => status.systemMappedStatus === 'OPEN'),
                      },
                    });
                  }}
                >
                  {t('buttons.assignManually')}
                </Button>
              )}
            </Card>
          ) : (
            <Card
              sx={{
                boxShadow: '0.5px 0.5px 2px',
                px: 2,
                py: 1,
                height: '38%',
                '@media (max-height: 620px)': {
                  height: '35%',
                },
                bgcolor: '#F9F9F9',
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: '3px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  },
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {ticketData && currentUserId === ticketData.assignedTo ? (
                <>
                  <Typography
                    sx={{
                      '@media (max-height: 620px)': {
                        fontSize: '1rem',
                      },
                    }}
                    variant="h6"
                  >
                    {t('pages.ticketDetails.userInfo')}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{
                      '@media (max-height: 620px)': {
                        mt: 2.5,
                      },
                      mt: 5,
                    }}
                  >
                    <Avatar
                      sx={{
                        '@media (max-height: 620px)': {
                          height: '30px',
                          width: '30px',
                        },
                      }}
                      src={customerAvatar}
                      alt=""
                    />
                    <Typography
                      sx={{
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                      variant="body1"
                    >
                      {ticketData && currentUserId === ticketData.assignedTo ? (
                        <b>{customerData.username}</b>
                      ) : (
                        <b>{agentData.username}</b>
                      )}
                    </Typography>
                  </Stack>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      '@media (max-height: 620px)': {
                        fontSize: '1rem',
                      },
                    }}
                    variant="h6"
                  >
                    {t('pages.ticketDetails.agentInfo')}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{
                      '@media (max-height: 620px)': {
                        mt: 2.5,
                      },
                      mt: 5,
                    }}
                  >
                    <Avatar
                      sx={{
                        '@media (max-height: 620px)': {
                          height: '30px',
                          width: '30px',
                        },
                      }}
                      src={agentAvatar}
                      alt=""
                    />
                    <Typography
                      sx={{
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                      variant="body1"
                    >
                      {ticketData && currentUserId === ticketData.assignedTo ? (
                        <b>{customerData.username}</b>
                      ) : (
                        <b>{agentData.username}</b>
                      )}
                    </Typography>
                  </Stack>
                </>
              )}
              <Stack sx={{ mt: 3 }} spacing={3}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      '@media (max-height: 620px)': {
                        fontSize: '0.75rem',
                      },
                    }}
                  >
                    <b>{t('attributes.email')} : </b>
                  </Typography>
                  {ticketData && currentUserId === ticketData.assignedTo ? (
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                    >
                      {customerData.email}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                    >
                      {agentData.email}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      '@media (max-height: 620px)': {
                        fontSize: '0.75rem',
                      },
                    }}
                  >
                    <b>{t('attributes.phoneNumber')} : </b>
                  </Typography>
                  {agentData.phoneNumber ? (
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                    >
                      {agentData.phoneNumber}
                    </Typography>
                  ) : customerData.phoneNumber ? (
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                    >
                      {customerData.phoneNumber}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                    >
                      {t('pages.ticketDetails.noPhone')}
                    </Typography>
                  )}
                </Stack>
                {ticketData && currentUserId !== ticketData.assignedTo && (
                  <Stack direction="row" spacing={2}>
                    <Typography
                      sx={{
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                    >
                      <b>{t('attributes.department')}:</b>
                    </Typography>
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                        '@media (max-height: 620px)': {
                          fontSize: '0.75rem',
                        },
                      }}
                    >
                      {departmentData.name}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Card>
          )}
          <AppOrderTimeline
            sx={{
              mt: 2.5,

              boxShadow: '0.5px 0.5px 2px',
              bgcolor: '#F9F9F9',
              overflowY: 'scroll',
              height: '38%',
              '@media (max-height: 620px)': {
                height: '35%',
              },
              scrollbarWidth: 'thin',
              scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '3px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                },
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
            }}
            title={t('pages.ticketDetails.interactions')}
            list={interactions.map((_, index) => ({
              id: _.id,
              title: _.title,
              type: `order${index + 1}`,
              time: _.time,
            }))}
          />
          <a
            href={`data:text/csv;charset=utf-8,${dataInCSV}`}
            download={`ticket-${ticketId}-Answers.csv`}
            style={{ justifyContent: 'center', display: 'flex', marginTop: '1vh' }}
          >
            <Button variant="contained" startIcon={<Iconify icon="material-symbols:download" />}>
              {t('buttons.downloadAnswers')}
            </Button>
          </a>
        </Grid>
      </Grid>
    </Box>
  );
}
