import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwipeableDrawer, Stack, Typography, Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { PropTypes } from 'prop-types';
import FormBody from '../formComposer/FormBody';
import FormPreview from '../FormPreview';
import { FormProvider } from '../../../hooks/useForm';

FormDetails.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  initialData: PropTypes.object,
  formId: PropTypes.number,
};

export default function FormDetails({ setOpen, open, formId, initialData }) {
  const [value, setValue] = useState('0');
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <SwipeableDrawer
      anchor="right"
      onOpen={handleOpen}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { width: '60%' },
      }}
    >
      <FormProvider initialData={initialData}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="tablist">
              <Tab label={t('pages.formPage.formComposer')} value="0" />
              <Tab label={t('pages.formPage.previewForm')} value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4">{t('buttons.updateForm')}</Typography>
            </Stack>
            <FormBody updateMode formId={formId} handleClose={handleClose} />
          </TabPanel>
          <TabPanel value="1">
            <FormPreview disabled />
          </TabPanel>
        </TabContext>
      </FormProvider>
    </SwipeableDrawer>
  );
}
