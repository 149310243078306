import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  MenuItem,
  Stack,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX, PHONENUMBER_REGEX } from '../../utils/regex';
import countries from '../../locale/phoneCode';

ModifyUserModal.propTypes = {
  row: PropTypes.object,
  setOpen: PropTypes.func,
  modifyUser: PropTypes.func,
  open: PropTypes.bool,
  currentRole: PropTypes.string,
  departmentsData: PropTypes.array,
};

export default function ModifyUserModal({ row, setOpen, modifyUser, open, currentRole, departmentsData }) {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    countries.sort((a, b) => a[i18n.language].localeCompare(b[i18n.language]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phoneNumberSplit = (number) => {
    const defaultCountryCode = '+39';
    const regex = /\(([^)]+)\) (.+)/;
    const match = number?.match(regex);

    if (match) {
      return { partOne: match[1], partTwo: match[2] };
    }

    return { partOne: defaultCountryCode, partTwo: number };
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: row.id,
      givenName: row.givenName,
      familyName: row.familyName,
      countryCode: phoneNumberSplit(row?.phoneNumber).partOne,
      phoneNumber: phoneNumberSplit(row?.phoneNumber).partTwo,
      appUserRole: row.appUserRole,
      departmentId: row.departmentId,
      departmentName: row.departmentName,
      email: row.email,
    },
  });

  const selectedRole = watch('appUserRole');

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {};

  const onSubmit = (data) => {
    // Concatenate the country code with the phone number
    data.phoneNumber = `(${data.countryCode}) ${data.phoneNumber}`;

    if (data.appUserRole === 'ROLE_AGENT' || data.appUserRole === 'ROLE_DEPARTMENTMANAGER') {
      const selectedDepartmentObject = departmentsData.find((department) => department.id === data.departmentId);
      data.departmentName = selectedDepartmentObject.name;
    }

    modifyUser(data);
    handleClose();
  };

  useMemo(() => {
    const defaultValues = {
      id: row.id,
      givenName: row.givenName,
      familyName: row.familyName,
      countryCode: phoneNumberSplit(row?.phoneNumber).partOne,
      phoneNumber: phoneNumberSplit(row?.phoneNumber).partTwo,
      appUserRole: row.appUserRole,
      departmentId: row.departmentId,
      departmentName: row.departmentName,
      email: row.email,
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <DialogTitle id="modal--title" variant="h6" component="h2">
          {t('pages.usersPage.form.modifyUser')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <br />
              <TextField
                name="givenName"
                label={t('attributes.name')}
                error={!!errors.givenName}
                helperText={errors.givenName?.message || ''}
                {...register('givenName', {
                  required: t('formControl.enterName'),
                  minLength: {
                    value: 3,
                    message: t('formControl.minName'),
                  },
                })}
              />
              <TextField
                name="familyName"
                label={t('attributes.lastName')}
                error={!!errors.familyName}
                helperText={errors.familyName?.message || ''}
                {...register('familyName', {
                  required: t('formControl.enterLasName'),
                  minLength: {
                    value: 3,
                    message: t('formControl.minLastName'),
                  },
                })}
              />
              <Stack direction="row" spacing={1}>
                <FormControl sx={{ width: '20%' }}>
                  <InputLabel id="country-code-label">{t('attributes.country')}</InputLabel>
                  <Select
                    labelId="country-code-label"
                    label={t('attributes.country')}
                    id="countryCode"
                    name="countryCode"
                    defaultValue={phoneNumberSplit(row?.phoneNumber).partOne}
                    renderValue={(countryCode) => `(${countryCode})`}
                    {...register('countryCode', {
                      required: t('formControl.selectRole'),
                    })}
                  >
                    {countries.map((country, index) => (
                      <MenuItem key={index} value={country.code}>
                        {`${country[i18n.language]}  (${country.code})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  sx={{ width: '80%' }}
                  fullWidth
                  name="phoneNumber"
                  id="user-phone-input"
                  type="number"
                  label={t('attributes.phoneNumber')}
                  autoComplete="tel-national"
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message || ''}
                  {...register('phoneNumber', {
                    required: t('formControl.enterPhoneNumber'),
                    pattern: {
                      value: PHONENUMBER_REGEX,
                      message: t('formControl.invalidPhoneNumber'),
                    },
                  })}
                />
              </Stack>
              {currentRole === 'ROLE_ADMIN' && (
                <FormControl fullWidth error={!!errors.appUserRole}>
                  <InputLabel id="demo-simple-select-label">{t('pages.profilePage.role')}</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="appUserRole"
                    defaultValue={row.appUserRole}
                    label={t('pages.usersPage.form.role')}
                    {...register('appUserRole', {
                      required: t('formControl.selectRole'),
                    })}
                  >
                    <MenuItem value={'ROLE_CUSTOMER'}>{t('roles.ROLE_CUSTOMER')}</MenuItem>
                    <MenuItem value={'ROLE_AGENT'}>{t('roles.ROLE_AGENT')}</MenuItem>
                    <MenuItem value={'ROLE_ADMIN'}>{t('roles.ROLE_ADMIN')}</MenuItem>
                    <MenuItem value={'ROLE_DEPARTMENTMANAGER'}>{t('roles.ROLE_DEPARTMENTMANAGER')}</MenuItem>
                  </Select>
                  <FormHelperText>{errors.appUserRole?.message}</FormHelperText>
                </FormControl>
              )}
              {selectedRole &&
                selectedRole !== 'ROLE_CUSTOMER' &&
                selectedRole !== 'ROLE_ADMIN' &&
                currentRole === 'ROLE_ADMIN' && (
                  <FormControl fullWidth error={!!errors.departmentId}>
                    <InputLabel id="demo-simple-select-label">{t('attributes.department')}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={row.departmentId}
                      label="Department"
                      {...register('departmentId', {
                        required: t('formControl.selectDepartment'),
                      })}
                    >
                      {departmentsData.map((department) => {
                        const { id, name } = department;

                        return (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    <FormHelperText>{errors.departmentId?.message}</FormHelperText>
                  </FormControl>
                )}

              <TextField
                name="email"
                label={t('pages.usersPage.form.emailAdress')}
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email?.message || ''}
                {...register('email', {
                  required: t('formControl.enterEmail'),
                  pattern: {
                    value: EMAIL_REGEX,
                    message: t('formContol.invalidEmail'),
                  },
                })}
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleClick}
                disabled={!isValid}
              >
                {t('buttons.update')}
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
