import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import FormCard from './FormCard';
import FormSection from './FormSection';
import { useFormContext } from '../../../hooks/useForm';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { fetchDepartments } from '../../../services/department.service';
import SelectInput from '../../FormInputs/SelectInput';
import { createForm, modifyForm } from '../../../services/form.service';
import Iconify from '../../iconify';

FormBody.propTypes = {
  updateMode: PropTypes.bool,
  formId: PropTypes.number,
  handleClose: PropTypes.func,
};

export default function FormBody({ updateMode, formId, handleClose }) {
  const { sections, addSection, updateFormData, getFormData, formData, removeQuestionAnswers } = useFormContext();
  const { id, role, departmentId } = useAuth();
  const { showToast, hideToast } = useToast();
  const [focusedSection, setFocusedSection] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(
    role.authority === 'ROLE_DEPARTMENTMANAGER' ? departmentId : formData.department || null
  );
  const [sectionsArray, setSectionsArray] = useState([]);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      formName: formData.title || 'Untitled',
      formDescription: formData.description || '',
    },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const departmentList = useMemo(() => {
    const deps = departments?.map((dep) => ({ text: dep.name, value: dep.id }));
    return deps;
  }, [departments]);

  useEffect(() => {
    const sectionArray = Object.values(sections);
    setSectionsArray(sectionArray);
  }, [sections]);

  useEffect(() => {
    const fetchData = () => {
      fetchDepartments().then((res) => {
        setDepartments(res);
      });
    };
    fetchData();
  }, []);

  const handleDepartmentSelection = (value) => {
    setSelectedDepartment(value);
  };
  const onSubmit = async (data) => {
    if (!isDebouncing) {
      setIsDebouncing(true);
      removeQuestionAnswers();
      await updateFormData({
        title: data.formName,
        department: selectedDepartment,
        description: data.formDescription,
      });
      const formData = getFormData();
      if (updateMode) {
        modifyForm({
          id: formId,
          title: data.formName,
          departmentId: selectedDepartment,
          description: data.formDescription,
          formData: {
            ...formData,
            formData: {
              ...formData.formData,
              title: data.formName,
              department: selectedDepartment,
              description: data.formDescription,
            },
          },
          createdBy: id,
        })
          .then(() => {
            showToast({ message: 'Form updated successfully', severity: 'success', props: { hideToast } });

            handleClose();
          })
          .catch(() => {
            showToast({ message: 'Error updating form', severity: 'error', props: { hideToast } });
          });
      } else {
        createForm({
          title: data.formName,
          departmentId: selectedDepartment,
          description: data.formDescription,
          formData: {
            ...formData,
            formData: {
              ...formData.formData,
              title: data.formName,
              department: selectedDepartment,
              description: data.formDescription,
            },
          },
          createdBy: id,
        })
          .then(() => {
            showToast({ message: 'Form created successfully', severity: 'success', props: { hideToast } });
            navigate('/dashboard/forms');
          })
          .catch(() => {
            showToast({ message: 'Error creating form', severity: 'error', props: { hideToast } });
          });
      }
      setTimeout(() => {
        setIsDebouncing(false);
      }, 300);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} key={'formCard'}>
        <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
          <Button
            variant="contained"
            startIcon={<Iconify icon={updateMode ? 'radix-icons:update' : 'eva:plus-fill'} />}
            type="submit"
            disabled={!isValid || !selectedDepartment || Object.keys(sections).length === 0 || isDebouncing}
          >
            {updateMode ? t('buttons.updateForm') : t('buttons.createForm')}
          </Button>
        </Stack>
        <FormCard isFormHeader>
          <TextField
            id="form-title"
            name={t('pages.formPage.formTitle')}
            label={t('pages.formPage.formTitle')}
            variant="standard"
            fullWidth
            size="medium"
            color="info"
            mt={2}
            error={!!errors.formName}
            helperText={errors.formName?.message || ''}
            {...register('formName', {
              required: t('formControl.enterName'),
            })}
          />
          <TextField
            id="form-description"
            name="Form description"
            label={t('pages.formPage.formDescription')}
            variant="standard"
            size="small"
            fullWidth
            mt={2}
            error={!!errors.formDescription}
            helperText={errors.formDescription?.message || ''}
            {...register('formDescription', {})}
          />
          {departmentList && (
            <SelectInput
              labelValue={t('attributes.department')}
              name="select department"
              defaultValue={formData.department || selectedDepartment}
              fullWidth
              disabled={role.authority === 'ROLE_DEPARTMENTMANAGER'}
              options={departmentList || []}
              setSelectedInput={handleDepartmentSelection}
              sx={{ marginTop: '2vh' }}
            />
          )}
        </FormCard>
        <Button
          id="add-section-btn"
          onClick={() => {
            if (!isDebouncing) {
              setIsDebouncing(true);

              addSection({ name: sectionsArray.length + 1, questions: [] });

              setTimeout(() => {
                setIsDebouncing(false);
              }, 300);
            }
          }}
          disabled={isDebouncing}
        >
          {t('pages.formPage.addSection')}
        </Button>

        {sectionsArray.map((section, index) => (
          <FormSection
            key={section.id}
            section={section}
            index={index + 1}
            sectionLength={sectionsArray.length}
            setFocusedSection={setFocusedSection}
            focusedSection={focusedSection}
            selectedDepartment={selectedDepartment}
          />
        ))}
        <Stack direction="row" alignItems="center" justifyContent="center" mb={5} mt={5}>
          <Button
            variant="contained"
            id="save-form-btn"
            startIcon={<Iconify icon={updateMode ? 'radix-icons:update' : 'eva:plus-fill'} />}
            type="submit"
            disabled={!isValid || !selectedDepartment || Object.keys(sections).length === 0 || isDebouncing}
          >
            {updateMode ? t('buttons.updateForm') : t('buttons.createForm')}
          </Button>
        </Stack>
      </form>
    </div>
  );
}
