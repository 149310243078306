import { filter } from 'lodash';

export const descendingComparator = (operand1, operand2, orderBy) => {
  if (operand2[orderBy] < operand1[orderBy]) {
    return -1;
  }
  if (operand2[orderBy] > operand1[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (operand1, operand2) => descendingComparator(operand1, operand2, orderBy)
    : (operand1, operand2) => -descendingComparator(operand1, operand2, orderBy);

export const applySortFilter = (array, comparator, query, variable) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((operand1, operand2) => {
    const order = comparator(operand1[0], operand2[0]);
    if (order !== 0) return order;
    return operand1[1] - operand2[1];
  });
  if (query) {
    return filter(
      array,
      (item) => item[variable] && item[variable].toString().toLowerCase().indexOf(query.toString().toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
};

export const transformData = (originalData) =>
  originalData.map((dep) => {
    const statusOrder = ['UNASSIGNED', 'RESOLVED', 'CLOSED', 'OPEN', 'ONHOLD']; // Define the order of statuses

    const statusCounts = {
      UNASSIGNED: 0,
      RESOLVED: 0,
      CLOSED: 0,
      OPEN: 0,
      ONHOLD: 0,
    };

    dep?.tickets?.forEach((ticket) => {
      // eslint-disable-next-line no-plusplus
      statusCounts[ticket.status]++;
    });
    const data = statusOrder.map((status) => statusCounts[status]);
    return data;
  });

export const reorderArray = (arr, currentIndex, nextIndex) => {
  if (currentIndex === -1 || nextIndex === -1) {
    // If either section is not found in the array, return the original array.
    return arr;
  }

  const newArr = [...arr]; // Create a new array to avoid mutating the original.
  const nextSection = arr[nextIndex];
  const currentVal = newArr[currentIndex];
  // Remove the nextSection from its current position.
  newArr.splice(nextIndex, 1);
  // Get new current index after removing the nextSection.
  const newCurrentIndex = newArr.indexOf(currentVal);

  // Calculate the new index to insert the nextSection.
  const insertIndex = newCurrentIndex + 1;

  // Insert the nextSection at the new position.
  newArr.splice(insertIndex, 0, nextSection);

  return { currentIndex: newCurrentIndex, array: newArr };
};
