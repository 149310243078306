import React, { useMemo, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';

const AuthContext = React.createContext(null);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(secureLocalStorage.getItem('user') || null);

  const login = (token) => {
    secureLocalStorage.setItem('user', token);
    setToken(token);
  };

  const user = useMemo(() => (token ? jwtDecode(token) : ''), [token]);
  const { sub: email, id, username, givenName, familyName, status, departmentId, firstLogin, auth: role } = user;

  return (
    <AuthContext.Provider
      value={{
        email,
        id,
        username,
        givenName,
        familyName,
        status,
        departmentId,
        firstLogin,
        role,
        isAuthenticated: !!token,
        token,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
