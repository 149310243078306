import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, IconButton, ListItemAvatar, Avatar } from '@mui/material';
import { getFileByName } from '../../services/file.service';
import fileDownload from '../../utils/fileDownload';
import Iconify from '../iconify';

AttachmentsList.propTypes = {
  ticketFilesList: PropTypes.array,
};
export default function AttachmentsList({ ticketFilesList }) {
  const handleDownloadClick = (e) => {
    const name = e.currentTarget.value;
    getFileByName(name).then((file) => fileDownload(file, name));
  };
  return (
    <List sx={{ px: 2 }}>
      {ticketFilesList.map((fileName, index) => {
        let icon = 'bx:file';
        const extensions = ['.jpeg', '.jpg', '.png', '.gif'];
        extensions.forEach((ext) => {
          if (fileName.endsWith(ext)) {
            icon = 'ph:image';
          }
        });

        return (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton value={fileName} onClick={handleDownloadClick} edge="end" aria-label="delete">
                <Iconify icon="material-symbols:download" />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Iconify icon={icon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={fileName} />
          </ListItem>
        );
      })}
    </List>
  );
}
