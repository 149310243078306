import { Card, CardHeader, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from '../../../hooks/useForm';
import QuestionView from './QuestionView';
import { reorderArray } from '../../../utils/filterData';
import TextInput from '../../FormInputs/TextInput';

SectionPanel.propTypes = {
  section: PropTypes.object,
  sectionsList: PropTypes.array,
  page: PropTypes.number,
  setSections: PropTypes.func,
  setPage: PropTypes.func,
};

export default function SectionPanel({ section, sectionsList, page, setSections, setPage }) {
  const { getSectionQuestions, updateFormData } = useFormContext();
  const { t } = useTranslation();
  const [sectionQuestions, setSectionQuestions] = useState(null);
  const [description, setDescription] = useState('');
  useEffect(() => {
    const questionList = getSectionQuestions(section.id);
    setSectionQuestions(questionList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSectionQuestions, section.id]);

  useEffect(() => {
    if (section.nextSection && section.nextSection !== 'submit') {
      const nextSectionId = section.nextSection;
      const nextIndex = sectionsList.findIndex((section) => section.id === nextSectionId);
      const newSectionOrder = reorderArray(sectionsList, page, nextIndex);
      setSections(newSectionOrder.array);
      setPage(newSectionOrder.currentIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section.nextSection]);

  const setTicketDescription = (value) => {
    setDescription(value);
    updateFormData({ ticketDescription: value });
  };
  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader title={`${t('pages.formPage.section')} ${section.name}`} />
      <CardContent>
        {sectionQuestions?.map((question) => (
          <QuestionView
            key={`q${question.id}`}
            question={question}
            sectionsList={sectionsList}
            page={page}
            setSections={setSections}
            setPage={setPage}
          />
        ))}
        {section.nextSection === 'submit' && (
          <TextInput
            fullWidth
            multiline
            rows={3}
            defaultValue={description}
            label={t('pages.formPage.additonalDetail')}
            sx={{ marginTop: '2vh' }}
            onBlur={(e) => setTicketDescription(e.target.value)}
          />
        )}
      </CardContent>
    </Card>
  );
}
