import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Container, Button, Stack, Typography, Paper, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StatusGrid from '../../components/status/StatusGrid';
import StatusTable from '../../components/status/StatusTable';
import UserListToolbar from '../../sections/@dashboard/toolbar/FormToolbar';
import Iconify from '../../components/iconify';
import AddStatus from '../../components/status/AddStatus';
import { useModal } from '../../hooks/useModal';
import { fetchDepartments } from '../../services/department.service';
import DepartmentSelect from '../../sections/@dashboard/toolbar/DepartmentSelect';
import { useAuth } from '../../hooks/useAuth';
import { getUsersByDepartment } from '../../services/user.service';
import { applySortFilter, getComparator } from '../../utils/filterData';
import EditStatus from '../../components/status/EditStatus';
import { fetchStatuses, modifyStatus, createStatus, deleteStatus } from '../../services/status.service';
import { useToast } from '../../hooks/useToast';

export default function Status() {
  const [departmentList, setDepartmentList] = useState([]);
  const [options, setOptions] = useState([]);
  const { role, departmentId } = useAuth();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(departmentId);
  const [filteredStatusData, setFilteredStatusData] = useState([]);
  const [emailsList, setEmailsList] = useState([]);
  const [filterName, setFilterName] = useState('');
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('1');
  const [gridPage, setGridPage] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('statusId');
  const { showToast, hideToast } = useToast();
  const genericApiToaster = (apiCall, args, successMessage, errorMessage, successCallback, errorCallback) => {
    const apiArguments = Array.isArray(args) ? args : [args]; // Convert single argument to an array

    apiCall(...apiArguments)
      .then((res) => {
        showToast({ message: successMessage, severity: 'success', props: { hideToast } });
        if (successCallback) {
          successCallback(res);
        }
      })
      .catch((e) => {
        showToast({ message: errorMessage, severity: 'error', props: { hideToast } });
        if (errorCallback) {
          errorCallback(e);
        }
      });
  };
  // add status
  const addStatus = (newStatusData) => {
    genericApiToaster(
      createStatus,
      newStatusData,
      t('successMessages.successCreatingStatus'),
      t('errorMessages.couldntCreateStatus'),
      () => getStatuses()
    );
  };
  // edit status
  const editStatus = (editedStatusData) => {
    genericApiToaster(
      modifyStatus,
      editedStatusData,
      t('successMessages.successModifyingStatus'),
      t('errorMessages.couldntModifyStatus'),
      () => getStatuses()
    );
  };

  // delete status
  const delStatus = (id) => {
    genericApiToaster(
      deleteStatus,
      id,
      t('successMessages.successDeletingStatus'),
      t('errorMessages.couldntDeleteStatus'),
      () => getStatuses()
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const getDepartMents = async () => {
    const departments = await fetchDepartments();
    setDepartmentList(departments);
  };
  const handleChangeRowsPerPage = (event) => {
    setTablePage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleChangeTablePage = (event, newPage) => {
    setTablePage(newPage);
  };
  const itemsPerPage = 10;
  useEffect(() => {
    if (role.authority === 'ROLE_ADMIN') getDepartMents();
    else setSelectedDepartmentId(departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDetailsClick = (status) => {
    showModal({
      title: t('pages.statusPage.editStatusForm'),
      Component: EditStatus,
      props: { hideModal, status, role: role.authority, departmentList, emailsList, editStatus },
    });
  };

  const StatusColorMapper = (status) => {
    const colorMap = { 1: '#d32f2f', 2: '#4caf50', 3: '#d32f2f', 4: '#ed6c02', 5: '#1976d2', default: status.color };
    return colorMap[status.statusId];
  };

  useEffect(() => {
    const opt = [{ id: 0, name: 'All Departments' }];
    departmentList.forEach((department) => {
      opt.push({ id: department.id, name: department.name });
    });
    setOptions(opt);
  }, [departmentList]);

  const getStatuses = () => {
    if (selectedDepartmentId === 0) {
      fetchStatuses().then((res) => {
        const updatedStatuses = res.map((status) => {
          const statusColor = StatusColorMapper(status);

          if (status.statusId <= 5) {
            return {
              ...status,
              statusColor,
            };
          }
          return status;
        });
        setFilteredStatusData(updatedStatuses);
      });

      setTablePage(0);
      setGridPage(0);
      setEmailsList([]);
    } else {
      fetchStatuses().then((res) => {
        const updatedStatuses = res.map((status) => {
          const statusColor = StatusColorMapper(status);
          if (status.statusId <= 5) {
            return {
              ...status,
              statusColor,
            };
          }
          return status;
        });
        setFilteredStatusData(
          updatedStatuses.filter((status) =>
            role.authority === 'ROLE_ADMIN'
              ? status.departmentId === selectedDepartmentId
              : status.departmentId === selectedDepartmentId || status.statusId < 6
          )
        );
      });
      setTablePage(0);
      setGridPage(0);
      getUsersByDepartment(selectedDepartmentId).then((res) => setEmailsList(res.map((user) => user.email)));
    }
  };
  useEffect(() => {
    getStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentId]);

  const filteredStatus = applySortFilter(filteredStatusData, getComparator(order, orderBy), filterName, 'name');
  const isNotFound = !filteredStatus.length && !!filterName;

  const { showModal, hideModal } = useModal();

  const handleFilterByName = (event) => {
    setGridPage(0);
    setTablePage(0);
    setFilterName(event.target.value);
  };

  const handleChangePageGrid = (event, newPage) => {
    setGridPage(newPage - 1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Helmet>
        <title>{t('pages.statusPage.statusSingle')}</title>
      </Helmet>
      <Container>
        <Stack px={3} direction="row" justifyContent="space-between">
          <Typography variant="h4"> {t('pages.statusPage.statusPlural')} </Typography>
          <Button
            variant="contained"
            id="add-status-btn"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() =>
              showModal({
                title: t('pages.statusPage.addStatusForm'),
                Component: AddStatus,
                props: {
                  hideModal,
                  emailsList,
                  departmentList,
                  role: role.authority,
                  addStatus,
                  currentUserDepartment: departmentId,
                },
              })
            }
            sx={{ marginRight: '1vh' }}
          >
            {t('pages.statusPage.newStatus')}
          </Button>
        </Stack>
        {role.authority === 'ROLE_ADMIN' && (
          <Box pt={2} px={3}>
            <DepartmentSelect options={options} setSelectedDepId={setSelectedDepartmentId} />
          </Box>
        )}

        <Box mb={3}>
          <UserListToolbar
            numSelected={0}
            model={t('pages.statusPage.statusSingle')}
            onFilterName={handleFilterByName}
            filterName={filterName}
          />
        </Box>
        <TabContext value={tabValue}>
          <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
            <TabList onChange={handleTabChange} aria-label="status-tabs">
              <Tab onClick={() => setOrderBy('statusId')} icon={<Iconify icon="gridicons:grid" />} value="1" />
              <Tab icon={<Iconify icon="gridicons:list-unordered" />} value="2" />
            </TabList>
          </Stack>
          <TabPanel value="1">
            <StatusGrid
              statusData={filteredStatus}
              itemsPerPage={itemsPerPage}
              page={gridPage}
              handleChangePage={handleChangePageGrid}
              handleDetailsClick={handleDetailsClick}
              deleteStatus={delStatus}
            />
          </TabPanel>
          <TabPanel value="2">
            <StatusTable
              statusData={filteredStatus}
              page={tablePage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangeTablePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              handleDetailsClick={handleDetailsClick}
              deleteStatus={delStatus}
            />
          </TabPanel>
        </TabContext>

        {isNotFound && (
          <Paper
            sx={{
              my: 5,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" paragraph>
              {t('filters.notFound')}
            </Typography>

            <Typography variant="body2">{t('filters.noResultsFound', { filterName })}</Typography>
          </Paper>
        )}
      </Container>
    </>
  );
}
