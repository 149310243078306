import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled, alpha } from '@mui/material/styles';
import { InputAdornment, OutlinedInput } from '@mui/material';
import Iconify from '../../../components/iconify';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

SearchBar.propTypes = {
  filterName: PropTypes.string,
  handleFilterByName: PropTypes.func,
  model: PropTypes.string,
};
export default function SearchBar({ filterName, handleFilterByName, model }) {
  const { t } = useTranslation();
  return (
    <StyledSearch
      value={filterName}
      onChange={handleFilterByName}
      placeholder={`${t('filters.search')} ${model}...`}
      startAdornment={
        <InputAdornment position="start">
          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
        </InputAdornment>
      }
    />
  );
}
