import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useMemo } from 'react';
import secureLocalStorage from 'react-secure-storage';
// @mui
import { Grid, Container, Typography, Stack } from '@mui/material';

// api's
import { fetchTicketsByDate } from '../../services/ticket.service';
import { getDepartmentById } from '../../services/department.service';
import { getUsersByDepartment } from '../../services/user.service';
import { fetchStatuses } from '../../services/status.service';
// sections
import { DashboardToolbar } from '../../sections/@dashboard/toolbar';
import { AppWidgetSummary, PieChart, BarChart } from '../../sections/@dashboard/Charts';
import DepartmentSelect from '../../sections/@dashboard/toolbar/DepartmentSelect';
// hooks
import { useAuth } from '../../hooks/useAuth';
// ----------------------------------------------------------------------

export default function Dashboard() {
  const [department, setDepartmentData] = useState({ id: 0, name: '' });
  const [ticketCount, setTicketCount] = useState({ open: 0, onhold: 0, resolved: 0, closed: 0, unassigned: 0 });
  const [tickets, setTickets] = useState([]);
  const [agents, setAgents] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [statusCounts, setStatusCounts] = useState({});
  const { t } = useTranslation();
  const { username, departmentId } = useAuth();

  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const fetchDepartment = async () => {
    const department = await getDepartmentById(departmentId);
    setDepartmentData(department);
  };

  const getStatuses = (depId) => {
    if (depId === 0) {
      fetchStatuses().then((res) => {
        setStatusesList(res);
      });
    } else {
      fetchStatuses().then((res) => {
        setStatusesList(res.filter((status) => status.departmentId === depId || status.departmentId === 0));
      });
    }
  };
  useEffect(() => {
    getStatuses(departmentId);
  }, [departmentId]);
  const fetchAgents = async () => {
    const agentsRes = await getUsersByDepartment(departmentId);
    setAgents(agentsRes.filter((user) => user.appUserRole === 'ROLE_AGENT'));
  };
  const getTicketsByDate = async ({ startDate, endDate, attributeName }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    const tickets = await fetchTicketsByDate({ startDate: start, endDate: end, attributeName });
    const updatedTickets = tickets.map((ticket) => {
      const statusObject = statusesList.find((status) => status.statusId === ticket.status_id);
      return { ...ticket, statusObject };
    });
    setTickets(updatedTickets.filter((ticket) => ticket.departmentId === departmentId));
  };

  useEffect(() => {
    setTicketCount({
      open: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'OPEN').length || 0,
      onhold: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'ONHOLD').length || 0,
      resolved: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED').length || 0,
      closed: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'CLOSED').length || 0,
      unassigned: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'UNASSIGNED').length || 0,
    });
  }, [tickets]);
  useEffect(() => {
    // Function to calculate status counts
    const calculateStatusCounts = () => {
      const counts = {};

      // Iterate through tickets to count statuses
      tickets.forEach((ticket) => {
        const { statusObject } = ticket;
        if (statusObject) {
          const { name } = statusObject;

          // Increment count for the status name
          counts[name] = (counts[name] || 0) + 1;
        }
      });

      // Set the status counts state
      setStatusCounts(counts);
    };

    // Call the function to calculate status counts
    calculateStatusCounts();
  }, [tickets]);

  const ticketsByAgent = useMemo(() => {
    const agentTickets = tickets.filter((ticket) => ticket.agentName !== null);
    const ticketsByAgent = agentTickets.reduce((accumulator, ticket) => {
      const { agentName: agent, assignedTo: agentId } = ticket;
      if (!accumulator[agentId]) {
        accumulator[agentId] = { agent, agentId, tickets: [] };
      }
      accumulator[agentId].tickets.push(ticket);

      return accumulator;
    }, {});
    const result = Object.values(ticketsByAgent);
    return result;
  }, [tickets]);

  useEffect(() => {
    fetchDepartment();
    getTicketsByDate({
      startDate: start,
      endDate: end,
      attributeName: 'created',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList]);

  useEffect(() => {
    fetchAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h6" sx={{ mb: 5 }}>
          {t('dashboard.greeting')} {username || ''} !
        </Typography>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Grid item xs={10}>
            <DashboardToolbar getTicketsByDate={getTicketsByDate} start={start} end={end} />
          </Grid>
          <Grid item xs={2}>
            <DepartmentSelect
              options={[{ id: department.id, name: department.name }]}
              setSelectedDepId={setSelectedDepartmentId}
            />
          </Grid>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.totalTickets')}
              total={tickets.length}
              color="warning"
              icon={'ph:ticket'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.unassignedTickets')}
              total={ticketCount.unassigned}
              color="error"
              icon={'system-uicons:ticket'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.totalAgents')}
              total={agents.length}
              color="info"
              icon={'mdi:face-agent'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.closureRate')}
              total={(ticketCount.resolved / tickets.length) * 100 || 0}
              icon={'material-symbols:percent'}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <BarChart
              title={t('dashboard.bestPerAgents')}
              subheader={department.name}
              chartData={ticketsByAgent.map((agent) => ({
                label: agent.agent,
                value: agent.tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED').length,
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <PieChart
              title={t('dashboard.ticketByStatus')}
              chartData={statusesList.map((status) => {
                const statusName = status.statusId < 6 ? t(`status.${status.name}`) : status.name;
                return { label: statusName, value: statusCounts[status.name] || 0 };
              })}
              chartColors={statusesList.map((status) => {
                switch (status.statusId) {
                  case 1:
                    return '#d32f2f';
                  case 2:
                    return '#4caf50';
                  case 3:
                    return '#9c27b0';
                  case 4:
                    return '#ed6c02';
                  case 5:
                    return '#1976d2';
                  default:
                    return status.color;
                }
              })}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
