import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextField, Typography, Box, Badge, Paper, Stack, Button, IconButton, Avatar } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Message from './message/Message';
import Iconify from '../iconify/Iconify';
import formatRelativeTime from '../../utils/formatRelativeTime';
import convertLineReturnToHTML from '../../utils/convertLineReturn';
import FileInput from '../inputs/fileInput';
import { useModal } from '../../hooks/useModal';
import AttachmentsList from './AttachmentsList';
import { convertLocalTime } from '../../utils/convertTime';

TicketConversation.propTypes = {
  currentUserId: PropTypes.number,
  commentsData: PropTypes.array,
  row: PropTypes.object,
  createComment: PropTypes.func,
  fetchCommentsPerTicketId: PropTypes.func,
  fetchUser: PropTypes.func,
  ticketFilesList: PropTypes.array,
  getTicketFiles: PropTypes.func,
  customerAvatar: PropTypes.string,
  agentAvatar: PropTypes.string,
  currentStatus: PropTypes.object,
  statusesList: PropTypes.array,
};
export default function TicketConversation({
  currentUserId,
  row,
  commentsData,
  createComment,
  fetchCommentsPerTicketId,
  fetchUser,
  ticketFilesList,
  getTicketFiles,
  customerAvatar,
  agentAvatar,
  currentStatus,
  statusesList,
}) {
  const { showModal, hideModal } = useModal();
  const [rows, setRows] = useState(4); // Default rows value
  const [formdata, setFormData] = useState([]);
  const { register, handleSubmit, reset, getValues, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      id: '',
      message: '',
      avatar: '',
      user: '',
      time: '',
    },
  });

  useEffect(() => {
    const data = row?.data.flatMap((section) =>
      section?.questions?.map((question) => ({
        question: question.text,
        answer: question.inputValue,
      }))
    );
    setFormData(data);
  }, [row?.data]);
  const textFieldRef = useRef(null);
  const { t } = useTranslation();
  const isDisabled =
    row &&
    currentStatus &&
    (currentStatus.systemMappedStatus === 'UNASSIGNED' ||
      (currentUserId !== row.createdBy && currentUserId !== row.assignedTo));
  useEffect(() => {
    // Check the screen height and set rows accordingly

    const handleResize = () => {
      const screenHeight = window.innerHeight;
      if (screenHeight < 600) {
        setRows(2);
      } else if (screenHeight < 800) {
        setRows(3);
      } else {
        setRows(4);
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      const messageValue = getValues('message');
      setValue('message', `${messageValue}\n`);

      if (textFieldRef.current) {
        textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
      }
    } else if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };
  const onUpload = async (file) => {
    createComment({
      userId: currentUserId,
      message: file,
      messageType: 'FILE',
    });

    fetchCommentsPerTicketId(row.id);
    getTicketFiles();
  };

  const onSubmit = (data) => {
    createComment({
      userId: currentUserId,
      message: data.message,
      messageType: 'MESSAGE',
    });
    reset();
  };
  const bottomElementRef = useRef(null);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (bottomElementRef.current) {
      bottomElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 250);
  }, [commentsData]);

  return (
    <Box sx={{ borderRadius: 0 }}>
      <Paper
        sx={{
          bgcolor: '#F9F9F9',
          p: 1.5,
          height: '100vh',

          boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box
          sx={{
            backgroundImage: 'url(/assets/images/covers/bluepattern.avif)',
            boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: 3,
            height: '100%',
            backgroundSize: 'cover',
          }}
        >
          <>
            <Box sx={{ p: 1, py: 2, height: '10%' }}>
              <Stack direction="row" justifyContent="space-between">
                <Stack>
                  <Stack spacing={1} alignItems="center" direction="row">
                    {row?.assignedTo === currentUserId ? (
                      <>
                        <Avatar src={customerAvatar} alt="" />
                        <Typography variant="h6" color="#4a4a4a">
                          {row.customerName}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Avatar src={agentAvatar} alt="" />
                        <Typography variant="h6" color="#4a4a4a">
                          {row?.agentName || 'agentName'}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Badge
                    badgeContent={ticketFilesList.length}
                    color="error"
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <IconButton
                      size="medium"
                      color="inherit"
                      onClick={() =>
                        showModal({
                          title: t('pages.ticketDetails.listAttachments'),
                          Component: AttachmentsList,
                          props: { hideModal, ticketFilesList },
                        })
                      }
                    >
                      <Iconify
                        sx={{
                          '@media (max-height: 600px)': {
                            width: 25,
                          },
                        }}
                        color="#4a4a4a"
                        width={35}
                        icon={'teenyicons:attachment-outline'}
                      />
                    </IconButton>
                  </Badge>
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                height: '60%',
                p: 1,
                overflow: 'auto',
                flexDirection: 'column-reverse',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: '3px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  },
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {row && (
                <Message
                  key={0}
                  message={formdata?.map((text, index) => (
                    <li key={index}>
                      <strong>{t('pages.ticketDetails.question')} : </strong> {text.question}
                      <br />
                      <strong>{t('pages.ticketDetails.answer')} : </strong>
                      {typeof text.answer === 'string' ? convertLineReturnToHTML(text.answer) : text.answer}
                    </li>
                  ))}
                  messageType="MESSAGE"
                  userId={row.createdBy}
                  time={formatRelativeTime(
                    convertLocalTime(row.createdAt),
                    t('filters.dates.today'),
                    t('filters.dates.yesterday'),
                    t('pages.ticketDetails.at')
                  )}
                  fetchUser={fetchUser}
                />
              )}
              <Stack>
                {commentsData.map((message) => (
                  <Message
                    key={`message${message.id}`}
                    message={message.message}
                    messageType={message.messageType}
                    userId={message.sender}
                    statusesList={statusesList}
                    time={formatRelativeTime(
                      convertLocalTime(message.sentAt),
                      t('filters.dates.today'),
                      t('filters.dates.yesterday'),
                      t('pages.ticketDetails.at')
                    )}
                    fetchUser={fetchUser}
                  />
                ))}
              </Stack>
              <Box ref={bottomElementRef} />
            </Box>
          </>

          <form onSubmit={handleSubmit(onSubmit)} key="message-box">
            <Box
              sx={{
                px: 2,
                pt: 4,
                height: '30%',
                '@media (max-height: 600px)': {
                  pt: 1,
                },
              }}
            >
              <TextField
                sx={{
                  bgcolor: isDisabled ? '#D3D3D3' : 'white',
                }}
                rows={rows}
                fullWidth
                disabled={isDisabled}
                multiline
                placeholder={t('pages.ticketDetails.addMessage')}
                onKeyDown={handleEnterKeyPress}
                inputRef={textFieldRef}
                {...register('message', { required: t('formControl.enterMessage') })}
              />

              <Stack justifyContent="space-between" direction="row">
                <Stack spacing={1} direction="row">
                  <IconButton
                    disabled={isDisabled}
                    size="medium"
                    onClick={() =>
                      showModal({
                        title: t('inputs.file.fileInput'),
                        Component: FileInput,
                        props: { hideModal, onUpload, ticketId: row.id },
                      })
                    }
                  >
                    <Iconify color="#4a4a4a" icon={'eva:attach-2-outline'} />
                  </IconButton>
                </Stack>
                <Button disabled={isDisabled} variant="text" size="small" type="submit" sx={{ m: 1 }}>
                  <Typography color="#4a4a4a">{t('buttons.send')}</Typography>
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Paper>
    </Box>
  );
}
