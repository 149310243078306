import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, InputAdornment, IconButton, Link, Alert, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import jwtDecode from 'jwt-decode';
import { useAuth } from '../../../hooks/useAuth';
import Iconify from '../../../components/iconify';
import { EMAIL_REGEX } from '../../../utils/regex';
import authService from '../../../services/auth.service';
import Logo from '../../../components/logo';

LoginForm.propTypes = {
  toggleForgotPassword: PropTypes.func,
};

export default function LoginForm({ toggleForgotPassword }) {
  const HoverableText = styled('p')({
    cursor: 'pointer',
  });
  const navigate = useNavigate();
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const handleForgotPassword = () => {
    toggleForgotPassword();
    reset();
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { t } = useTranslation();

  const handleClick = () => {};
  const onSubmit = (data) => {
    authService
      .login(data)
      .then((res) => {
        login(res.authenticationToken);
        const { firstLogin, id } = jwtDecode(res.authenticationToken);
        if (firstLogin) navigate('/firstLogin', { replace: true, state: { userId: id, oldPassword: data.password } });
        else {
          navigate('/dashboard', { replace: true });
        }
      })
      .catch((e) => {
        setError(e);
      });
  };

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Logo
        alignSelf="center"
        sx={{
          width: '40vh',
          height: '20vh',
        }}
      />
      <Typography variant="h3" alignSelf="center">
        {t('pages.login.signIn')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <br />
          {error && (
            <Stack sx={{ width: '100%' }}>
              <Alert severity="error">
                <strong>{t('pages.login.email/password')}</strong> {t('pages.login.valueIncorrect')}
              </Alert>
            </Stack>
          )}
          <TextField
            name="email"
            id="login-email"
            label={t('pages.usersPage.form.emailAdress')}
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message || ''}
            {...register('email', {
              required: t('formControl.enterEmail'),
              pattern: {
                value: EMAIL_REGEX,
                message: t('formControl.invalidEmail'),
              },
            })}
          />

          <TextField
            name="password"
            id="login-password"
            label={t('attributes.password')}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password?.message || ''}
            {...register('password', {
              required: t('formControl.insertPassword'),
            })}
          />
        </Stack>
        <Link variant="subtitle2" underline="hover" onClick={handleForgotPassword}>
          <HoverableText> {t('pages.login.forgotPassword')} </HoverableText>
        </Link>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton
            id="login-button"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleClick}
            disabled={!isValid}
          >
            {t('buttons.login')}
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
}
