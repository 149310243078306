import PropTypes from 'prop-types';
import React from 'react';
import { Stack, Typography, Avatar, Card } from '@mui/material';
import convertLineReturnToHTML from '../../../utils/convertLineReturn';

TextMessage.propTypes = {
  time: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isMine: PropTypes.bool,
  user: PropTypes.object,
};
export default function TextMessage({ time, message, isMine, user }) {
  return (
    <Stack justifyContent="space-between">
      <Typography color="#4a4a4a" variant="caption" textAlign="center">
        {time}
      </Typography>
      <Stack direction="row" justifyContent="center" spacing={2}>
        {!isMine && <Avatar src={user.avatar ?? ''} alt="avatarUrl" sx={{ width: 30, height: 30 }} />}

        <Stack sx={{ width: '80%' }} spacing={1}>
          <Typography variant="body1" color="#4a4a4a" sx={{ textAlign: isMine ? 'right' : 'left' }}>
            {user.username}
          </Typography>
          <Card
            sx={{
              bgcolor: isMine ? '#757de8' : 'white',
              alignSelf: isMine ? 'flex-end' : 'flex-start',
              width: '70%',
              wordWrap: 'break-word',
              p: 2,
              borderRadius: 3,
            }}
          >
            <Typography variant="body2" sx={{ textAlign: 'left', color: isMine ? 'white' : '#4a4a4a' }}>
              {typeof message === 'string' ? convertLineReturnToHTML(message) : message}
            </Typography>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
}
