import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// @mui
import { styled } from '@mui/material/styles';
import { Grid, Box, Paper, Typography, CssBaseline } from '@mui/material';
// components
import { useTheme } from '@emotion/react';
// sections
import { LoginForm } from '../../sections/auth/login';
import ForgotPassword from '../../sections/auth/ForgotPassword';
import Copyright from '../../components/copyright/CopyRight';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const [forgotPassword, setForgotPassword] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const toggleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };
  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System Login </title>
      </Helmet>
      <StyledRoot>
        <Grid
          container
          component="main"
          sx={{
            minHeight: '100vh',
            height: '100%',
            color: 'white',
            background: 'rgb(80 85 127)',
            [theme.breakpoints.up('md')]: {
              paddingTop: '1%',
              paddingX: '10%',
            },
          }}
        >
          <CssBaseline />
          <Grid container sx={{ margin: 'auto', minHeight: '85vh' }}>
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url('/assets/images/covers/AnimationAlgorLeap.gif')`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
              }}
            />

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 3,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ width: '80%' }}>
                  {forgotPassword ? (
                    <ForgotPassword toggleForgotPassword={toggleForgotPassword} />
                  ) : (
                    <LoginForm toggleForgotPassword={toggleForgotPassword} />
                  )}
                  <Copyright />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Typography
            spacing={0}
            variant="body1"
            alignSelf="center"
            sx={{
              mt: 2,
              mb: 0,
              color: 'white',
              textAlign: 'center',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('pages.login.poweredBy')}
            <img src="/assets/icons/AlgorLeapLogo.svg" height={75} alt="Algor" />
          </Typography>
        </Grid>
      </StyledRoot>
    </>
  );
}
