import axios from 'axios';
import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/notification`;

// fetch notifications
export const fetchNotificationsByUserId = async (id, page, size) => {
  const res = await axios.get(`${url}/byconcerns/${id}?page=${page}&size=${size}&sort=createdAt,DESC`, {
    headers: getAuthorizationHeader(),
  });
  return res.data;
};

// mark notification as read
export const markNotificationReadById = async (id) => {
  const res = await axios.put(
    `${url}/isread/${id}`,
    {},
    {
      headers: getAuthorizationHeader(),
    }
  );
  return res;
};

// mark all as read
export const markAllAsReadById = async (id) => {
  const res = await axios.put(
    `${url}/readall/${id}`,
    {},
    {
      headers: getAuthorizationHeader(),
    }
  );
  return res;
};
