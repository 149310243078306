import React from 'react';
import { SpeedDial, SpeedDialIcon, SpeedDialAction, useScrollTrigger } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Iconify from '../iconify';

export const FloatingButton = () => {
  const navigate = useNavigate();

  const actions = [
    {
      icon: <Iconify icon="fluent-emoji:ticket" />,
      name: 'Create new ticket',
      onClick: () => navigate('/dashboard/newTicket'),
    },
  ];
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      icon={<SpeedDialIcon />}
      variant="contained"
      color="primary"
      sx={{
        position: 'fixed',
        bottom: trigger ? '20px' : '50px',
        right: '1vh',
        zIndex: 1000,
        transition: 'bottom 0.3s',
      }}
    >
      {actions.map((action) => (
        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.onClick} />
      ))}
    </SpeedDial>
  );
};
