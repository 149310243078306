import {
  Typography,
  Box,
  Stack,
  TextField,
  ToggleButton,
  IconButton,
  ToggleButtonGroup,
  InputAdornment,
  Button,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';
import { useToast } from '../../hooks/useToast';
import { getSettings, updateSettings } from '../../services/configuration.service';
import Iconify from '../../components/iconify';
import { EMAIL_REGEX } from '../../utils/regex';

export default function Settings() {
  const [startHours, setStartHours] = useState(dayjs('06:00:00', 'HH:mm:ss'));
  const [endHours, setEndHours] = useState(dayjs('18:00:00', 'HH:mm:ss'));
  const [breakStart, setBreakStart] = useState(dayjs('13:00:00', 'HH:mm:ss'));
  const [breakEnd, setBreakEnd] = useState(dayjs('14:00:00', 'HH:mm:ss'));
  const [workDays, setWorkDays] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      emailSecret: '',
      endTime: 0,
      startTime: 0,
      workingDays: [],
    },
  });

  const numberOfWorkHours = useMemo(
    () => Number((endHours.diff(startHours, 'minute') - breakEnd.diff(breakStart, 'minute')) / 60).toFixed(1),
    [startHours, endHours, breakStart, breakEnd]
  );

  const fetchSettings = () => {
    getSettings()
      .then((res) => {
        const defaultValues = {
          email: res.data?.email || '',
          emailSecret: res.data?.emailSecret || '',
          endTime: res.data?.endTime || '18:00:00',
          startTime: res.data?.startTime || '09:00:00',
          breakStart: res.data?.endTime || '13:00:00',
          breakEnd: res.data?.startTime || '14:00:00',
          workingDays: res.data?.workingDays || [],
        };
        reset({ ...defaultValues });
        setWorkDays(res.data?.workingDays);
        setStartHours(dayjs(res.data.startTime, 'HH:mm:ss'));
        setEndHours(dayjs(res.data.endTime, 'HH:mm:ss'));
        setBreakStart(dayjs(res.data.breakStart, 'HH:mm:ss'));
        setBreakEnd(dayjs(res.data.breakEnd, 'HH:mm:ss'));
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadSettings'), severity: 'error', props: { hideToast } });
      });
  };

  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, day) => {
    setWorkDays(day);
  };

  const onSubmit = (data) => {
    data.startTime = startHours.format('HH:mm:ss');
    data.endTime = endHours.format('HH:mm:ss');
    data.workingHours = Number(numberOfWorkHours)*60*60;
    data.workingDays = workDays;
    data.breakStart = breakStart.format('HH:mm:ss');
    data.breakEnd = breakEnd.format('HH:mm:ss');
    updateSettings(data)
      .then(() =>
        showToast({ message: t('successMessages.successSettingsUpdate'), severity: 'success', props: { hideToast } })
      )
      .catch(() =>
        showToast({ message: t('errorMessages.couldntUpdateInfo'), severity: 'error', props: { hideToast } })
      );
  };
  return (
    <Box bgcolor="#f1f1f1" borderRadius={5} p={3}>
      <Typography variant="h3" mb={3}>
        {t('pages.settings.applicationSettings')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5}>
          <Box bgcolor="white" borderRadius={5} p={4}>
            <Stack spacing={2}>
              <Typography variant="h6">{t('pages.settings.workingHours')}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={3}>
                  <TimePicker
                    label={t('attributes.start')}
                    value={startHours}
                    onChange={(newValue) => {
                      setStartHours(newValue);
                    }}
                  />
                  <TimePicker
                    label={t('attributes.end')}
                    value={endHours}
                    onChange={(newValue) => setEndHours(newValue)}
                  />
                </Stack>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={3}>
                  {/* ... (other time pickers) */}
                  <TimePicker
                    label={t('pages.settings.breakStart')}
                    value={breakStart}
                    onChange={(newValue) => {
                      setBreakStart(newValue);
                    }}
                  />
                  <TimePicker
                    label={t('pages.settings.breakEnd')}
                    value={breakEnd}
                    onChange={(newValue) => setBreakEnd(newValue)}
                  />
                </Stack>
              </LocalizationProvider>
              <Typography variant="h6">
                {t('pages.settings.numberOfWorkHours')} : {numberOfWorkHours}h
              </Typography>

              <Typography variant="h6" mb={2}>
                {t('pages.settings.workingDays')}
              </Typography>
              <Stack direction="row" spacing={2}>
                <ToggleButtonGroup color="primary" onChange={handleChange} value={workDays} sx={{ overflow: 'auto' }}>
                  {[
                    { value: 'SUNDAY', day: t('days.sunday') },
                    { value: 'MONDAY', day: t('days.monday') },
                    { value: 'TUESDAY', day: t('days.tuesday') },
                    { value: 'WEDNESDAY', day: t('days.wednesday') },
                    { value: 'THURSDAY', day: t('days.thursday') },
                    { value: 'FRIDAY', day: t('days.friday') },
                    { value: 'SATURDAY', day: t('days.saturday') },
                  ].map((item, index) => (
                    <ToggleButton key={index} value={item.value}>
                      {item.day}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            </Stack>
          </Box>
          <Box bgcolor="white" borderRadius={5} p={4}>
            <Stack spacing={2}>
              <Typography variant="h6"> {t('pages.settings.applicationEmail')}</Typography>
              <TextField
                name="email"
                label={t('attributes.email')}
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email?.message || ''}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('email', {
                  required: t('formControl.enterEmail'),
                  pattern: {
                    value: EMAIL_REGEX,
                    message: t('formControl.invalidEmail'),
                  },
                })}
              />
              <TextField
                name="password"
                autoComplete="current-password"
                label={t('pages.settings.emailSecret')}
                error={!!errors.emailSecret}
                helperText={errors.emailSecret?.message || ''}
                type={showPassword ? 'text' : 'password'}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('emailSecret', {
                  required: t('formControl.provideEmailSecret'),
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Box>
          <Button type="submit" variant="outlined" disabled={!isValid}>
            {t('buttons.confirmChanges')}
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
