import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Stack,
  TextField,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  Autocomplete,
  InputLabel,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getUsersByDepartment } from '../../services/user.service';
import { EMAIL_REGEX } from '../../utils/regex';

EditStatus.propTypes = {
  hideModal: PropTypes.func,
  emailsList: PropTypes.array,
  departmentList: PropTypes.array,
  role: PropTypes.string,
  status: PropTypes.object,
  editStatus: PropTypes.func,
};

export default function EditStatus({ hideModal, emailsList, departmentList, role, status, editStatus }) {
  const [color, setColor] = useState(status.statusId <= 5 ? status.statusColor : status.color || '#000000');
  const [emails, setEmails] = useState(status.correspondences);
  const [emailValid, setEmailValid] = useState(true);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();
  const handleHtmlColorChange = (event) => {
    setColor(event.target.value);
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: status.statusId,
      description: status.statusId <= 5 ? t(`statusDescription.${status.name}`) : status.description,
      name: status.statusId <= 5 ? t(`status.${status.name}`) : status.name,
      correspondences: status.correspondences,
      color: status.statusId <= 5 ? status.statusColor : status.color,
      systemMappedStatus: status.systemMappedStatus,
      departmentId: status.departmentId,
    },
  });
  const handleAddEmail = (event, newValue) => {
    let validEmails = true;
    newValue.forEach((email) => {
      if (EMAIL_REGEX.test(email) === false) {
        validEmails = false;
      }
    });
    if (validEmails) {
      setEmailValid(true);
      setEmails(newValue);
    } else if (newValue.length !== 0) {
      setEmailValid(false);
    }
  };
  useEffect(() => {
    if (role !== 'ROLE_ADMIN') setEmailSuggestions(emailsList);
  }, [emailsList, role]);
  const selectedDepartment = watch('departmentId');
  useEffect(() => {
    if (selectedDepartment)
      getUsersByDepartment(selectedDepartment).then((res) => setEmailSuggestions(res.map((user) => user.email)));
  }, [selectedDepartment]);
  const onSubmit = (data) => {
    data.color = color;
    data.correspondences = emails;
    editStatus(data);
    // reset();
    hideModal();
  };
  const handleKeyDown = (event) => {
    if ((event.key === ' ' || event.key === ',') && event.target.value !== '') {
      event.preventDefault();
      event.stopPropagation();
      handleAddEmail(event, [...emails, event.target.value]);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack my={2} spacing={3}>
        <TextField
          id="status-name"
          name="name"
          label={t('tableHeads.status.name')}
          error={!!errors.name}
          disabled={status.statusId <= 5}
          helperText={errors.name?.message || ''}
          {...register('name', { required: t('formControl.enterName') })}
        />
        <TextField
          multiline
          id="status-description"
          minRows={3}
          name="description"
          disabled={status.statusId <= 5}
          label={t('tableHeads.status.description')}
          error={!!errors.description}
          helperText={errors.description?.message || ''}
          {...register('description', { required: t('formControl.enterDescription') })}
        />
        {role === 'ROLE_ADMIN' && status.statusId > 5 && (
          <FormControl fullWidth error={!!errors.departmentId} data-testid="user-department-select-control">
            <InputLabel id="department-select-label">{t('tableHeads.status.department')}</InputLabel>
            <Select
              labelId="department-select-label"
              id="department-select"
              inputProps={{
                'data-testid': 'department-select',
              }}
              disabled
              label={t('tableHeads.status.department')}
              defaultValue={status.departmentId}
              {...register('departmentId', {
                required: t('formControl.selectDepartment'),
              })}
            >
              {departmentList.map((department) => {
                const { id, name } = department;

                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>

            <FormHelperText>{errors.departmentId?.message}</FormHelperText>
          </FormControl>
        )}

        <FormControl fullWidth error={!!errors.appUserRole}>
          <InputLabel id="demo-simple-select-label"> {t('tableHeads.status.systemState')}</InputLabel>

          <Select
            name="systemMappedStatus"
            labelId="demo-simple-select-label"
            id="related-state-select"
            label={t('tableHeads.status.systemState')}
            disabled
            defaultValue={status.statusId > 5 ? status.systemMappedStatus : 'GLOBAL'}
            {...register('systemMappedStatus')}
          >
            {status.statusId <= 5 && <MenuItem value={'GLOBAL'}> {t('pages.statusPage.globalStatus')}</MenuItem>}
            <MenuItem value={'ONHOLD'}>{t('status.ONHOLD')}</MenuItem>
            <MenuItem value={'UNASSIGNED'}>{t('status.UNASSIGNED')}</MenuItem>
            <MenuItem value={'OPEN'}>{t('status.OPEN')}</MenuItem>
            <MenuItem value={'RESOLVED'}>{t('status.RESOLVED')}</MenuItem>
            <MenuItem value={'CLOSED'}>{t('status.CLOSED')}</MenuItem>
          </Select>
          <FormHelperText>{errors.systemMappedStatus?.message}</FormHelperText>
        </FormControl>
        {status.statusId > 5 && (
          <Autocomplete
            multiple
            error={!emailValid}
            id="email-input"
            options={emailSuggestions} // You can provide options for suggestions here
            freeSolo // Allows entering values not in the options
            defaultValue={status.correspondences}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('tableHeads.status.correspondances')}
                placeholder={t('pages.statusPage.typeEmail')}
                onKeyDown={(e) => handleKeyDown(e)}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                error={!emailValid}
                helperText={!emailValid ? t('pages.statusPage.invalidEmail') : ''}
              />
            )}
            onChange={(e, newValue) => {
              handleAddEmail(e, newValue);
            }}
            clearOnBlur
            onBlur={() => setEmailValid(true)}
            value={emails}
          />
        )}
        <Stack direction="row" id="color-picker" alignItems="center" spacing={1}>
          <Typography variant="h6"> {t('tableHeads.status.color')} : </Typography>
          <input
            style={{ cursor: 'pointer' }}
            type="color"
            value={color}
            onChange={handleHtmlColorChange}
            disabled={status.statusId <= 5}
          />
        </Stack>
      </Stack>

      <LoadingButton
        sx={{ mt: 2 }}
        id="new-stat-btn"
        fullWidth
        size="medium"
        type="submit"
        variant="contained"
        disabled={!isValid || !emailValid || status.statusId <= 5}
      >
        {t('pages.statusPage.editStatus')}
      </LoadingButton>
    </form>
  );
}
