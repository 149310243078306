import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, Stack, Typography, Button, Popover, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import secureLocalStorage from 'react-secure-storage';
// component
import dayjs from 'dayjs';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'start',
  padding: theme.spacing(0, 1, 0, 3),
  whiteSpace: 'pre',
}));

// ----------------------------------------------------------------------
DashboardToolbar.propTypes = {
  end: PropTypes.instanceOf(Date),
  start: PropTypes.instanceOf(Date),
  getTicketsByDate: PropTypes.func,
  setIsLoading: PropTypes.func,
};
export default function DashboardToolbar({ end, start, getTicketsByDate, setIsLoading }) {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(false);
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState(t(secureLocalStorage.getItem('sortby')));
  const handleStartDateChange = (date) => {
    secureLocalStorage.setItem('ticketStart', date);
    setStartDate(new Date(secureLocalStorage.getItem('ticketStart')));
  };
  const handleEndDateChange = (date) => {
    secureLocalStorage.setItem('ticketEnd', date);
    setEndDate(new Date(secureLocalStorage.getItem('ticketEnd')));
  };
  const handleClickMenu = (event) => {
    if (popoverAnchorEl) {
      setPopoverAnchorEl(null);
    } else {
      setPopoverAnchorEl(event.currentTarget);
    }
  };

  const handleApply = async (startDate, endDate) => {
    if (setIsLoading) setIsLoading(true);
    await getTicketsByDate({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      attributeName: 'created',
    });
    if (setIsLoading) setIsLoading(false);
  };
  const handleMenuClick = async (sortBy) => {
    const start = new Date();
    if (sortBy === 'thisMonth') start.setDate(1);
    else if (sortBy === 'thisWeek') start.setDate(start.getDate() - ((start.getDay() + 6) % 7));
    const end = new Date();
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);
    handleApply(start, end);
    handleEndDateChange(end);
    handleStartDateChange(start);
    setSortBy(sortBy);
    secureLocalStorage.setItem('sortby', sortBy);
    handleClickMenu();
  };

  return (
    <StyledRoot>
      <Stack direction="row" alignItems="center" spacing={2} key="1">
        <Box key="12">
          <Tooltip title="Filter by Date" sx={{ marginRight: '2vh' }}>
            <Button color="inherit" onClick={handleClickMenu}>
              <Iconify icon="subway:paragraph-2" />
              <Typography ml={2} variant="body1" key="sortBy">
                {t(`filters.dates.${sortBy}`)}
              </Typography>
            </Button>
          </Tooltip>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              value={dayjs(startDate)}
              onChange={handleStartDateChange}
              label={t('filters.dates.from')}
            />
          </LocalizationProvider>
        </Box>

        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              value={dayjs(endDate)}
              onChange={handleEndDateChange}
              label={t('filters.dates.to')}
            />
          </LocalizationProvider>
        </Box>

        <Button variant="outlined" onClick={() => handleApply(start, end)}>
          {t('buttons.apply')}
        </Button>
      </Stack>
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handleClickMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick('today');
          }}
        >
          {t('filters.dates.today')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClick('thisWeek');
          }}
        >
          {t('filters.dates.thisWeek')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClick('thisMonth');
          }}
        >
          {t('filters.dates.thisMonth')}
        </MenuItem>
      </Popover>
    </StyledRoot>
  );
}
