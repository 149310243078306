import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  TableCell,
  TableContainer,
  IconButton,
  Popover,
  MenuItem,
  Card,
  Typography,
  Stack,
  TablePagination,
} from '@mui/material';
import Scrollbar from '../scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import Iconify from '../iconify/Iconify';
import Label from '../label/Label';
import { useConfirmModal } from '../../hooks/useModal';

const TABLE_HEAD = [
  { id: 'name', label: 'status.name', alignCenter: true },
  { id: 'description', label: 'status.description', alignRight: false },
  { id: 'systemState', label: 'status.systemState', alignRight: false },
  { id: 'department', label: 'status.department', alignRight: false },
  { id: 'color', label: 'status.color', alignCenter: false },
  { id: 'correspondances', label: 'status.correspondances', alignCenter: true },
  { id: 'options', label: 'ticket.options' },
];

StatusTable.propTypes = {
  statusData: PropTypes.array.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  handleDetailsClick: PropTypes.func,
  deleteStatus: PropTypes.func,
};
export default function StatusTable({
  statusData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRequestSort,
  order,
  orderBy,
  handleDetailsClick,
  deleteStatus,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState([]);
  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };
  const { showConfirmModal } = useConfirmModal(
    t('pages.statusPage.confirmDelete'),
    `${t('pages.statusPage.areYouSure')} ${selected?.name}`,
    async () => deleteStatus(selected.statusId)
  );
  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={statusData.length}
                numSelected={0}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {statusData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                  const {
                    statusId,
                    statusColor,
                    name,
                    color,
                    correspondences,
                    description,
                    department,
                    systemMappedStatus,
                    departmentId,
                  } = row;

                  const statusName = statusId <= 5 ? t(`status.${name}`) : name;
                  const statusDescription = statusId <= 5 ? t(`statusDescription.${name}`) : description;
                  return (
                    <TableRow hover key={idx} sx={{ cursor: 'pointer' }} onClick={() => handleDetailsClick(row)}>
                      <TableCell component="th" scope="row">
                        <Stack alignItems="center">
                          <Label color={color}>{statusName}</Label>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{statusDescription}</Typography>
                      </TableCell>
                      <TableCell align="left">{t(`status.${systemMappedStatus}`)}</TableCell>
                      <TableCell align="left">
                        {statusId <= 5
                          ? t('pages.statusPage.globalStatus')
                          : departmentId === 0
                          ? t('pages.statusPage.noDepartment')
                          : department}
                      </TableCell>
                      <TableCell align="left">
                        <Stack alignItems="center" justifyContent="center">
                          <Box height={25} width={25} bgcolor={statusId <= 5 ? statusColor : color} borderRadius={1} />
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {statusId > 5 && <Stack alignItems="center">{correspondences.length}</Stack>}
                      </TableCell>
                      <TableCell align="right">
                        {statusId > 5 && (
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOpenMenu(event, row);
                              setSelected(row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[6, 12, 30]}
          component="div"
          labelRowsPerPage={t('filters.rowsPerPage')}
          count={statusData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleDetailsClick(selected);
            handleCloseMenu();
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('popover.edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            showConfirmModal();
            handleCloseMenu();
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('popover.delete')}
        </MenuItem>
      </Popover>
    </>
  );
}
