import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  MenuItem,
  Stack,
  TextField,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect, useState } from 'react';

ModifyTicket.propTypes = {
  row: PropTypes.object,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  modifyTicket: PropTypes.func,
  departmentData: PropTypes.array,
  statusesList: PropTypes.array,
  getStatuses: PropTypes.func,
  currentRole: PropTypes.string,
  departmentUsers: PropTypes.array,
  currentUserId: PropTypes.number,
};

export default function ModifyTicket({
  row,
  setOpen,
  open,
  modifyTicket,
  departmentData,
  getStatuses,
  statusesList,
  currentRole,
  departmentUsers,
  currentUserId,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: row?.id || 0,
      title: row?.title || '',
      description: row?.description || '',
      department: row?.department || '',
      priority: row?.priority || '',
      status: row?.status || '',
      createdAt: row?.createdAt || new Date(),
      department_id: row?.departmentId || 0,
      assignedTo: row?.assignedTo || -1,
      createdBy: row?.createdBy || -1,
      status_id: row?.status_id || 0,
    },
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [initialStatusObject, setInitialStatusObject] = useState({});
  const [selectedStatusObject, setSelectedStatusObject] = useState();
  const [showAgents, setShowAgents] = useState(false);
  useMemo(() => {
    const defaultValues = {
      id: row?.id || 0,
      title: row?.title || '',
      description: row?.description || '',
      department: row?.department || '',
      priority: row?.priority || '',
      status: row?.status || '',
      createdAt: row?.createdAt || new Date(),
      department_id: row?.departmentId || 0,
      assignedTo: row?.assignedTo || -1,
      createdBy: row?.createdBy || -1,
      status_id: row?.status_id || 0,
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  const onSubmit = (data) => {
    if (selectedStatusObject.systemMappedStatus === 'UNASSIGNED') {
      data.assignedTo = -1;
    }
    modifyTicket(data);
    handleClose();
    if (selectedStatusObject.systemMappedStatus === 'UNASSIGNED') {
      navigate(-1);
    }
  };
  const selectedDepartment = watch('department_id');
  const selectedStatusId = watch('status_id');
  useEffect(() => {
    const selectedObject = statusesList.find((status) => status.statusId === selectedStatusId);
    setSelectedStatusObject(selectedObject);
    if (
      initialStatusObject?.systemMappedStatus === 'UNASSIGNED' &&
      selectedObject?.systemMappedStatus !== 'UNASSIGNED'
    ) {
      if (currentRole === 'ROLE_AGENT') {
        setShowAgents(false);
        setValue('assignedTo', currentUserId);
      } else {
        setShowAgents(true);
      }
    } else {
      setShowAgents(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusId, statusesList]);

  useEffect(() => {
    setInitialStatusObject(statusesList.find((status) => status.statusId === row?.status_id));
  }, [row, statusesList]);

  useEffect(() => {
    getStatuses(selectedDepartment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment]);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <DialogTitle id="modal--title" variant="h6" component="h2">
          {t('pages.ticketDetails.modifyTicket')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <br />
              <FormControl fullWidth error={!!errors.department}>
                <InputLabel id="demo-simple-select-label">{t('attributes.department')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="department"
                  defaultValue={row?.department}
                  disabled
                  label={t('attributes.department')}
                  {...register('department', {
                    required: t('formControl.selectDepartment'),
                  })}
                >
                  {departmentData.map((department) => {
                    const { id, name } = department;
                    return (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.department?.message}</FormHelperText>
              </FormControl>
              <TextField
                name="title"
                label={t('attributes.title')}
                error={!!errors.title}
                helperText={errors.title?.message}
                {...register('title', {
                  required: t('formControl.enterTitle'),
                  maxLength: {
                    value: 50,
                    message: t('formControl.maxTitle'),
                  },
                })}
              />

              <TextField
                multiline
                rows={4}
                name="description"
                label={t('attributes.description')}
                error={!!errors.description}
                helperText={errors.description?.message || ''}
                {...register('description', {
                  maxLength: {
                    value: 255,
                    message: t('formControl.maxDescription'),
                  },
                })}
              />
              {currentRole !== 'ROLE_CUSTOMER' && (
                <>
                  <FormControl fullWidth error={!!errors.status_id}>
                    <InputLabel id="ticket-status-select-label">{t('attributes.status')}</InputLabel>
                    <Select
                      labelId="ticket-status-select-label"
                      id="status-select"
                      name="status_id"
                      defaultValue={row?.status_id ?? ''}
                      label={t('attributes.status')}
                      {...register('status_id')}
                    >
                      {statusesList?.map((status) => {
                        const { statusId, name } = status;
                        let tName = name;
                        if (statusId <= 5) tName = t(`status.${name}`);
                        return (
                          <MenuItem key={statusId} value={statusId}>
                            {tName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.status?.message}</FormHelperText>
                  </FormControl>
                  {showAgents && (
                    <FormControl fullWidth error={!!errors.status_id}>
                      <InputLabel id="ticket-assignedTo-select-label">{t('pages.ticketDetails.assignTo')}</InputLabel>
                      <Select
                        labelId="ticket-assignedTo-select-label"
                        id="assignedTo-select"
                        name="status_id"
                        defaultValue={row?.assignedTo ?? -1}
                        label={t('pages.ticketDetails.assignTo')}
                        {...register('assignedTo', {
                          required: t('formControl.selectTicketManager'),
                          validate: { assigned: (v) => parseInt(v, 10) !== -1 },
                        })}
                      >
                        <MenuItem key={-1} value={-1}>
                          {t('pages.ticketDetails.selectTicketManager')}
                        </MenuItem>

                        {departmentUsers?.map((user) => {
                          const { id, username } = user;
                          return (
                            <MenuItem key={id} value={id}>
                              {username}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{errors.status?.message}</FormHelperText>
                    </FormControl>
                  )}
                </>
              )}
              <FormControl fullWidth error={!!errors.priority}>
                <InputLabel id="demo-simple-select-label">{t('attributes.priority')}</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="priority"
                  defaultValue={row?.priority ?? ''}
                  label={t('attributes.priority')}
                  {...register('priority')}
                >
                  <MenuItem key={1} value={'HIGH'}>
                    {t('priority.HIGH')}
                  </MenuItem>
                  <MenuItem key={2} value={'MEDIUM'}>
                    {t('priority.MEDIUM')}
                  </MenuItem>
                  <MenuItem key={3} value={'LOW'}>
                    {t('priority.LOW')}
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.priority?.message}</FormHelperText>
              </FormControl>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!isValid}>
                  {t('pages.ticketDetails.modifyTicket')}
                </LoadingButton>
                <LoadingButton
                  sx={{ mx: 2, bgcolor: '#b90e0a' }}
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleClose}
                >
                  {t('buttons.cancel')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
