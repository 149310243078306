import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  DialogTitle,
  DialogContent,
  Grid,
  Stack,
  Box,
  Typography,
  Card,
  IconButton,
  Dialog,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { convertLocalTime } from '../../utils/convertTime';
import { useToast } from '../../hooks/useToast';
import { useAuth } from '../../hooks/useAuth';
import Iconify from '../../components/iconify';
import ImageInput from '../../components/inputs/ImageInput';
import { GetUserById, updateUser } from '../../services/user.service';
import Label from '../../components/label/Label';
import { getDepartmentById } from '../../services/department.service';
import ChangePassword from '../../components/user/ChangePassword';
import { EMAIL_REGEX, PHONENUMBER_REGEX } from '../../utils/regex';
import convertToBase64 from '../../utils/convertBase64';
import compressImage from '../../utils/imageCompressor';

export default function ProfilePage() {
  const { t } = useTranslation();
  const maxSize = 1024 * 1024 * 2; // 2MB maximum file size
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];
  const [userData, setUserData] = useState({
    id: 0,
    familyName: '',
    givenName: '',
    appUserRole: '',
    phoneNumber: '',
    username: '',
    departmentId: 0,
    status: false,
  });
  const { id: currentUserId } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: userData.id,
      givenName: userData.givenName,
      familyName: userData.familyName,
      username: userData.username,
      phoneNumber: userData.phoneNumber,
      appUserRole: userData.appUserRole,
      departmentId: userData.departmentId,
      email: userData.email,
      avatar: userData.avatar,
    },
  });
  useMemo(() => {
    const defaultValues = {
      id: userData.id,
      givenName: userData.givenName,
      familyName: userData.familyName,
      username: userData.username,
      phoneNumber: userData.phoneNumber,
      appUserRole: userData.appUserRole,
      departmentId: userData.departmentId,
      email: userData.email,
      avatar: userData.avatar,
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  const [department, setDepartment] = useState('');
  const [StartDate, setStartDate] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { showToast, hideToast } = useToast();

  // fetch user data
  const getUserData = () => {
    GetUserById(currentUserId)
      .then((res) => setUserData(res))
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadUser'), severity: 'error', props: { hideToast } });
      });
  };

  // update user

  const modifyUser = async (newData) => {
    await updateUser({
      id: newData.id,
      givenName: newData.givenName,
      familyName: newData.familyName,
      username: newData.username,
      email: newData.email,
      departmentId: newData.departmentId,
      appUserRole: newData.appUserRole,
      phoneNumber: newData.phoneNumber,
      avatar: newData.avatar,
    });
    showToast({ message: t('successMessages.successUpdate'), severity: 'success', props: { hideToast } });
    getUserData();
  };

  // update avatar
  const changeAvatar = async () => {
    if (selectedFile) {
      const callbackFunc = (compressedDataURL) => {
        updateUser({
          id: userData.id,
          givenName: userData.givenName,
          familyName: userData.familyName,
          username: userData.username,
          email: userData.email,
          departmentId: userData.departmentId,
          appUserRole: userData.appUserRole,
          phoneNumber: userData.phoneNumber,
          avatar: compressedDataURL,
        })
          .then(() => {
            showToast({
              message: t('successMessages.successProfilePicUpdate'),
              severity: 'success',
              props: { hideToast },
            });
            getUserData();
            handleDialogueClose();
          })
          .catch(() => {
            showToast({
              message: t('errorMessages.errorChangingProofilePic'),
              severity: 'error',
              props: { hideToast },
            });
          });
      };
      if (selectedFile.type === 'image/gif') {
        convertToBase64(selectedFile).then(callbackFunc);
      } else {
        compressImage(selectedFile, callbackFunc);
      }
    }
  };
  // fetch department
  const getUserDepartment = () => {
    getDepartmentById(userData.departmentId)
      .then((res) => setDepartment(res.name))
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadUser'), severity: 'error', props: { hideToast } });
      });
  };
  const handleDialogueClose = () => {
    setDialogOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setSelectedFile(file);
      } else if (!allowedTypes.includes(file.type)) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImage'),
          severity: 'error',
          props: { hideToast },
        });
      } else if (file.size > maxSize) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImageSize'),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
  };
  const onSubmit = (data) => {
    modifyUser(data);
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userData.departmentId) getUserDepartment();
    
    if (userData.createdAt) {
      const formattedStartDate = format(convertLocalTime(new Date(userData.createdAt)), 'dd/MM/yyyy HH:mm:ss');
      setStartDate(formattedStartDate);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleDialogueClose} fullWidth maxWidth="sm">
        <DialogTitle id="modal--title" component="h2">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{t('pages.profilePage.modifyProfilePic')}</Typography>
            <IconButton onClick={() => handleDialogueClose()}>
              <Iconify
                sx={{
                  height: 40,
                  width: 40,
                  color: 'grey',
                  '&:hover': {
                    color: '#B72136',
                  },
                }}
                icon={'eva:close-square-fill'}
              />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <ImageInput handleChange={handleFileChange} selectedFile={selectedFile} existingFile={userData.avatar} />
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <LoadingButton fullWidth type="submit" variant="contained" onClick={changeAvatar}>
              {t('buttons.confirm')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <Grid container flexGrow={1}>
        <Grid item xs={8}>
          <Stack spacing={2}>
            <Box bgcolor="#F1F1F1" borderRadius={2} p={2}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Avatar src={userData.avatar} alt={userData.username} sx={{ height: '75px', width: '75px' }} />

                <Stack alignItems="flex-start" spacing={2}>
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <Typography variant="h6">{userData.username}</Typography>

                    <Typography>
                      {userData.status ? (
                        <Label color="success"> {t('userStatus.active')} </Label>
                      ) : (
                        <Label color="error"> {t('userStatus.inactive')}</Label>
                      )}
                    </Typography>
                  </Stack>
                  <LoadingButton variant="outlined" size="small" onClick={() => setDialogOpen(true)}>
                    {t('buttons.updateProfilePic')}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Box>
            <Box bgcolor="#F1F1F1" borderRadius={2} p={2}>
              <Box>
                <Stack spacing={1}>
                  <Stack sx={{ px: 2 }} direction="row">
                    <Iconify icon={'eva:edit-2-outline'} sx={{ mr: 1, mt: 0.5 }} />
                    <Typography variant="h6">{t('pages.profilePage.editInfoHere')}:</Typography>
                  </Stack>
                  <Card
                    sx={{
                      p: 3,
                      boxShadow: '0.5px 0.5px 2px',
                    }}
                  >
                    <form onSubmit={handleSubmit(onSubmit)} key={userData.id}>
                      <Stack mb={3} direction="row" spacing={3}>
                        <TextField
                          fullWidth
                          name="givenName"
                          label={t('attributes.firstName')}
                          defaultValue={userData.givenName}
                          error={!!errors.givenName}
                          helperText={errors.givenName?.message || ''}
                          {...register('givenName', {
                            required: t('formControl.enterName'),
                            minLength: {
                              value: 3,
                              message: t('formControl.minName'),
                            },
                          })}
                        />
                        <TextField
                          fullWidth
                          name="familyName"
                          label={t('attributes.lastName')}
                          autoComplete="family-name"
                          error={!!errors.familyName}
                          helperText={errors.familyName?.message || ''}
                          {...register('familyName', {
                            required: t('formControl.enterLastName'),
                            minLength: {
                              value: 3,
                              message: t('formControl.minLastName'),
                            },
                          })}
                        />
                      </Stack>
                      <Stack spacing={3}>
                        <TextField
                          name="username"
                          label={t('attributes.userName')}
                          autoComplete="username"
                          error={!!errors.username}
                          helperText={errors.username?.message || ''}
                          {...register('username', {
                            required: t('formControl.enterName'),
                            minLength: {
                              value: 3,
                              message: t('formControl.minName'),
                            },
                          })}
                        />
                        <TextField
                          name="phoneNumber"
                          type="number"
                          label={t('attributes.phoneNumber')}
                          autoComplete="tel-local"
                          error={!!errors.phoneNumber}
                          helperText={errors.phoneNumber?.message || ''}
                          {...register('phoneNumber', {
                            required: t('formControl.enterPhoneNumber'),
                            pattern: {
                              value: PHONENUMBER_REGEX,
                              message: t('formControl.invalidPhoneNumber'),
                            },
                          })}
                        />
                        <TextField
                          name="email"
                          label={t('pages.usersPage.form.emailAdress')}
                          autoComplete="email"
                          error={!!errors.email}
                          helperText={errors.email?.message || ''}
                          {...register('email', {
                            required: t('formControl.enterEmail'),
                            pattern: {
                              value: EMAIL_REGEX,
                              message: t('formControl.enterEmail'),
                            },
                          })}
                        />
                        <LoadingButton type="submit" variant="contained" disabled={!isValid}>
                          {t('buttons.update')}
                        </LoadingButton>
                      </Stack>
                    </form>
                  </Card>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={0.25} />

        <Grid item xs={3.75} bgcolor="#F1F1F1" borderRadius={2} p={2}>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Stack sx={{ px: 2 }} direction="row">
                <Iconify icon={'eva:question-mark-circle-outline'} sx={{ mr: 1, mt: 0.5 }} />
                <Typography variant="h6">{t('pages.profilePage.userInfo')}</Typography>
              </Stack>
              <Card
                sx={{
                  p: 3,
                  boxShadow: '0.5px 0.5px 2px',
                }}
              >
                <Stack spacing={1}>
                  <Typography>
                    <b>{t('pages.profilePage.role')}: </b>
                    {t(`roles.${userData.appUserRole}`)}
                  </Typography>
                  {department && (
                    <Typography>
                      <b>{t('attributes.department')} : </b>
                      {department}
                    </Typography>
                  )}
                  <Typography>
                    <b>{t('pages.profilePage.startedAt')}: </b>
                    {StartDate}
                  </Typography>
                </Stack>
              </Card>
            </Stack>
            <Stack mt={1} spacing={1}>
              <Stack sx={{ px: 2 }} direction="row">
                <Iconify icon={'eva:refresh-outline'} sx={{ mr: 1, mt: 0.5 }} />
                <Typography variant="h6">{t('pages.profilePage.changePassword')}:</Typography>
              </Stack>
              <Card
                sx={{
                  p: 3,
                  boxShadow: '0.5px 0.5px 2px',
                }}
              >
                <ChangePassword userId={userData.id} />
              </Card>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
