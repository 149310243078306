import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuItem, Stack, TextField, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import countries from '../../locale/phoneCode';
import { EMAIL_REGEX, PHONENUMBER_REGEX } from '../../utils/regex';

AddUserForm.propTypes = {
  addUser: PropTypes.func,
  hideModal: PropTypes.func,
  currentRole: PropTypes.string,
  departmentsData: PropTypes.array,
};

export default function AddUserForm({ addUser, hideModal, currentRole, departmentsData }) {
  const [selectedCountry, setSelectedCountry] = useState('');
  const { t, i18n } = useTranslation();
  useEffect(() => {
    countries.sort((a, b) => a[i18n.language].localeCompare(b[i18n.language]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      givenName: '',
      familyName: '',
      phoneNumber: '',
      departmentId: 0,
      appUserRole: '',
      email: '',
    },
  });

  const selectedRole = watch('appUserRole');

  const handleChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleClick = () => {};
  const onSubmit = (data) => {
    data.phoneNumber = `(${selectedCountry}) ${data.phoneNumber}`;
    addUser({ ...data, status: 'active', isVerified: true });
    hideModal();
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <br />
        <TextField
          name="name"
          id="user-name-input"
          label={t('attributes.name')}
          autoComplete="given-name"
          error={!!errors.givenName}
          helperText={errors.givenName?.message || ''}
          {...register('givenName', {
            required: t('formControl.enterName'),
            minLength: {
              value: 3,
              message: t('formControl.minName'),
            },
          })}
        />

        <TextField
          name="lastName"
          id="user-lastName-input"
          label={t('attributes.lastName')}
          autoComplete="family-name"
          error={!!errors.familyName}
          helperText={errors.familyName?.message || ''}
          {...register('familyName', {
            required: t('formControl.enterLastName'),
            minLength: {
              value: 3,
              message: t('formControl.minLastName'),
            },
          })}
        />
        <Stack direction="row" spacing={1}>
          <FormControl sx={{ width: '20%' }}>
            <InputLabel id="country-code-label">{t('attributes.country')}</InputLabel>
            <Select
              labelId="country-code-label"
              label={t('attributes.country')}
              id="country-code"
              value={selectedCountry}
              onChange={handleChangeCountry}
              renderValue={(countryCode) => `(${countryCode})`}
            >
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.code}>
                  {`${country[i18n.language]} (${country.code})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            sx={{ width: '80%' }}
            fullWidth
            name="phoneNumber"
            id="user-phone-input"
            type="number"
            label={t('attributes.phoneNumber')}
            autoComplete="tel-national"
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message || ''}
            {...register('phoneNumber', {
              required: t('formControl.enterPhoneNumber'),
              pattern: {
                value: PHONENUMBER_REGEX,
                message: t('formControl.invalidPhoneNumber'),
              },
            })}
          />
        </Stack>
        {currentRole === 'ROLE_ADMIN' && (
          <FormControl fullWidth error={!!errors.appUserRole}>
            <InputLabel id="demo-simple-select-label">{t('pages.usersPage.form.role')}</InputLabel>

            <Select
              name="appUserRole"
              labelId="demo-simple-select-label"
              id="user-role-select"
              label={t('pages.usersPage.form.role')}
              defaultValue={''}
              {...register('appUserRole', {
                required: t('formControl.selectRole'),
              })}
            >
              <MenuItem value={'ROLE_CUSTOMER'}>{t('roles.ROLE_CUSTOMER')}</MenuItem>
              <MenuItem value={'ROLE_AGENT'}>{t('roles.ROLE_AGENT')}</MenuItem>
              <MenuItem value={'ROLE_ADMIN'}>{t('roles.ROLE_ADMIN')}</MenuItem>
              <MenuItem value={'ROLE_DEPARTMENTMANAGER'}>{t('roles.ROLE_DEPARTMENTMANAGER')}</MenuItem>
            </Select>
            <FormHelperText>{errors.appUserRole?.message}</FormHelperText>
          </FormControl>
        )}

        {selectedRole && selectedRole !== 'ROLE_CUSTOMER' && selectedRole !== 'ROLE_ADMIN' && (
          <FormControl fullWidth error={!!errors.departmentId} data-testid="user-department-select-control">
            <InputLabel id="demo-simple-select-label">{t('attributes.department')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="user-department-select"
              inputProps={{
                'data-testid': 'user-department-select',
              }}
              label={t('attributes.department')}
              defaultValue={''}
              {...register('departmentId', {
                required: t('formControl.selectDepartment'),
              })}
            >
              {departmentsData.map((department) => {
                const { id, name } = department;
                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>

            <FormHelperText>{errors.departmentId?.message}</FormHelperText>
          </FormControl>
        )}

        <TextField
          name="email"
          id="user-email-input"
          label={t('pages.usersPage.form.emailAdress')}
          autoComplete="email"
          error={!!errors.email}
          helperText={errors.email?.message || ''}
          {...register('email', {
            required: t('formControl.enterEmail'),
            pattern: {
              value: EMAIL_REGEX,
              message: t('formControl.invalidEmail'),
            },
          })}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <LoadingButton
          fullWidth
          id="new-user-submit-btn"
          size="large"
          type="submit"
          variant="contained"
          onClick={handleClick}
          disabled={!isValid}
        >
          {t('buttons.confirm')}
        </LoadingButton>
      </Stack>
    </form>
  );
}
