// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader, Avatar, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
// utils
import { convertLocalTime } from '../../../utils/convertTime';
import { fToNow } from '../../../utils/formatTime';
import stringToColor from '../../../utils/stringToColor';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  onViewAllClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${(name.split(' ')[1] || '')[0] || ''}`,
  };
}

export default function AppNewsUpdate({ title, subheader, list, onViewAllClick, isLoading, ...other }) {
  const { t } = useTranslation();
  return (
    <Card {...other} sx={{ height: '100%' }}>
      <CardHeader title={title} subheader={subheader} />
      <Stack height="90%" justifyContent="space-between">
        <Scrollbar>
          <Stack spacing={1} sx={{ p: 3, pr: 0 }}>
            {isLoading ? (
              [...Array(3)].map((_, index) => (
                <Stack direction="row" alignItems="center" spacing={2} key={index}>
                  <Skeleton variant="circular" width="45px" height="45px" />
                  <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                    <Skeleton variant="text" width="100px" />
                    <Stack direction="row" spacing={1}>
                      <Skeleton variant="text" width="70px" />
                      <Skeleton variant="text" width="90px" />
                    </Stack>
                    <Skeleton variant="text" width="200px" />
                  </Box>
                  <Stack sx={{ pr: 3, flexShrink: 0, color: 'text.secondary', alignItems: 'end' }}>
                    <Skeleton variant="text" width="100px" />
                    <Skeleton variant="text" width="75px" />
                  </Stack>
                </Stack>
              ))
            ) : list.length === 0 ? (
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" sx={{ color: (theme) => theme.palette.grey[500] }} paragraph>
                  {t('filters.notFound')}
                </Typography>

                <Typography variant="body2" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  {t('dashboard.noUpdates')}
                </Typography>
              </Box>
            ) : (
              list.map((news) => <NewsItem key={news.id} news={news} />)
            )}
          </Stack>
        </Scrollbar>
        <Divider />
        <Box sx={{ p: 2, textAlign: 'right' }}>
          <Button
            size="small"
            color="inherit"
            onClick={onViewAllClick}
            endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
          >
            {t('buttons.viewAll')}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.number,
    avatar: PropTypes.string,
    createdBy: PropTypes.string,
    department: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const { id, createdBy, avatar, department, title, description, postedAt } = news;
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar src={avatar} {...stringAvatar(createdBy)} />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link href={`/tickets/${id}`} color="inherit" variant="subtitle2" underline="hover" noWrap>
          <b>{title}</b>
        </Link>
        <Typography variant="body2" color="text.secondary" noWrap>
          <b>{t('dashboard.openedBy')}</b> {createdBy}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {description}
        </Typography>
      </Box>

      <Stack sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        <Typography textAlign="right" variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {department}
        </Typography>
        <Typography textAlign="right" variant="caption">
          {fToNow(Date.parse(convertLocalTime(postedAt)))}
        </Typography>
      </Stack>
    </Stack>
  );
}
