import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
// @mui
import { useState } from 'react';
import { Container, Stack, Typography, Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FormProvider } from '../../hooks/useForm';
import FormBody from '../../components/Form/formComposer/FormBody';
import FormPreview from '../../components/Form/FormPreview';
// ----------------------------------------------------------------------

export default function FormPage() {
  const [value, setValue] = useState('0');
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title> Form Page </title>
      </Helmet>
      <Container sx={{ overflowY: 'auto', height: '86%', position: 'fixed' }}>
        <FormProvider>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label={t('pages.formPage.formComposer')} value="0" />
                <Tab label={t('pages.formPage.previewForm')} value="1" />
              </TabList>
            </Box>
            <TabPanel value="0">
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">{t('buttons.newForm')}</Typography>
              </Stack>
              <FormBody />
            </TabPanel>
            <TabPanel value="1">
              <FormPreview disabled />
            </TabPanel>
          </TabContext>
        </FormProvider>
      </Container>
    </>
  );
}
