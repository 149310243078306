import PropTypes from 'prop-types';
import { Grid, Pagination, Stack } from '@mui/material';
import StatusCard from './StatusCard';

StatusGrid.propTypes = {
  statusData: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleDetailsClick: PropTypes.func,
  deleteStatus: PropTypes.func,
};
export default function StatusGrid({
  statusData,
  itemsPerPage,
  page,
  handleChangePage,
  handleDetailsClick,
  deleteStatus,
}) {
  const totalPages = Math.ceil(statusData.length / itemsPerPage);
  return (
    <>
      <Grid container columns={15} spacing={3}>
        {statusData.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map((status, idx) => (
          <Grid item key={idx} xs={12} sm={6} md={3}>
            <StatusCard status={status} handleDetailsClick={handleDetailsClick} deleteStatus={deleteStatus} />
          </Grid>
        ))}
      </Grid>
      {statusData.length > 0 && (
        <Stack alignItems="center" sx={{ mt: 5 }}>
          <Pagination
            component="div"
            color="primary"
            count={totalPages}
            size="large"
            page={page + 1}
            onChange={handleChangePage}
          />
        </Stack>
      )}
    </>
  );
}
