import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @mui
import { IconButton, Popover, MenuItem, Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useToast } from '../../../hooks/useToast';
import ModifyDepartment from '../../../components/departments/ModifyDepartment';
import DepartmentDetails from '../../../components/departments/DepartmentDetails';
import Iconify from '../../../components/iconify/Iconify';
// services
import { getTicketsByDepartment } from '../../../services/ticket.service';
import { getUsersByDepartment } from '../../../services/user.service';
import { useConfirmModal } from '../../../hooks/useModal';

// ----------------------------------------------------------------------

const StyledDepartmenttImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

DepartmentCard.propTypes = {
  department: PropTypes.object,
  handleDelete: PropTypes.func,
  editDepartment: PropTypes.func,
};

export default function DepartmentCard({ department, handleDelete, editDepartment }) {
  const { name, image, description } = department;
  const { showToast, hideToast } = useToast;
  const [open, setOpen] = useState(null);
  const [openModifyDepartment, setOpenModifyDepartment] = useState(false);
  const [openDepartmentDetails, setOpenDepartmentDetails] = useState(false);
  const [ticketsData, setTicketsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const { t } = useTranslation();

  // fetch tickets by department
  const fetchTickets = (departmentId) => {
    getTicketsByDepartment(departmentId)
      .then((res) => {
        setTicketsData(res);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDepTickets'), severity: 'error', props: { hideToast } });
      });
  };

  // fetch agents by department
  const fetchUsers = (departmentId) => {
    getUsersByDepartment(departmentId)
      .then((res) => {
        setUsersData(res.filter((user) => user.appUserRole === 'ROLE_AGENT'));
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDepAgents'), severity: 'error', props: { hideToast } });
      });
  };
  const handleOpenMenu = (e) => {
    setOpen(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(false);
  };
  const handleDeleteClick = async () => {
    showConfirmModal();
  };

  const { showConfirmModal } = useConfirmModal(
    t('pages.departmentPage.details.confirmDelete'),
    `${t('pages.departmentPage.details.areYouSure')} ${department.name}`,
    async () => handleDelete(department.id)
  );

  useEffect(() => {
    fetchTickets(department.id);
    fetchUsers(department.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModifyDepartment
        key={`dep-${department.id}`}
        open={openModifyDepartment}
        setOpen={setOpenModifyDepartment}
        department={department}
        editDepartment={editDepartment}
      />
      <DepartmentDetails
        open={openDepartmentDetails}
        setOpen={setOpenDepartmentDetails}
        department={department}
        tickets={ticketsData.length}
        users={usersData.length}
      />
      <Card sx={{ cursor: 'pointer' }} onClick={() => setOpenDepartmentDetails(true)}>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          <StyledDepartmenttImg alt={name} src={image || '/assets/images/departments/defaultDepartment.png'} />
        </Box>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Link color="inherit" underline="hover">
              <Typography variant="h6" noWrap>
                {name}
              </Typography>
            </Link>
            <IconButton
              size="large"
              color="inherit"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenMenu(e, department);
              }}
            >
              <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>
          </Stack>
          <Typography variant="body1" noWrap>
            {description}
          </Typography>
        </Stack>
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenDepartmentDetails(true);
            handleCloseMenu();
          }}
        >
          <Iconify icon={'eva:plus-circle-outline'} sx={{ mr: 1 }} />
          {t('popover.moreDetails')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpenModifyDepartment(true);
            handleCloseMenu();
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('popover.edit')}
        </MenuItem>

        <MenuItem onClick={handleDeleteClick}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('popover.delete')}
        </MenuItem>
      </Popover>
    </>
  );
}
