import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Stack, Box, Container, Typography, Alert, LinearProgress } from '@mui/material';
import { useToast } from '../../hooks/useToast';
import { uploadFile } from '../../services/ticket.service';
import Iconify from '../iconify/Iconify';
import { formatTimeStamp } from '../../utils/formatTime';

FileInput.propTypes = {
  hideModal: PropTypes.func,
  ticketId: PropTypes.number,
  onUpload: PropTypes.func,
};

export default function FileInput({ hideModal, onUpload, ticketId }) {
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const { showToast, hideToast } = useToast();
  const [valid, setValid] = useState(true);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dropzoneEnabled, setDropzoneEnabled] = useState(true);
  const maxSize = 1024 * 1024 * 100; // 100MB maximum file size
  const { t } = useTranslation();

  const onDrop = (acceptedFiles) => {
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/webp',
      'image/svg+xml',
      'application/pdf', // PDF files
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx files
      'application/vnd.ms-excel', // xls files
      'text/csv', // CSV files
      'text/plain', // Text files
      'application/msword', // Word files (doc)
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word files (docx)
      'application/vnd.ms-powerpoint', // PowerPoint files (ppt)
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint files (pptx)
      'application/json',
      'application/gzip',
      'application/x-7z-compressed',
      'application/zip',
      'application/x-rar-compressed',
      'application/vnd.rar',
    ];

    const filteredFiles = acceptedFiles.filter((file) => (allowedTypes.includes(file.type) || file.path.split('.').pop() === 'cnv') && file.size <= maxSize);
    const unsupportedFiles = acceptedFiles.filter((file) => !(allowedTypes.includes(file.type) || file.path.split('.').pop() === 'cnv') && file.size <= maxSize);

    if (!unsupportedFiles || unsupportedFiles.length === 0) {
      // Handle the dropped files here (e.g., upload to a server, display them, etc.)
      setValid(true);
      setFiles(filteredFiles);
      const filePreviews = filteredFiles.map((file) => {
        let url = '';
        if (file.type.startsWith('image/')) {
          url = URL.createObjectURL(file);
        } else {
          url = '/assets/images/files/fileDefault.png';
        }
        return (
          <Box key={file.name} p={2} border={1} borderColor="grey" borderRadius={3} width={200}>
            <Stack spacing={2}>
              <img src={url} alt={file.name} style={{ marginRight: 'auto', marginLeft: 'auto', maxHeight: 100 }} />
              <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} variant="caption">
                {file.name}
              </Typography>
            </Stack>
          </Box>
        );
      });
      setFilePreviews(filePreviews);
    } else {
      const unsupportedNames = unsupportedFiles?.map((item) => item.name);
      setInvalidFiles(unsupportedNames);
      setValid(false);
    }
  };
  const uploadFiles = async () => {
    files.forEach((file) => {
      setUploadProgress(0);
      const timeStamp = formatTimeStamp(Date.now());
      const newFileName = `${file.name.replace(/\.[^.]+$/, '')}_${timeStamp}.${file.name.match(/\.([^.]+)$/)[1]}`;
      const updatedFile = new File([file], newFileName, { type: file.type });
      const formData = new FormData();
      formData.append('files', updatedFile);
      uploadFile(formData, ticketId, (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setUploadProgress(progress);
      })
        .then(() => {
          onUpload(newFileName);
        })
        .catch(() => {
          showToast({ message: t('errorMessages.couldntUploadFile'), severity: 'error', props: { hideToast } });
        });
    });
    setDropzoneEnabled(false);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop, disabled: !dropzoneEnabled });
  return (
    <>
      <Stack>
        {uploadProgress === 100 && <Typography> Upload Successful</Typography>}
        {uploadProgress > 0 && (
          <Box>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>

            <Typography> {uploadProgress} %</Typography>
          </Box>
        )}
      </Stack>
      {!valid && (
        <div title={JSON.stringify(invalidFiles)}>
          <Alert severity="error">
            {t('inputs.file.cannotRead')}
            <br /> {invalidFiles[0]}, {invalidFiles[1]}... <br />
            {t('inputs.file.verifyExtensionAndSize')}
          </Alert>
        </div>
      )}
      <Container
        sx={{
          cursor: 'pointer',
          overflow: 'auto',
          p: 3,
          mt: 3,
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          borderRadius: 3,
          border: 1.5,
          borderColor: 'grey',
          mb: 3,
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '3px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            },
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        {filePreviews.length > 0 ? (
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="h6">{t('inputs.file.filesPreview')} :</Typography>
            <Stack direction="row" spacing={1.5}>
              {filePreviews}
            </Stack>
          </Stack>
        ) : (
          <>
            <Stack alignItems="center" spacing={2} justifyContent="center">
              <Iconify icon="material-symbols:upload" height={60} width={60} />
              <Typography variant="h6"> {t('inputs.file.dragHere')}</Typography>
              <Button variant="outlined" size="medium">
                {t('buttons.chooseFile')}
              </Button>
            </Stack>
          </>
        )}
      </Container>
      {filePreviews.length > 0 && uploadProgress === 0 && (
        <Stack justifyContent="center" direction="row" spacing={2}>
          <Button variant="outlined" size="medium" onClick={uploadFiles}>
            {t('buttons.upload')}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            onClick={() => {
              setFiles([]);
              setFilePreviews([]);
            }}
          >
            {t('buttons.reset')}
          </Button>
        </Stack>
      )}
      {filePreviews.length > 0 && uploadProgress > 0 && (
        <Button fullWidth variant="outlined" size="medium" onClick={hideModal}>
          Done
        </Button>
      )}
    </>
  );
}
