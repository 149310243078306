import React, { useEffect, useState } from 'react';
import TicketForm from '../../components/ticket/TicketForm';
import { fetchDepartments } from '../../services/department.service';

export default function CreateTicketPage() {
  const [departments, setDepartments] = useState();
  const fetchData = async () => {
    const data = await fetchDepartments();
    setDepartments(data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return <TicketForm departments={departments} />;
}
