import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import UpdateMessage from './UpdateMessage';
import MediaMessage from './MediaMessage';
import TextMessage from './TextMessage';

Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  messageType: PropTypes.string,
  userId: PropTypes.number,
  time: PropTypes.string,
  fetchUser: PropTypes.func,
  statusesList: PropTypes.array,
};
export default function Message({ message, messageType, userId, time, fetchUser, statusesList }) {
  const [user, setUser] = useState({
    id: '',
    username: '',
    appUserRole: '',
  });
  const { id: currentUserId } = useAuth();

  useEffect(() => {
    fetchUser(userId, setUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMine = currentUserId === userId;

  return (
    <Stack spacing={2} p={1}>
      {messageType === 'UPDATE' && (
        <UpdateMessage message={message} time={time} user={user} statusesList={statusesList} />
      )}
      {messageType === 'FILE' && <MediaMessage message={message} time={time} user={user} isMine={isMine} />}
      {messageType === 'MESSAGE' && <TextMessage message={message} time={time} isMine={isMine} user={user} />}
    </Stack>
  );
}
