import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import TextInput from '../../../FormInputs/TextInput';
import SelectInput from '../../../FormInputs/SelectInput';
import DateTimePickerInput from '../../../FormInputs/DateTimePickerInput';
import FileInput from '../../../FormInputs/FileInput';
import TextAreaInput from '../../../FormInputs/TextAreaInput';
import OptionSelector from '../OptionsSelector';
import { useFormContext } from '../../../../hooks/useForm';

const InputPlaceHolder = ({ selectedInput, question, sectionId, selectedType }) => {
  const [type, setType] = useState(question.type ?? selectedInput);

  useEffect(() => {
    setType(selectedInput);
  }, [selectedInput]);

  if (type === 'TextInput') return <TextInput label="Text input" disabled />;
  if (type === 'TextAreaInput') return <TextAreaInput label="Text input" disabled />;
  if (type === 'DateTimePickerInput') return <DateTimePickerInput disabled />;
  if (type === 'FileInput') return <FileInput disabled />;

  return <OptionSelector question={question} sectionId={sectionId} selectedInput={type} selectedType={selectedType} />;
};
InputPlaceHolder.propTypes = {
  selectedInput: PropTypes.string,
  question: PropTypes.object,
  sectionId: PropTypes.string,
  selectedType: PropTypes.string,
};

export default function Question({ sectionId, question, selectedType }) {
  const { modifyQuestion } = useFormContext();
  const { t } = useTranslation();
  const [updatedQuestion, setUpdatedQuestion] = useState(question);
  const [selectedInput, setSelectedInput] = useState(question.type ?? 'TextInput');
  const Inputs = [
    { text: t('formInputs.text'), value: 'TextInput' },
    { text: t('formInputs.textArea'), value: 'TextAreaInput' },
    { text: t('formInputs.select'), value: 'SelectInput' },
    { text: t('formInputs.checkbox'), value: 'CheckboxInput' },
    { text: t('formInputs.multipleChoice'), value: 'RadioInput' },
    { text: t('formInputs.date'), value: 'DateTimePickerInput' },
    { text: t('formInputs.fileUpload'), value: 'FileInput' },
  ];

  const handleSelectInput = (selectedel) => {
    setSelectedInput(selectedel);
    modifyQuestion(question.id, { ...question, type: selectedel });
  };
  const handleQuestionChange = () => {
    modifyQuestion(question.id, { ...question, ...updatedQuestion });
  };

  return (
    <>
      <Grid container mt={2} mb={2}>
        <Grid item xs={6}>
          <TextInput
            name="Question label"
            label={t('pages.formPage.question')}
            variant="standard"
            size="medium"
            color="info"
            fullWidth
            defaultValue={question.text || ''}
            onChange={(e) => setUpdatedQuestion({ ...question, text: e.target.value })}
            onBlur={handleQuestionChange}
          />
        </Grid>
        <Grid item xs={5} sx={{ textAlign: 'end' }}>
          <SelectInput
            labelValue={t('pages.formPage.type')}
            name="Type selector"
            defaultValue={question.type || t('pages.formPage.textInput')}
            options={Inputs}
            setSelectedInput={handleSelectInput}
          />
        </Grid>
      </Grid>
      <Grid mb={2}>
        <InputPlaceHolder
          selectedInput={selectedInput}
          question={question}
          sectionId={sectionId}
          selectedType={selectedType}
        />
      </Grid>
    </>
  );
}

Question.propTypes = {
  question: PropTypes.object,
  sectionId: PropTypes.string,
  selectedType: PropTypes.string,
};
