import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useState, useMemo } from 'react';
import { useToast } from '../../hooks/useToast';
import convertToBase64 from '../../utils/convertBase64';
import compressImage from '../../utils/imageCompressor';
import ImageInput from '../inputs/ImageInput';

ModifyDepartment.propTypes = {
  department: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  editDepartment: PropTypes.func,
};
export default function ModifyDepartment({ department, open, setOpen, editDepartment }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      id: department.id,
      name: department.name,
      description: department.description,
      image: department.image,
      createdBy: department.createdBy,
    },
  });
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();
  const [selectedFile, setSelectedFile] = useState(null);
  const maxSize = 1024 * 1024 * 2; // 2MB maximum file size
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setSelectedFile(file);
      } else if (!allowedTypes.includes(file.type)) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImage'),
          severity: 'error',
          props: { hideToast },
        });
      } else if (file.size > maxSize) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImageSize'),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
  };
  const handleClose = () => {
    reset();
    setSelectedFile(null);
    setOpen(false);
  };
  const onSubmit = async (data) => {
    const callBackFunc = (convertedFile) => {
      data.image = convertedFile;
      editDepartment(data);
      handleClose();
    };
    if (selectedFile) {
      if (selectedFile.type === 'image/gif') convertToBase64(selectedFile).then(callBackFunc);
      else compressImage(selectedFile, callBackFunc);
    } else {
      editDepartment(data);
      handleClose();
    }
  };
  useMemo(() => {
    const defaultValues = {
      id: department.id,
      name: department.name,
      description: department.description,
      image: department.image,
      createdBy: department.createdBy,
    };
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <DialogTitle id="modal--title" variant="h6" component="h2">
          {t('pages.departmentPage.form.modifyDepartment')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <br />
              <TextField
                name="name"
                label={t('attributes.name')}
                error={!!errors.name}
                helperText={errors.name?.message || ''}
                {...register('name', {
                  required: t('formControl.enterName'),
                  minLength: {
                    value: 4,
                    message: t('formControl.minName'),
                  },
                  maxLength: {
                    value: 50,
                    message: t('formControl.maxName'),
                  },
                })}
              />

              <TextField
                multiline
                minRows={4}
                name="description"
                label={t('attributes.description')}
                error={!!errors.description}
                helperText={errors.description?.message || ''}
                {...register('description', {})}
              />

              <ImageInput handleChange={handleFileChange} selectedFile={selectedFile} existingFile={department.image} />

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!isValid}>
                  {t('buttons.editDepartment')}
                </LoadingButton>
                <LoadingButton
                  sx={{ mx: 2, bgcolor: '#b90e0a' }}
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleClose}
                >
                  {t('buttons.cancel')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
