import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack } from '@mui/material';
// sections
import { DashboardToolbar } from '../../sections/@dashboard/toolbar';
import { AppWidgetSummary, AppCurrentSubject, BarChart } from '../../sections/@dashboard/Charts';
import DepartmentSelect from '../../sections/@dashboard/toolbar/DepartmentSelect';
// api's
import { fetchTicketsByDate } from '../../services/ticket.service';
import { getDepartmentById } from '../../services/department.service';
import { fetchStatuses } from '../../services/status.service';
// hooks
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const theme = useTheme();
  const { id: currentUserId, username, departmentId } = useAuth();
  const [ticketsData, setTicketData] = useState([]);
  const [depTicketsData, setDepTicketsData] = useState([]);
  const [ticketCount, setTicketsCount] = useState(0);
  const [ticketStatusCount, setTicketStatusCount] = useState({ open: 0, onhold: 0, resolved: 0, closed: 0 });
  const [ticketCategoryByStatus, setTicketCategoryByStatus] = useState([]);
  const [expiredTickets, setExpiredTickets] = useState(0);
  const [expiringTickets, setExpiringTickets] = useState(0);
  const [departmentData, setDepartmentData] = useState({});
  const [statusesList, setStatusesList] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  // const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);

  const { showToast, hideToast } = useToast();
  const { t } = useTranslation();
  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const getStatuses = (depId) => {
    if (depId === 0) {
      fetchStatuses().then((res) => {
        setStatusesList(res);
      });
    } else {
      fetchStatuses().then((res) => {
        setStatusesList(res.filter((status) => status.departmentId === depId || status.departmentId === 0));
      });
    }
  };
  const getTicketsByDate = async ({ startDate, endDate, attributeName }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    const tickets = await fetchTicketsByDate({ startDate: start, endDate: end, attributeName });
    const updatedTickets = tickets.map((ticket) => {
      const statusObject = statusesList.find((status) => status.statusId === ticket.status_id);
      return { ...ticket, statusObject };
    });
    setTicketData(updatedTickets.filter((ticket) => ticket.assignedTo === currentUserId));
    setDepTicketsData(updatedTickets.filter((ticket) => ticket.departmentId === departmentId));
  };
  const fetchDepartment = () => {
    getDepartmentById(departmentId)
      .then((res) => {
        setDepartmentData(res);
      })
      .catch(() => showToast({ message: "couldn't load department data", severoty: 'error', props: { hideToast } }));
  };
  useEffect(() => {
    setTicketStatusCount({
      open: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'OPEN').length,
      onhold: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'ONHOLD').length,
      resolved: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED').length,
      closed: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'CLOSED').length,
    });
    setTicketsCount(ticketsData.length);
    setExpiredTickets(
      ticketsData?.filter(
        (ticket) =>
          new Date(ticket.estimatedEndTime).getTime() < new Date().getTime() &&
          ticket.statusObject?.systemMappedStatus !== ('RESOLVED' || 'CLOSED')
      ).length
    );
    setExpiringTickets(
      ticketsData?.filter(
        (ticket) =>
          ticket.statusObject?.systemMappedStatus !== 'RESOLVED' &&
          ticket.statusObject?.systemMappedStatus !== 'CLOSED' &&
          new Date(ticket.estimatedEndTime).getTime() > new Date().getTime() &&
          new Date(ticket.estimatedEndTime).getTime() - new Date().getTime() <=
            0.1 * (new Date(ticket.estimatedEndTime).getTime() - new Date(ticket.estimatedStartTime).getTime())
      ).length
    );
  }, [ticketsData]);
  useEffect(() => {
    getTicketsByDate({
      startDate: start,
      endDate: end,
      attributeName: 'created',
    });
    fetchDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList]);
  useEffect(() => {
    getStatuses(departmentId);
  }, [departmentId]);

  const ticketsByAgent = useMemo(() => {
    const agentTickets = depTicketsData.filter((ticket) => ticket.agentName !== null);
    const ticketsByAgent = agentTickets.reduce((accumulator, ticket) => {
      const { agentName: agent, assignedTo: agentId } = ticket;
      if (!accumulator[agentId]) {
        accumulator[agentId] = { agent, agentId, tickets: [] };
      }
      accumulator[agentId].tickets.push(ticket);

      return accumulator;
    }, {});
    const result = Object.values(ticketsByAgent);
    return result;
  }, [depTicketsData]);
  useMemo(() => {
    setTicketCategoryByStatus([]);
    const ticketsByCategory = ticketsData.reduce((accumulator, ticket) => {
      const { category } = ticket;
      if (!accumulator[category]) {
        accumulator[category] = { category, tickets: [] };
      }
      accumulator[category].tickets.push(ticket);
      const ticketCategoryByStat = Object.keys(accumulator).map((key) => ({
        name: key,
        data: statusesList?.map(
          (status) =>
            accumulator[key]?.tickets?.filter((ticket) => ticket.statusObject?.statusId === status.statusId).length || 0
        ),
      }));
      setTicketCategoryByStatus(ticketCategoryByStat);
      return accumulator;
    }, {});
    const result = Object.values(ticketsByCategory);

    return result;
  }, [ticketsData, statusesList]);
  useEffect(() => {
    setStatusNames(statusesList.map((status) => (status.statusId < 6 ? t(`status.${status.name}`) : status.name)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList]);
  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('dashboard.greeting')} {username || 'user'}!
        </Typography>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Grid item xs={10}>
            <DashboardToolbar getTicketsByDate={getTicketsByDate} start={start} end={end} />
          </Grid>
          <Grid item xs={2}>
            <DepartmentSelect options={[{ name: departmentData.name, id: departmentData.id }]} />
          </Grid>
        </Stack>
        <Grid container columns={15} spacing={3}>
          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.expiringTickets')}
              color="warning"
              total={expiringTickets || 0}
              icon={'uil:clock-ten'}
            />
          </Grid>

          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.expiredTickets')}
              total={expiredTickets || 0}
              color="error"
              icon={'uil:clock-two'}
            />
          </Grid>

          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.onHoldTickets')}
              total={ticketStatusCount.onhold}
              icon={'ph:ticket'}
            />
          </Grid>

          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.closureRate')}
              total={(ticketStatusCount.resolved / ticketCount) * 100 || 0}
              color="success"
              icon={'material-symbols:percent'}
            />
          </Grid>
          <Grid item xs={15} sm={7.5} md={3}>
            <AppWidgetSummary
              title={t('dashboard.openingRate')}
              total={((ticketStatusCount.onhold + ticketStatusCount.open) / ticketCount) * 100 || 0}
              color="info"
              icon={'material-symbols:percent'}
            />
          </Grid>

          <Grid item xs={15} md={7.5} lg={10}>
            <BarChart
              title={t('dashboard.bestPerAgents')}
              subheader=""
              chartData={ticketsByAgent.map((agent) => ({
                label: agent.agent,
                value:
                  agent.tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED').length || 0,
              }))}
            />
          </Grid>

          <Grid item xs={15} md={7.5} lg={5}>
            <AppCurrentSubject
              title={t('dashboard.mostReccuringSubjects')}
              chartLabels={statusNames}
              chartData={ticketCategoryByStatus}
              chartColors={[...Array(statusNames.length)].map(() => theme.palette.text.secondary)}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
