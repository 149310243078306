import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, Switch, FormControlLabel, IconButton, Tooltip, Button } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import Iconify from '../../../iconify';
import PopOverMenu from '../../../popovermenu/PopOverMenu';
import Question from './Question';
import { useFormContext } from '../../../../hooks/useForm';


QuestionCard.propTypes = {
  question: PropTypes.object,
  sectionId: PropTypes.string,
  index: PropTypes.number,
};

export default function QuestionCard({ question, sectionId, index }) {
  const { deleteQuestion: removeQuestion, addQuestion: handleAddQuestion, modifyQuestion } = useFormContext();
  const { t } = useTranslation();
  const options = [t('pages.formPage.goToSectionBaseOn')];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(question.redirectOnAnswer ? 0 : null);
  const [isRequired, setIsRequired] = useState(question.isRequired ?? isRequired);
  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleRequired = () => {
    setIsRequired(!isRequired);
    modifyQuestion(question.id, { ...question, isRequired: !isRequired });
  };

  const handleSelect = (value) => {
    setSelectedIndex(value);
    if (value === 0) {
      modifyQuestion(question.id, { ...question, redirectOnAnswer: true });

    }
  };
  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Card sx={{ minWidth: 275, mt: 3, mb: 3 }} raised>
          <CardContent>
        <Question question={question} sectionId={sectionId} selectedType={options[selectedIndex]} />
      </CardContent>
      <CardActions>
        <Button onClick={() => handleAddQuestion(sectionId)}>{t('pages.formPage.addQuestion')}</Button>
        <FormControlLabel
          label={t('pages.formPage.required')}
          control={
            <Switch
              name="toggleRequired"
              label={t('pages.formPage.required')}
              checked={isRequired}
              onClick={handleToggleRequired}
            />
          }
        />
        <Tooltip title={t('buttons.delete')}>
          <IconButton onClick={() => removeQuestion(sectionId, question.id)}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
        <IconButton size="large" color="inherit" onClick={handleMenuToggle}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
        <PopOverMenu
          options={options}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          selectedIndex={selectedIndex}
          setSelectedIndex={handleSelect}
        />
      </CardActions>
    </Card>
        </div>
      )}
    </Draggable>
  );
}
