import secureLocalStorage from 'react-secure-storage';
import jwtDecode from 'jwt-decode';

export const getAuthorizationHeader = () => {
  const token = secureLocalStorage.getItem('user');
  if (jwtDecode(token).exp * 1000 < Date.now()) {
    secureLocalStorage.clear();
    window.location.pathname = '/login';
    return false;
  }
  return { Authorization: `Bearer ${token}` };
};
