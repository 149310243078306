import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { SwipeableDrawer, Stack, Typography, Divider } from '@mui/material';

DepartmentDetails.propTypes = {
  setOpen: PropTypes.func,
  department: PropTypes.object,
  open: PropTypes.bool,
  tickets: PropTypes.number,
  users: PropTypes.number,
};
export default function DepartmentDetails({ setOpen, open, department, tickets, users }) {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <SwipeableDrawer anchor="right" onOpen={handleOpen} open={open} onClose={handleClose}>
        <img
          src={department.image || '/assets/images/departments/defaultDepartment.png'}
          alt="Selected"
          style={{ objectFit: 'cover', maxWidth: '100%', maxHeight: '200px' }}
        />
        <Stack width={'30vw'} sx={{ px: 4, py: 2 }} spacing={2}>
          <Typography variant="h3" alignSelf="center" sx={{ mb: 5 }}>
            {department.name}
          </Typography>
          <Stack>
            <Typography variant="h6">ID: </Typography>

            <Typography>{department.id}</Typography>
          </Stack>
          <Divider />
          <Stack>
            <Typography variant="h6">{t('attributes.description')}: </Typography>

            <Typography>{department.description}</Typography>
          </Stack>
          <Divider />
          <Stack>
            <Typography variant="h6">{t('pages.departmentPage.details.numAgents')}: </Typography>
            <Typography>{users}</Typography>
          </Stack>
          <Divider />
          <Stack>
            <Typography variant="h6">{t('pages.departmentPage.details.numTickets')}: </Typography>
            <Typography>{tickets}</Typography>
          </Stack>

          <Stack>
            <LoadingButton
              sx={{ bgcolor: '#b90e0a', mt: 5 }}
              fullWidth
              size="large"
              variant="contained"
              onClick={handleClose}
            >
              {t('buttons.close')}
            </LoadingButton>
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </>
  );
}
