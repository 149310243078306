import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack } from '@mui/material';
// sections
import { useEffect, useState, useMemo } from 'react';
import secureLocalStorage from 'react-secure-storage';
import DepartmentSelect from '../../sections/@dashboard/toolbar/DepartmentSelect';

import {
  AppNewsUpdate,
  LineChart,
  PieChart,
  AppWidgetSummary,
  AppCurrentSubject,
  BarChart,
} from '../../sections/@dashboard/Charts';

// utils
import { transformData } from '../../utils/filterData';
import { useAuth } from '../../hooks/useAuth';

// api's
import { fetchUsers, getUserCountByRole } from '../../services/user.service';
import { fetchStatuses } from '../../services/status.service';
import { fetchTicketsByDate } from '../../services/ticket.service';
import { fetchDepartments } from '../../services/department.service';
import { FloatingButton } from '../../components/floatingButton/FloatingButton';
import { DashboardToolbar } from '../../sections/@dashboard/toolbar';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { username } = useAuth();
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState({ customers: 0, agents: 0 });
  const [ticketCount, setTicketCount] = useState({ open: 0, unassigned: 0, onhold: 0, resolved: 0, closed: 0 });
  const [departmentList, setDepartmentList] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [ticketCountByDeps, setTicketCountByDeps] = useState([]);
  const [ticketCountByStat, setTicketCountByStat] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const getStatuses = (depId) => {
    if (depId === 0) {
      fetchStatuses().then((res) => setStatusesList(res));
    } else {
      fetchStatuses().then((res) =>
        setStatusesList(res.filter((status) => status.departmentId === depId || status.departmentId === 0))
      );
    }
  };
  const getUserCount = async () => {
    const userInfo = {};
    userInfo.customers = await getUserCountByRole('ROLE_CUSTOMER');
    userInfo.agents = await getUserCountByRole('ROLE_AGENT');
  };

  //  ### API Calls ###

  const getTicketsByDate = async ({ startDate, endDate, attributeName }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    const tickets = await fetchTicketsByDate({ startDate: start, endDate: end, attributeName });
    const updatedTickets = tickets.map((ticket) => {
      const { avatar } = users.find((user) => user.id === ticket.createdBy) || '';
      const statusObject = statusesList.find((status) => status.statusId === ticket.status_id);
      return { ...ticket, statusObject, avatar };
    });
    if (selectedDepartmentId === 0) {
      setTickets(updatedTickets);
    } else {
      setTickets(updatedTickets.filter((ticket) => ticket.departmentId === selectedDepartmentId));
    }
  };
  const getDepartMents = async () => {
    const departments = await fetchDepartments();
    setDepartmentList(departments);
  };
  const getUsers = async () => {
    const users = await fetchUsers();
    setUsers(users);
  };

  const activeUsersCount = async () => {
    const agentFilter =
      selectedDepartmentId === 0
        ? (user) => user.appUserRole === 'ROLE_AGENT' && user.status === true
        : (user) =>
            user.appUserRole === 'ROLE_AGENT' && user.status === true && user.departmentId === selectedDepartmentId;

    if (users) {
      const userInfo = {};
      userInfo.customers =
        users.filter((user) => user.appUserRole === 'ROLE_CUSTOMER').filter((user) => user.status === true).length || 0;
      userInfo.agents = users.filter(agentFilter).length || 0;
      setActiveUsers(userInfo);
    }
  };
  useEffect(() => {
    activeUsersCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, selectedDepartmentId]);

  useEffect(() => {
    setTicketCount({
      open: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'OPEN').length || 0,
      onhold: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'ONHOLD').length || 0,
      resolved: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED').length || 0,
      closed: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'CLOSED').length || 0,
      unassigned: tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'UNASSIGNED').length || 0,
    });
  }, [tickets]);

  // ##
  const ticketsByDep = useMemo(() => {
    setTicketCountByDeps([]);
    setTicketCountByStat([]);
    const ticketsByDepartment = tickets.reduce((accumulator, ticket) => {
      const { department, departmentId: deptId } = ticket;
      if (!accumulator[department]) {
        accumulator[department] = { department, deptId, tickets: [] };
      }
      accumulator[department].tickets.push(ticket);
      const ticketcountByDep = Object.keys(accumulator).map((key) => {
        const value = accumulator[key]?.tickets?.length;
        return { label: key, value };
      });
      const ticketcountByStatus = Object.keys(accumulator).map((key) => ({
        name: key,
        data: [
          accumulator[key]?.tickets?.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'OPEN').length || 0,
          accumulator[key]?.tickets?.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED')
            .length || 0,
          accumulator[key]?.tickets?.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'UNASSIGNED')
            .length || 0,
          accumulator[key]?.tickets?.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'CLOSED').length ||
            0,
          accumulator[key]?.tickets?.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'ONHOLD').length ||
            0,
        ],
      }));

      setTicketCountByDeps(ticketcountByDep);
      setTicketCountByStat(ticketcountByStatus);
      return accumulator;
    }, {});
    const result = Object.values(ticketsByDepartment);
    return result;
  }, [tickets]);
  const ResolvedTicketCategories = useMemo(() => {
    const groupedTickets = tickets
      .filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED')
      .reduce((acc, ticket) => {
        if (ticket.acceptedAt && ticket.resolvedAt) {
          const { category } = ticket;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push({
            ...ticket,
            resolutionTime: Math.abs(new Date(ticket.resolvedAt) - new Date(ticket.acceptedAt)) / 36e5,
          });
        }
        return acc;
      }, {});
    return groupedTickets;
  }, [tickets]);

  const averageResolutionTimes = useMemo(() => {
    const averageResolutionTimes = {};

    Object.keys(ResolvedTicketCategories).map((category) => {
      const ticketsInCategory = ResolvedTicketCategories[category];

      const totalResolutionTime = ticketsInCategory.reduce((sum, ticket) => sum + ticket.resolutionTime, 0);

      const averageTime = totalResolutionTime / ticketsInCategory.length;

      averageResolutionTimes[category] = averageTime;

      return averageResolutionTimes; // The return value is not used, map is just used for iteration
    });
    return averageResolutionTimes;
  }, [ResolvedTicketCategories]);

  const ticketsByAgent = useMemo(() => {
    const agentTickets = tickets.filter((ticket) => ticket.agentName !== null);
    const ticketsByAgent = agentTickets.reduce((accumulator, ticket) => {
      const { agentName: agent, assignedTo: agentId } = ticket;
      if (!accumulator[agentId]) {
        accumulator[agentId] = { agent, agentId, tickets: [] };
      }
      accumulator[agentId].tickets.push(ticket);

      return accumulator;
    }, {});
    const result = Object.values(ticketsByAgent);
    return result;
  }, [tickets]);

  const ticketStatusByDep = useMemo(() => transformData(ticketsByDep), [ticketsByDep]);
  useEffect(() => {
    getUserCount();
    getUsers();
    getDepartMents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getStatuses(selectedDepartmentId);
  }, [selectedDepartmentId]);

  useEffect(() => {
    setIsLoading(true);
    getTicketsByDate({
      startDate: start,
      endDate: end,
      attributeName: 'created',
    });
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentId, statusesList, users]);

  useEffect(() => {
    const opt = [{ id: 0, name: 'All Departments' }];
    departmentList.forEach((department) => {
      opt.push({ id: department.id, name: department.name });
    });
    setOptions(opt);
  }, [departmentList]);
  const ticketsWithAvatar = useMemo(
    () =>
      tickets
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 5)
        .map((ticket, index) => ({
          id: ticket.id,
          title: ticket.title,
          description: ticket.description,
          image: `/assets/images/covers/cover_${index + 1}.jpg`,
          postedAt: ticket.createdAt,
          avatar: ticket.avatar,
          createdBy: ticket.customerName,
          department: ticket.department,
        })),
    [tickets]
  );
  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>
      <Container maxWidth="xl">
        <FloatingButton />
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('dashboard.greeting')} {username || ''}!
        </Typography>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Grid item xs={10}>
            <DashboardToolbar getTicketsByDate={getTicketsByDate} start={start} end={end} setIsLoading={setIsLoading} />
          </Grid>
          <Grid item xs={2}>
            <DepartmentSelect options={options} setSelectedDepId={setSelectedDepartmentId} />
          </Grid>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3} height="100%">
              <Grid item xs={12} md={6} lg={6} height="100%">
                <AppNewsUpdate
                  isLoading={isLoading}
                  title={t('dashboard.latestUpdates')}
                  onViewAllClick={() => navigate('/dashboard/tickets')}
                  list={ticketsWithAvatar}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                      title={t('dashboard.activeUsers')}
                      total={activeUsers.customers}
                      icon={'raphael:customer'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                      title={t('dashboard.activeAgents')}
                      total={activeUsers.agents}
                      color="info"
                      icon={'mdi:face-agent'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                      title={t('dashboard.closureRate')}
                      total={(ticketCount.resolved / tickets.length) * 100 || 0}
                      color="warning"
                      icon={'material-symbols:percent'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                      title={t('dashboard.openingRate')}
                      total={
                        ((ticketCount.unassigned + ticketCount.onhold + ticketCount.open) / tickets.length) * 100 || 0
                      }
                      color="error"
                      icon={'material-symbols:percent'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {selectedDepartmentId === 0 ? (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <LineChart
                  title={t('dashboard.ticketStatusByDep')}
                  subheader=""
                  chartLabels={[
                    t('status.UNASSIGNED'),
                    t('status.RESOLVED'),
                    t('status.CLOSED'),
                    t('status.OPEN'),
                    t('status.ONHOLD'),
                  ]}
                  chartData={departmentList.map((dep, index) => ({
                    name: dep.name,
                    type: 'line',
                    fill: 'solid',
                    data: ticketStatusByDep[index] ?? [0, 0, 0, 0],
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PieChart
                  title={t('dashboard.totalTicketsByDep')}
                  chartData={ticketCountByDeps ?? []}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.error.main,
                    theme.palette.warning.main,
                    theme.palette.success.main,
                  ]}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} md={6} lg={4}>
            <PieChart
              title={t('dashboard.ticketByStatus')}
              chartData={[
                { label: t('status.UNASSIGNED'), value: ticketCount.unassigned ?? 0 },
                { label: t('status.RESOLVED'), value: ticketCount.resolved ?? 0 },
                { label: t('status.CLOSED'), value: ticketCount.closed ?? 0 },
                { label: t('status.OPEN'), value: ticketCount.open ?? 0 },
                { label: t('status.ONHOLD'), value: ticketCount.onhold ?? 0 },
              ]}
              chartColors={['#d32f2f', '#1976d2', '#9c27b0', '#4caf50', '#ed6c02']}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <BarChart
              title={selectedDepartmentId === 0 ? t('dashboard.bestPerManagers') : t('dashboard.bestPerAgents')}
              subheader=""
              chartData={ticketsByAgent.map((agent) => ({
                label: agent.agent,
                value:
                  agent.tickets.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED').length || 0,
              }))}
            />
          </Grid>

          {selectedDepartmentId === 0 ? (
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentSubject
                title={t('dashboard.statByDep')}
                chartLabels={[
                  t('status.OPEN'),
                  t('status.RESOLVED'),
                  t('status.UNASSIGNED'),
                  t('status.CLOSED'),
                  t('status.ONHOLD'),
                ]}
                chartData={ticketCountByStat || []}
                chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} md={selectedDepartmentId === 0 ? 6 : 12} lg={selectedDepartmentId === 0 ? 8 : 12}>
            <BarChart
              title={t('dashboard.averageResolutionTime')}
              subheader={t('pages.formPage.inHours')}
              chartData={Object.keys(averageResolutionTimes).map((key) => ({
                label: key,
                value: averageResolutionTimes[key],
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
