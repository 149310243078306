/* eslint-disable camelcase */
import axios from 'axios';
import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/tickets`;

// get tickets
export const fetchTickets = async () => {
  const result = await axios.get(url, { headers: getAuthorizationHeader() });
  return result.data;
};

// get ticket by ID

export const getTicketById = async (id) => {
  const result = await axios.get(`${url}/${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};

// get ticket by date

export const fetchTicketsByDate = async ({ startDate, endDate, attributeName }) => {
  const result = await axios.get(`${url}/by-date`, {
    headers: getAuthorizationHeader(),
    params: {
      startDate,
      endDate,
      attributeName,
    },
  });
  return result.data;
};

// get tickets by agent

export const fetchAgentTickets = async (agentId) => {
  const result = await axios.get(`${url}/by-agent/${agentId}`, { headers: getAuthorizationHeader() });
  return result.data;
};

// get tickets by department

export const fetchTicketsBydepartment = async (depId) => {
  const result = await axios.get(`${url}/by-department/${depId}`, { headers: getAuthorizationHeader() });
  return result.data;
};
// get tickets by customer
export const fetchCustomerTickets = async (customerId) => {
  const result = await axios.get(`${url}/by-customer/${customerId}`, { headers: getAuthorizationHeader() });
  return result.data;
};

export const assignTicketManually = async (ticketId, userId, statusId) => {
  // const status_id = 2;
  const res = await axios.post(
    `${url}/assign-manually/${ticketId}/${userId}/${statusId}`,
    {},
    {
      headers: getAuthorizationHeader(),
    }
  );
  return res.data;
};

// Update ticket

export const updateTicket = async ({ id, title, description, priority, status_id, assignedTo }) => {
  const result = await axios.put(
    `${url}/${id}`,
    { title, description, priority, status_id, assignedTo },

    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// reject ticket

export const rejectTicket = async (id, description) => {
  const result = await axios.post(`${url}/reject-ticket/${id}`, { description }, { headers: getAuthorizationHeader() });
  return result.data;
};

// accept ticket
export const acceptTicket = async (id) => {
  const result = await axios.post(`${url}/accept/${id}`, {}, { headers: getAuthorizationHeader() });
  return result.data;
};

// Create Ticket
export const createTicket = async (formData, onUploadProgress) => {
  const result = await axios.post(`${url}/add`, formData, {
    headers: { ...getAuthorizationHeader(), 'Content-Type': 'multipart/form-data' },
    maxBodyLength: Infinity,
    onUploadProgress,
  });
  return result.data;
};

// resolve ticket
export const resolveTicket = async (id) => {
  const result = await axios.post(`${url}/resolved/${id}`, {}, { headers: getAuthorizationHeader() });
  return result.data;
};
// Delete Ticket
export const deleteTicket = async (id) => {
  const result = await axios.delete(`${url}/${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};

// Download tickets as csv

export const downloadTickets = async () => {
  const result = await axios.get(`${API_URL}/csv/download/tickets`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};
// ## ADMIN Dashboard Services ##

export const getTicketCountByStatus = async () => {
  const result = await axios.get(`${url}/count-by-status`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const getTicketsByDepartment = async (id) => {
  const result = await axios.get(`${url}/by-department/${id}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

export const getTicketNumberByDepartment = async () => {
  const result = await axios.get(`${url}/count-by-department`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};

// upload files

export const uploadFile = async (formData, ticketId, onUploadProgress) => {
  const result = await axios.post(`${url}/upload/${ticketId}`, formData, {
    headers: { ...getAuthorizationHeader(), 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
  return result.data;
};

// get files
export const getFilesByTicket = async (ticketId) => {
  const result = await axios.get(`${url}/attachments/${ticketId}`, {
    headers: getAuthorizationHeader(),
  });
  return result.data;
};
