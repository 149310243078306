import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
// @mui
import {
  Card,
  Grid,
  Table,
  Popper,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { PropTypes } from 'prop-types';
// components
import ModifyUserModal from '../../components/user/ModifyUserModal';
import AddUserForm from '../../components/user/AddUserForm';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CSVInput from '../../components/inputs/CSVInput';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// utils
import { getComparator, applySortFilter } from '../../utils/filterData';
// hooks
import { useToast } from '../../hooks/useToast';
import { useModal, useConfirmModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
// api's
import authService from '../../services/auth.service';
import { fetchDepartments } from '../../services/department.service';
import {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  getUsersByDepartment,
  uploadUsers,
} from '../../services/user.service';
// ----------------------------------------------------------------------

// labels are translated in the UserListHead.js file these here are the keys the values are in the en.json file
const TABLE_HEAD_ADMIN = [
  { id: 'givenName', name: 'fullName', label: 'user.name', alignRight: false },
  { id: 'email', name: 'email', label: 'user.email', alignRight: false },
  { id: 'department', name: 'departmentName', label: 'user.department', alignRight: false },
  { id: 'appUserRole', name: 'appUserRole', label: 'user.role', alignRight: false },
  { id: 'phoneNumber', name: 'phoneNumber', label: 'user.phoneNumber', alignRight: false },
  { id: 'status', name: 'status', label: 'user.status', alignRight: false },
  { id: 'options', label: 'user.options' },
];

const TABLE_HEAD_DEPMANAGER = [
  { id: 'givenName', name: 'name', label: 'user.name', alignRight: false },
  { id: 'email', name: 'email', label: 'user.email', alignRight: false },
  { id: 'appUserRole', name: 'role', label: 'user.role', alignRight: false },
  { id: 'phoneNumber', name: 'phoneNumber', label: 'user.phoneNumber', alignRight: false },
  { id: 'status', name: 'status', label: 'user.status', alignRight: false },
  { id: 'options', label: 'user.options' },
];

// ----------------------------------------------------------------------
UserPage.propTypes = {
  title: PropTypes.string,
};

export default function UserPage({ title }) {
  const { t } = useTranslation();
  const { role: currentUserRole, departmentId, id: currentUserId } = useAuth();
  const role = currentUserRole.authority;
  const { showModal, hideModal } = useModal();
  const [usersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModifyUserModal, setModifyUserModal] = useState(false);
  const [page, setPage] = useState(0);
  const [departmentsData, setDepartmentData] = useState([]);
  const [filterPopover, setFilterPopover] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('fullName');
  const [filterBy, setFilterBy] = useState('fullName');
  const [searchBy, setSearchBy] = useState('byName');
  const [subFilterPopover, setSubFilterPopover] = useState({ department: null, status: null, role: null });
  const [showDelete, setShowDelete] = useState(true);
  const [order, setOrder] = useState('asc');
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [currentPageUsers, setCurrentPageUsers] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataInCSV, setDataInCSV] = useState('');
  const { showToast, hideToast } = useToast();

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedRow(row);
    if (role === 'ROLE_ADMIN' && row.id === currentUserId) setShowDelete(false);
    else setShowDelete(true);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleOpenFilter = (event) => {
    setFilterPopover(event.currentTarget);
  };

  const handleCloseFilter = () => {
    handleCloseSubFilter();
    setFilterPopover(null);
  };

  const handleOpenSubFilter = (event, category) => {
    if (category === 'department') {
      setSubFilterPopover({ department: event.currentTarget });
    } else if (category === 'status') {
      setSubFilterPopover({ status: event.currentTarget });
    } else if (category === 'role') {
      setSubFilterPopover({ role: event.currentTarget });
    }
  };

  const handleCloseSubFilter = () => {
    setSubFilterPopover({ status: null, priority: null, department: null });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Set selected items
  const handleClick = (event, email, id) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];
    let newSelectedId = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
      newSelectedId = newSelectedId.concat(selectedId, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedId = newSelectedId.concat(selectedId.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedId = newSelectedId.concat(selectedId.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newSelectedId = newSelectedId.concat(selectedId.slice(0, selectedIndex), selectedId.slice(selectedIndex + 1));
    }
    setSelectedId(newSelectedId);
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersData.length) : 0;

  // Fetch Clients
  const fetchData = () => {
    if (title === t('users')) {
      fetchUsers()
        .then((res) => {
          const usersWithFullname = res.map((user) => ({
            ...user,
            fullName: `${user.givenName} ${user.familyName}`,
            status: user.status ? 'true' : 'false',
          }));
          setUsersData(usersWithFullname);
        })
        .catch(() => {
          showToast({ message: t('errorMessages.couldntLoadUser'), severity: 'error', props: { hideToast } });
        });
    } else if (title === t('agents')) {
      getUsersByDepartment(departmentId)
        .then((res) => {
          const agentsWithFullname = res
            .filter((user) => user.appUserRole === 'ROLE_AGENT')
            .map((user) => ({
              ...user,
              fullName: `${user.givenName} ${user.familyName}`,
              status: user.status ? 'true' : 'false',
            }));
          setUsersData(agentsWithFullname);
        })
        .catch(() => {
          showToast({ message: t('errorMessages.couldntLoadDep'), severity: 'error', props: { hideToast } });
        });
    }
  };
  // fetch Departments
  const fetchDepartmentsData = () => {
    fetchDepartments()
      .then((res) => {
        setDepartmentData(res);
      })
      .catch(() => {
        showToast({ message: t('errorMessages.couldntLoadDep'), severity: 'error', props: { hideToast } });
      });
  };

  useEffect(() => {
    fetchDepartmentsData();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // get department name for each user

  let isNotFound = false;
  const filteredUsers = useMemo(() => {
    const filteredData = applySortFilter(usersData, getComparator(order, orderBy), filterName, filterBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isNotFound = !filteredData.length && !!filterName;

    return filteredData;
  }, [filterName, order, orderBy, filterBy, usersData]);
  useEffect(() => {
    const csvData = Papa.unparse(filteredUsers);
    setDataInCSV(csvData);
  }, [filteredUsers]);
  useEffect(() => {
    setCurrentPageUsers(filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [filteredUsers, page, rowsPerPage]);

  const handleSelectAllClick = () => {
    const newSelecteds = currentPageUsers.map((n) => n.email);
    const newSelectdsId = currentPageUsers.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedId.includes(id))) {
      // remove selected ids and email of this page if all are already selected
      setSelectedId(selectedId.filter((id) => !newSelectdsId.includes(id)));
      setSelected(selected.filter((email) => !newSelecteds.includes(email)));
      return;
    }
    // else select ids and emails of this page and add it to the selected list
    setSelected([...new Set([...selected, ...newSelecteds])]);
    setSelectedId([...new Set([...selectedId, ...newSelectdsId])]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const newSelectdsId = currentPageUsers.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedId.includes(id))) {
      // if all items of this page are selected then the checkbox state is set to indeterminate ( checked but with - icon )
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentPageUsers]);

  const handleRemoveSelected = () => {
    setSelected([]);
    setSelectedId([]);
  };

  // Add a new User
  const addNewUser = async (newUser) => {
    if (role === 'ROLE_ADMIN') {
      createUser({
        givenName: newUser.givenName,
        familyName: newUser.familyName,
        email: newUser.email,
        phoneNumber: newUser.phoneNumber,
        appUserRole: newUser.appUserRole,
        departmentId: newUser.departmentId,
      })
        .then(() => {
          showToast({ message: t('successMessages.successUserAdded'), severity: 'success', props: { hideToast } });
          fetchData();
        })
        .catch((error) =>
          showToast({
            message: error.response?.data || t('errorMessages.couldntCreateUser'),
            severity: 'error',
            props: { hideToast },
          })
        );
    }
  };

  // Modify user
  const modifyUser = async (newUserData) => {
    await updateUser({
      id: newUserData.id,
      givenName: newUserData.givenName,
      familyName: newUserData.familyName,
      email: newUserData.email,
      departmentId:
        newUserData.appUserRole === 'ROLE_CUSTOMER' || newUserData.appUserRole === 'ROLE_ADMIN'
          ? 0
          : newUserData.departmentId,

      appUserRole: newUserData.appUserRole,
      phoneNumber: newUserData.phoneNumber,
    });
    const newUsersList = usersData.map((user) =>
      user.id === newUserData.id
        ? {
            ...user,
            givenName: newUserData.givenName,
            familyName: newUserData.familyName,
            fullName: `${newUserData.givenName} ${newUserData.familyName}`,
            phoneNumber: newUserData.phoneNumber,
            departmentId:
              newUserData.appUserRole === 'ROLE_CUSTOMER' || newUserData.appUserRole === 'ROLE_ADMIN'
                ? 0
                : newUserData.departmentId,
            departmentName:
              newUserData.appUserRole === 'ROLE_CUSTOMER' || newUserData.appUserRole === 'ROLE_ADMIN'
                ? ''
                : newUserData.departmentName,
            email: newUserData.email,
            appUserRole: newUserData.appUserRole,
          }
        : user
    );
    showToast({ message: t('successMessages.successUserUpdate'), severity: 'success', props: { hideToast } });
    setUsersData(newUsersList);
  };
  // Delete client
  const RemoveUser = async (id) => {
    if (id !== currentUserId || currentUserRole.authority !== 'ROLE_ADMIN') {
      await deleteUser(id);
      showToast({ message: t('successMessages.successUserDelete'), severity: 'success', props: { hideToast } });
      const newUsersList = usersData.filter((user) => user.id !== id);
      setUsersData(newUsersList);
      handleCloseMenu();
      setSelected([]);
      setSelectedId([]);
    } else {
      showToast({ message: t('errorMessages.cannotDeleteUser'), severity: 'error', props: { hideToast } });
    }
  };
  useEffect(() => {
    const newSelectdsId = currentPageUsers.map((n) => n.id);
    if (newSelectdsId.every((id) => selectedId.includes(id))) {
      setIndeterminateCheckbox(true);
    } else setIndeterminateCheckbox(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const { showConfirmModal } = useConfirmModal(
    t('pages.usersPage.form.confirmDelete'),
    `${t('pages.usersPage.form.areYouSure')} ${selectedRow?.username}`,
    async () => RemoveUser(selectedRow.id)
  );
  const csvUpload = (formData) => {
    handleBackdropOpen();
    uploadUsers(formData)
      .then(() => {
        handleBackdropClose();
        showToast({ message: t('successMessages.successUsersUpload'), severity: 'success', props: { hideToast } });
        fetchData();
      })
      .catch(() => {
        handleBackdropClose();
        showToast({
          message: t('errorMessages.errorFileUpload'),
          severity: 'error',
          props: { hideToast },
        });
      });
  };
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };
  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };
  return (
    <>
      <Helmet>
        <title> {t(`nav.${title}`)} </title>
      </Helmet>
      {selectedRow ? (
        <ModifyUserModal
          open={openModifyUserModal}
          setOpen={setModifyUserModal}
          row={selectedRow}
          modifyUser={modifyUser}
          departmentsData={departmentsData}
          currentRole={authService.verifyUserRole()}
        />
      ) : null}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t(`nav.${title}`)}
          </Typography>
          <Grid>
            {role === 'ROLE_ADMIN' && (
              <Button
                variant="contained"
                id="add-user-btn"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() =>
                  showModal({
                    title: t('pages.usersPage.form.newUser'),
                    Component: AddUserForm,
                    props: {
                      addUser: addNewUser,
                      hideModal,
                      departmentsData,
                      currentRole: authService.verifyUserRole(),
                    },
                  })
                }
                sx={{ marginRight: '1vh' }}
              >
                {t('buttons.newUser')}
              </Button>
            )}
            <a href={`data:text/csv;charset=utf-8,${dataInCSV}`} download="users.csv">
              <Button
                sx={{ marginRight: '1vh' }}
                variant="contained"
                startIcon={<Iconify icon="material-symbols:download" />}
              >
                {t('buttons.download')}
              </Button>
            </a>
            {role === 'ROLE_ADMIN' && (
              <Button
                component="span"
                variant="contained"
                startIcon={<Iconify icon="material-symbols:upload" />}
                onClick={() =>
                  showModal({
                    title: t('inputs.CSV.uploadCSV'),
                    Component: CSVInput,
                    props: {
                      hideModal,
                      csvUpload,
                      maxWidth: true,
                    },
                  })
                }
              >
                {t('buttons.uploadUsers')}
              </Button>
            )}
          </Grid>
        </Stack>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            filterBy={searchBy}
            handleCloseFilter={handleCloseFilter}
            handleOpenFilter={handleOpenFilter}
            onFilterName={handleFilterByName}
            selected={selectedId}
            handleRemoveSelected={handleRemoveSelected}
            deleteFunc={RemoveUser}
            model={t(`nav.${title}`)}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {role === 'ROLE_ADMIN' ? (
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_ADMIN}
                    rowCount={filteredUsers.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    showCheckBox
                    indeterminate={indeterminateCheckbox}
                  />
                ) : (
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_DEPMANAGER}
                    rowCount={filteredUsers.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    indeterminate={indeterminateCheckbox}
                    showCheckBox
                  />
                )}

                <TableBody>
                  {currentPageUsers.map((row, idx) => {
                    const { fullName, appUserRole, email, phoneNumber, status, departmentName, id } = row;
                    const selectedUser = selected.indexOf(email) !== -1;

                    return (
                      <TableRow hover key={idx} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, email, id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {fullName}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{email}</TableCell>
                        {role === 'ROLE_ADMIN' && (
                          <TableCell align="left">
                            {appUserRole === 'ROLE_ADMIN' || appUserRole === 'ROLE_CUSTOMER' ? '' : departmentName}
                          </TableCell>
                        )}

                        <TableCell align="left">{t(`roles.${appUserRole}`)}</TableCell>
                        <TableCell align="left">{phoneNumber}</TableCell>
                        <TableCell align="left">
                          <Label color={(status === 'false' && 'error') || 'success'}>
                            {status === 'true' ? t('userStatus.active') : t('userStatus.inactive')}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            id="user-options-btn"
                            color="inherit"
                            onClick={(e) => handleOpenMenu(e, row)}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {/* If table is empty */}
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph sx={{ color: (theme) => theme.palette.grey[500] }}>
                            {t('filters.notFound')}
                          </Typography>

                          <Typography sx={{ color: (theme) => theme.palette.grey[500] }} variant="body2">
                            {t('filters.noResultsFound', { filterName })}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            labelRowsPerPage={t('filters.rowsPerPage')}
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          id="modify-user-btn"
          onClick={() => {
            setModifyUserModal(true);
            handleCloseMenu();
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('popover.edit')}
        </MenuItem>
        {role === 'ROLE_ADMIN' && showDelete && (
          <MenuItem
            sx={{ color: 'error.main' }}
            id="delete-user-btn"
            onClick={() => {
              showConfirmModal();
              handleCloseMenu();
            }}
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {t('popover.delete')}
          </MenuItem>
        )}
      </Popover>
      {/* main popper */}
      <Popper open={Boolean(filterPopover)} anchorEl={filterPopover} placement="left-start" onClose={handleCloseFilter}>
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {TABLE_HEAD_ADMIN.map(({ id, name }) =>
            name === 'status' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'status'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'status')}
              >
                <Iconify icon="eva:arrow-ios-back-outline" />
                <Typography variant="body2">{t('filters.byStatus')}</Typography>
              </MenuItem>
            ) : name === 'fullName' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === name}
                onMouseEnter={handleCloseSubFilter}
                onClick={() => {
                  setFilterName('');
                  setFilterBy(name);
                  setSearchBy(`byName`);
                  setSelectedFilter(name);
                  handleCloseFilter();
                }}
              >
                <Typography variant="body2">Name</Typography>
              </MenuItem>
            ) : name === 'departmentName' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'department'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'department')}
              >
                <Iconify icon="eva:arrow-ios-back-outline" />
                <Typography variant="body2">{t('filters.byDepartment')}</Typography>
              </MenuItem>
            ) : name === 'appUserRole' ? (
              <MenuItem
                key={id}
                selected={selectedFilter === 'appUserRole'}
                onMouseEnter={(e) => handleOpenSubFilter(e, 'role')}
              >
                <Iconify icon="eva:arrow-ios-back-outline" />
                <Typography variant="body2">{t('filters.byRole')}</Typography>
              </MenuItem>
            ) : (
              <MenuItem
                key={id}
                selected={selectedFilter === name}
                onMouseEnter={handleCloseSubFilter}
                onClick={() => {
                  setFilterName('');
                  setFilterBy(name);
                  setSearchBy(`by${name.charAt(0).toUpperCase() + name.slice(1)}`);
                  setSelectedFilter(name);
                  handleCloseFilter();
                }}
              >
                <Typography variant="body2">{name && name.charAt(0).toUpperCase() + name.slice(1)}</Typography>
              </MenuItem>
            )
          )}
        </Card>
      </Popper>
      {/* sub poppers */}
      <Popper
        open={Boolean(subFilterPopover.status)}
        anchorEl={subFilterPopover.status}
        onClose={handleCloseSubFilter}
        placement="left-start"
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {['true', 'false'].map((status, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setFilterBy('status');
                setSearchBy('byStatus');
                setSelectedFilter('status');
                setFilterName(status);
                handleCloseFilter();
                setPage(0);
              }}
            >
              {status === 'true' ? (
                <Typography>{t('userStatus.active')}</Typography>
              ) : (
                <Typography>{t('userStatus.inactive')}</Typography>
              )}
            </MenuItem>
          ))}
        </Card>
      </Popper>

      <Popper
        open={Boolean(subFilterPopover.role)}
        anchorEl={subFilterPopover.role}
        onClose={handleCloseSubFilter}
        placement="left-start"
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {[
            { value: 'ROLE_CUSTOMER', show: t('roles.ROLE_CUSTOMER') },
            { value: 'ROLE_ADMIN', show: t('roles.ROLE_ADMIN') },
            { value: 'ROLE_DEPARTMENTMANAGER', show: t('roles.ROLE_DEPARTMENTMANAGER') },
            { value: 'ROLE_AGENT', show: t('roles.ROLE_AGENT') },
          ].map((role, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setFilterBy('appUserRole');
                setSearchBy('byRole');
                setSelectedFilter('appUserRole');
                setFilterName(role.value);
                handleCloseFilter();
                setPage(0);
              }}
            >
              {role.show}
            </MenuItem>
          ))}
        </Card>
      </Popper>
      <Popper
        open={Boolean(subFilterPopover.department)}
        anchorEl={subFilterPopover.department}
        placement="left-start"
        onClose={handleCloseSubFilter}
      >
        <Card
          sx={{
            p: 1,
            minwidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              boxShadow: '0.5px 0.5px 2 px',
            },
          }}
        >
          {departmentsData.map((department) => (
            <MenuItem
              key={department.id}
              onClick={() => {
                setFilterBy('departmentName');
                setSearchBy('byDepartment');
                setSelectedFilter('department');
                setFilterName(department.name);
                handleCloseFilter();
                setPage(0);
              }}
            >
              {department.name}
            </MenuItem>
          ))}
        </Card>
      </Popper>
    </>
  );
}
