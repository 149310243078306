import { Navigate, Outlet } from 'react-router-dom';
import FirstLogin from '../pages/Common/FirstLogin';

// layouts

import LoginPage from '../pages/Common/LoginPage';
// ----------------------------------------------------------------------

const AuthRoutes = {
  path: '*',
  element: <Outlet />,
  children: [
    {
      path: 'login',
      element: <LoginPage />,
    },
    { path: 'resetPassword', element: <FirstLogin /> },
    {
      path: '*',
      element: <Navigate to="/login" replace />,
    },
  ],
};

export default AuthRoutes;
