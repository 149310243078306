import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import FormInput from '../../FormInputs/FormInput';
import { useFormContext } from '../../../hooks/useForm';
import { reorderArray } from '../../../utils/filterData';

QuestionView.propTypes = {
  question: PropTypes.object,
  sectionsList: PropTypes.array,
  page: PropTypes.number,
  setSections: PropTypes.func,
  setPage: PropTypes.func,
};

export default function QuestionView({ question, sectionsList, page, setSections, setPage }) {
  const [input, setInput] = useState(question)
  const { getQuestionOptions, modifyQuestion, addFiles, removeFiles } = useFormContext();
  const { t } = useTranslation();
  const [selectedOpt, setSelectedOpt] = useState('');
  const [options, setOptions] = useState(null);

  const onChange = (e) => {
    setSelectedOpt(e.target.value);
    modifyQuestion(question.id, { ...question, inputValue: e.target.value });
  };
  const setSelectedVals = (selectedVals) => {
    modifyQuestion(question.id, { ...question, inputValue: selectedVals });
  };

  const setFormData = (formData) => {
    addFiles(formData);
  };

  const removeAttachements = (attachementId) => {
    removeFiles(attachementId);
  };

  const handleSelect = (value) => {
    setSelectedOpt(value);
    modifyQuestion(question.id, { ...question, inputValue: value });
  };

  useEffect(() => {
    setInput(question)
    const optionsList = getQuestionOptions(question.id);
    setOptions(optionsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.id]);

  const getOptionByValue = (valueToFind) => {
    const option = options?.find((option) => option.value === valueToFind);
    return option || null;
  };
  const getSectionIndexById = (sectionId) => {
    const sectionIndex = sectionsList.findIndex((section) => section.id === sectionId);
    return sectionIndex;
  };

  useEffect(() => {
    if (question.redirectOnAnswer && selectedOpt) {
      const option = getOptionByValue(selectedOpt);
      const sectionId = option?.redirectTo;

      if (sectionId !== undefined) {
        const index = getSectionIndexById(sectionId);
        const newSectionOrder = reorderArray(sectionsList, page, index);
        setSections(newSectionOrder.array);
        setPage(newSectionOrder.currentIndex);
        setSelectedOpt(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOpt, sectionsList]);

  const isCheckboxEmpty = useMemo(
    () => question.type === 'CheckboxInput' && Array.isArray(question.inputValue) && question.inputValue.length === 0
  , [question.type, question.inputValue]);

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title={question.text}
        sx={{ color: question.isRequired && (!question.inputValue || isCheckboxEmpty) ? 'red' : 'initial' }}
      />
      <CardContent>
        <FormInput
          input={input}
          options={options}
          sx={{ mt: '2vh' }}
          handleSelect={handleSelect}
          onChange={onChange}
          setSelectedVals={setSelectedVals}
          setFormData={setFormData}
          removeAttachements={removeAttachements}
        />
        {(!question.inputValue || isCheckboxEmpty) && question.isRequired && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {t('formControl.fieldRequired')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
