import PropTypes from 'prop-types';
import { Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

DepartmentSelect.propTypes = {
  options: PropTypes.array,
  setSelectedDepId: PropTypes.func,
};

export default function DepartmentSelect({ options, setSelectedDepId }) {
  const [department, setDepartment] = useState('');

  useEffect(() => {
    setDepartment(options[0]?.name || '');
  }, [options]);

  const handleChange = (event) => {
    setDepartment(event.target.value);
    setSelectedDepId(options.find((department) => department.name === event.target.value).id);
  };

  return (
    <Select
      id="dashboard-department-select"
      value={department}
      onChange={handleChange}
      sx={{ maxWidth: '25vh', minWidth: '20vh' }}
    >
      {options?.map((department) => (
        <MenuItem key={`department-${department.id}`} value={department.name}>
          {department.name}
        </MenuItem>
      ))}
    </Select>
  );
}
