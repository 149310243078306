import * as React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';

SelectInput.propTypes = {
  defaultValue: PropTypes.node,
  labelValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedInput: PropTypes.func,
  removeSection: PropTypes.func,
};

export default function SelectInput({ defaultValue, labelValue, options, setSelectedInput, ...other }) {
  const [value, setValue] = React.useState(defaultValue || '');

  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedInput(event.target.value);
  };

  return (
    <FormControl {...other}>
      <InputLabel id="select-label">{labelValue}</InputLabel>
      <Select labelId="select" id="select" value={value} label={labelValue} onChange={handleChange}>
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
