import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import secureLocalStorage from 'react-secure-storage';
import { useTheme } from '@emotion/react';
import ChangePassword from '../../components/user/ChangePassword';
import Logo from '../../components/logo';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function FirstLogin() {
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const { userId, oldPassword } = state || {};
  const theme = useTheme();
  const { t } = useTranslation();
  if (!state) {
    secureLocalStorage.removeItem('user');
  }
  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System</title>
      </Helmet>
      <StyledRoot>
        <Grid
          container
          component="main"
          sx={{
            minHeight: '100vh',
            height: '100%',

            background: 'rgb(80 85 127)',
            [theme.breakpoints.up('md')]: {
              paddingTop: '1%',
              paddingX: '10%',
            },
          }}
        >
          <Grid container sx={{ margin: 'auto', background: 'white', minHeight: '85vh' }}>
            <Container>
              <Stack py={5} height="100%" width="100%">
                <Logo
                  alignSelf="center"
                  sx={{
                    width: '40vh',
                    height: '20vh',
                  }}
                />
                <Typography variant="h3" my={7} alignSelf="center">
                  {t('pages.firstLogin.chooseNewPWD')}
                </Typography>
                <Typography variant="h6" color="rgb(80 85 127)">
                  {t('pages.firstLogin.pleaseNewPWD')}
                </Typography>
                <ChangePassword userId={userId} oldPass={oldPassword} token={token} />
              </Stack>
            </Container>
          </Grid>
          <Typography
            spacing={0}
            variant="body1"
            alignSelf="center"
            sx={{
              mt: 2,
              mb: 0,
              color: 'white',
              textAlign: 'center',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('pages.login.poweredBy')}
            <img src="/assets/icons/AlgorLeapLogo.svg" height={75} alt="Algor" />
          </Typography>
        </Grid>
      </StyledRoot>
    </>
  );
}
