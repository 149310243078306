import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Import UUID v4 generator
import PropTypes from 'prop-types';

const FormContext = createContext();

FormProvider.propTypes = {
  children: PropTypes.node,
  initialData: PropTypes.object,
};

export const useFormContext = () => useContext(FormContext);
export function FormProvider({ initialData, children }) {
  const [formData, setFormData] = useState(
    initialData?.formData || {
      id: uuidv4(),
      sections: [],
    }
  );
  const [sections, setSectionData] = useState(initialData?.sections || {});
  const [questions, setQuestionData] = useState(initialData?.questions || {});
  const [options, setOptionData] = useState(initialData?.options || {});
  const [files, setFiles] = useState(new FormData());

  // files uploaded to the Form
  const addFiles = (fileData) => {
    const fileList = files;
    Array.from(fileData.entries()).map(([key, value]) => {
      fileList.append(key, value);
      return true;
    });
    setFiles(fileList);
  };
  const formatAttachements = () => {
    const fileList = new FormData();
    Array.from(files.entries()).map(([, value]) => {
      fileList.append('attachments', value);
      return true;
    });
    return fileList;
  };
  const removeFiles = (attachementId) => {
    const fileList = files;
    fileList.delete(attachementId);
    setFiles(fileList);
  };
  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  // ### Section methods ###
  const addSection = (newSection) => {
    const sectionId = uuidv4();
    setSectionData((prevData) => ({
      ...prevData,
      [sectionId]: { ...newSection, id: sectionId, duration: { days: 0, hours: 1, minutes: 0 } },
    }));
    setFormData((prevData) => ({
      ...prevData,
      sections: [...prevData.sections, sectionId],
    }));
    addQuestion(sectionId, {});
  };

  const modifySection = (sectionId, newSection) => {
    setSectionData((prevData) => ({
      ...prevData,
      [sectionId]: { ...newSection, id: sectionId },
    }));
  };

  const deleteSection = (sectionId) => {
    setFormData((prevData) => {
      const updatedSections = prevData.sections.filter((section) => section !== sectionId);
      return { ...prevData, sections: updatedSections };
    });
    setSectionData((prevData) => {
      const updatedSections = { ...prevData };
      delete updatedSections[sectionId];
      return updatedSections;
    });
  };

  const getOrderedSections = () => {
    const orderedSections = formData.sections.map((section) => sections[section]);
    return orderedSections;
  };
  const removeQuestionAnswers = () => {
    Object.keys(questions).forEach((key) => {
      const entry = questions[key];
      delete entry.inputValue;
    });
  };
  // Function that returns the validity of a section
  const updateSectionValidity = (sectionId) => {
    const section = sections[sectionId];
    if (!section) return false;

    let sectionIsValid = true; // Assume the section is valid initially

    // eslint-disable-next-line no-restricted-syntax
    for (const questionId of section.questions) {
      const question = questions[questionId];
      if (!question) {
        sectionIsValid = false; // Set sectionIsValid to false if a question is not found
        break; // Break the loop, no need to check further
      }

      // Check if the question is requried then we check if it's filled in or not
      if (question.isRequired) {
        // Check if the question's inputValue is empty based on its type
        if (question.type === 'checkbox') {
          // For checkbox type, check if the inputValue is an empty array
          if (!(Array.isArray(question.inputValue) && question.inputValue.length > 0)) {
            sectionIsValid = false; // Set sectionIsValid to false and break if the condition is not met
            break;
          }
        }
        // For other types, check if the inputValue is not empty
        if (!question.inputValue) {
          sectionIsValid = false; // Set sectionIsValid to false and break if the condition is not met
          break;
        }
      }
    }
    // Return the section validity
    return sectionIsValid;
  };

  // ### Question methods ###
  const addQuestion = (sectionId, newQuestion) => {
    const questionId = uuidv4();
    setSectionData((prevData) => {
      const updatedSection = {
        ...prevData[sectionId],
        questions: [...prevData[sectionId].questions, questionId],
      };
      return { ...prevData, [sectionId]: updatedSection };
    });

    setQuestionData((prevData) => ({
      ...prevData,
      [questionId]: { ...newQuestion, id: questionId, options: [], isRequired: true, type: 'TextInput' }, // Include an empty options array
    }));
  };

  const modifyQuestion = (questionId, updatedQuestion) => {
    setQuestionData((prevData) => ({
      ...prevData,
      [questionId]: { ...updatedQuestion, id: questionId },
    }));
  };

  const deleteQuestion = (sectionId, questionId) => {
    setSectionData((prevData) => {
      const updatedSection = {
        ...prevData[sectionId], // Copy the existing section data
        questions: prevData[sectionId].questions.filter((key) => key !== questionId), // Filter out the questionId
      };

      // Create a copy of prevData with the updated section
      return {
        ...prevData,
        [sectionId]: updatedSection,
      };
    });

    setQuestionData((prevData) => {
      const updatedQuestions = { ...prevData }; // Create a copy of prevData
      delete updatedQuestions[questionId]; // Delete the question by key
      return updatedQuestions; // Return the updated object
    });
  };

  const getSectionQuestions = (sectionId) => {
    const selectedQuestions = sections[sectionId]?.questions
      ?.filter((key) =>
        // eslint-disable-next-line no-prototype-builtins
        questions.hasOwnProperty(key)
      )
      .map((key) => questions[key]);
    return selectedQuestions;
  };

  // ### Option methods ###
  const addOption = (questionId, newOption) => {
    const optionId = uuidv4();
    setQuestionData((prevData) => {
      const updatedQuestion = {
        ...prevData[questionId],
        options: [...prevData[questionId].options, optionId],
      };
      return { ...prevData, [questionId]: updatedQuestion };
    });

    setOptionData((prevData) => ({
      ...prevData,
      [optionId]: { ...newOption, id: optionId },
    }));
  };

  const modifyOption = (optionId, updatedOption) => {
    setOptionData((prevData) => ({
      ...prevData,
      [optionId]: { ...updatedOption, id: optionId },
    }));
  };

  const deleteOption = (questionId, optionId) => {
    setQuestionData((prevData) => {
      const updatedQuestion = {
        ...prevData[questionId], // Copy the existing section data
        questions: prevData[questionId].options.filter((key) => key !== optionId), // Filter out the questionId
      };

      // Create a copy of prevData with the updated section
      return {
        ...prevData,
        [optionId]: updatedQuestion,
      };
    });

    setOptionData((prevData) => {
      const updatedOptions = { ...prevData }; // Create a copy of prevData
      delete updatedOptions[optionId]; // Delete the question by key
      return updatedOptions; // Return the updated object
    });
  };

  const getQuestionOptions = (questionId) => {
    const selectedOptions = questions[questionId]?.options
      ?.filter((key) =>
        // eslint-disable-next-line no-prototype-builtins
        options.hasOwnProperty(key)
      )
      .map((key) => options[key]);
    return selectedOptions;
  };

  const getFormData = () => ({ formData, sections, questions, options, files });

  const updateSectionQuestions = (sectionId, ordredQuestions) => {
    setSectionData((prevData) => ({
      ...prevData,
      [sectionId]: {
        ...prevData[sectionId],
        questions: ordredQuestions.map((question) => question.id),
      },
    }));
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        sections,
        questions,
        files,
        addFiles,
        removeFiles,
        formatAttachements,
        updateFormData,
        addQuestion,
        modifyQuestion,
        deleteQuestion,
        getSectionQuestions,
        addOption,
        modifyOption,
        deleteOption,
        addSection,
        modifySection,
        deleteSection,
        getOrderedSections,
        updateSectionValidity,
        getQuestionOptions,
        getFormData,
        removeQuestionAnswers,
        updateSectionQuestions,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}
