function formatDate(date) {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Intl.DateTimeFormat('en-UK', options).format(date);
}

function formatRelativeTime(date, today, yesterday, at) {
  const now = new Date();
  const dateToCheck = new Date(date);

  if (now.toDateString() === dateToCheck.toDateString()) {
    return `${today} ${at} ${dateToCheck.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  now.setDate(now.getDate() - 1);
  if (now.toDateString() === dateToCheck.toDateString()) {
    return `${yesterday} ${at} ${dateToCheck.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  return `${formatDate(dateToCheck)} ${at} ${dateToCheck.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
}
export default formatRelativeTime;
