import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputLabel, Button } from '@mui/material';

ImageInput.propTypes = {
  handleChange: PropTypes.func,
  existingFile: PropTypes.string,
  selectedFile: PropTypes.object,
};
function ImageInput({ handleChange, selectedFile, existingFile }) {
  const { t } = useTranslation();
  return (
    <div>
      {selectedFile ? (
        <img
          src={URL.createObjectURL(selectedFile)}
          alt="Selected"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '100%',
            maxHeight: '200px',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        />
      ) : existingFile ? (
        <img
          src={existingFile}
          alt="Selected"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '100%',
            maxHeight: '200px',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        />
      ) : (
        <></>
      )}
      <InputLabel>
        <Button fullWidth component="span" variant="outlined">
          {t('buttons.chooseImage')}
        </Button>

        <input
          hidden
          accept="image/*"
          style={{ display: 'none' }}
          id="image-file-input"
          type="file"
          onChange={handleChange}
        />
      </InputLabel>
    </div>
  );
}

export default ImageInput;
