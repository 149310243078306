import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import convertToBase64 from '../../utils/convertBase64';
import compressImage from '../../utils/imageCompressor';
import ImageInput from '../inputs/ImageInput';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';

AddDepartment.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  addDepartment: PropTypes.func,
};
export default function AddDepartment({ setOpen, addDepartment, open }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      image: '',
      file: '',
    },
  });
  const { t } = useTranslation();
  const { showToast, hideToast } = useToast();
  const { id } = useAuth();
  const maxSize = 1024 * 1024 * 2; // 2MB maximum file size
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];

  const [selectedFile, setSelectedFile] = useState(null);

  const handleClose = () => {
    reset();
    setSelectedFile(null);
    setOpen(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setSelectedFile(file);
      } else if (!allowedTypes.includes(file.type)) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImage'),
          severity: 'error',
          props: { hideToast },
        });
      } else if (file.size > maxSize) {
        setSelectedFile(null);
        showToast({
          message: t('formControl.validImageSize'),
          severity: 'error',
          props: { hideToast },
        });
      }
    }
  };

  const onSubmit = async (data) => {
    const callBackFunc = (convertedFile) => {
      const fullData = { ...data, createdBy: id, image: convertedFile };
      addDepartment(fullData);
      reset();
      handleClose();
    };
    if (selectedFile) {
      if (selectedFile.type === 'image/gif') convertToBase64(selectedFile).then(callBackFunc);
      else compressImage(selectedFile, callBackFunc);
    } else {
      const fullData = { ...data, createdBy: id };
      addDepartment(fullData);
      reset();
      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <DialogTitle id="modal--title" variant="h6" component="h2">
          {t('pages.departmentPage.form.createDepartment')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <br />
              <TextField
                name="name"
                id="department-name"
                label={t('attributes.name')}
                error={!!errors.name}
                helperText={errors.name?.message || ''}
                {...register('name', {
                  required: t('formControl.enterName'),
                  minLength: {
                    value: 4,
                    message: t('formControl.minName'),
                  },
                  maxLength: {
                    value: 50,
                    message: t('formControl.maxName'),
                  },
                })}
              />

              <TextField
                multiline
                id="department-description"
                minRows={4}
                name="description"
                label={t('attributes.description')}
                error={!!errors.description}
                helperText={errors.description?.message || ''}
                {...register('description', {
                  // required: t('formControl.enterDescription'),
                  // minLength: {
                  //   value: 10,
                  //   message: t('formControl.minDescription'),
                  // },
                })}
              />

              <ImageInput handleChange={handleFileChange} selectedFile={selectedFile} />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <LoadingButton
                id="new-dep-btn"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!isValid}
              >
                {t('buttons.addDepartment')}
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
