import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
// @mui
import { Grid, Container, Typography } from '@mui/material';
import { fetchTicketsByDate } from '../../services/ticket.service';
import { fetchStatuses } from '../../services/status.service';

// sections
import { AppWidgetSummary } from '../../sections/@dashboard/Charts';
import { DashboardToolbar } from '../../sections/@dashboard/toolbar';
// hooks
import { useAuth } from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const { t } = useTranslation();
  const { username, id } = useAuth();
  const [ticketsData, setTicketsData] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [ticketStatusCount, setTicketStatusCount] = useState({ open: 0, onhold: 0, resolved: 0, closed: 0 });
  const start = new Date(secureLocalStorage.getItem('ticketStart'));
  const end = new Date(secureLocalStorage.getItem('ticketEnd'));

  const getStatuses = (depId) => {
    if (depId === 0) {
      fetchStatuses().then((res) => setStatusesList(res));
    } else {
      fetchStatuses().then((res) =>
        setStatusesList(res.filter((status) => status.departmentId === depId || status.departmentId === 0))
      );
    }
  };

  const getTicketsByDate = async ({ startDate, endDate, attributeName }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 0);
    const tickets = await fetchTicketsByDate({ startDate: start, endDate: end, attributeName });
    const updatedTickets = tickets.map((ticket) => {
      const statusObject = statusesList.find((status) => status.statusId === ticket.status_id);
      return { ...ticket, statusObject };
    });
    setTicketsData(updatedTickets.filter((ticket) => ticket.createdBy === id));
  };
  useEffect(() => {
    getTicketsByDate({ startDate: start, endDate: end, attributeName: 'created' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesList]);
  useEffect(() => {
    setTicketStatusCount({
      open: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'OPEN').length || 0,
      onhold: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'ONHOLD').length || 0,
      resolved: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'RESOLVED').length || 0,
      closed: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'CLOSED').length || 0,
      unassigned: ticketsData.filter((ticket) => ticket.statusObject?.systemMappedStatus === 'UNASSIGNED').length || 0,
    });
  }, [ticketsData]);
  useEffect(() => {
    getStatuses(0);
  }, []);

  return (
    <>
      <Helmet>
        <title> Oryx One Ticketing System </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('dashboard.greeting')} {username || ''}!
        </Typography>
        <DashboardToolbar getTicketsByDate={getTicketsByDate} start={start} end={end} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.totalTickets')}
              total={ticketsData?.length || 0}
              icon={'raphael:customer'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.resolvedTickets')}
              total={ticketStatusCount.resolved}
              color="info"
              icon={'mdi:face-agent'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.openTickets')}
              total={ticketStatusCount.open}
              color="warning"
              icon={'ph:ticket'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboard.closedTickets')}
              total={ticketStatusCount.closed}
              color="error"
              icon={'system-uicons:ticket'}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
