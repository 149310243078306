import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, Link, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { EMAIL_REGEX } from '../../utils/regex';
import { useToast } from '../../hooks/useToast';
import { generatePasswordUsingToken } from '../../services/user.service';

ForgotPassword.propTypes = {
  toggleForgotPassword: PropTypes.func,
};
export default function ForgotPassword({ toggleForgotPassword }) {
  const HoverableText = styled('p')({
    cursor: 'pointer',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReturnToLogin = () => {
    toggleForgotPassword();
    reset();
  };
  const { showToast, hideToast } = useToast();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (data) => {
    generatePasswordUsingToken(data.email)
      .then(() => {
        showToast({
          message: t('successMessages.successSendingPasswordResetEmail'),
          severity: 'success',
          props: { hideToast },
        });
        navigate('/login', { replace: true });
      })
      .catch(() => {
        showToast({ message: t('errorMessages.resetPasswordError'), severity: 'error', props: { hideToast } });
      });
  };

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Typography variant="h3" alignSelf="center" mb={6} gutterBottom>
        {t('pages.login.resetPassword')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <br />

          <TextField
            name="email"
            label={t('pages.usersPage.form.emailAdress')}
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message || ''}
            {...register('email', {
              required: t('formControl.enterEmail'),
              pattern: {
                value: EMAIL_REGEX,
                message: t('formControl.invalidEmail'),
              },
            })}
          />
        </Stack>
        <Link variant="subtitle2" underline="hover" onClick={handleReturnToLogin}>
          <HoverableText> {t('pages.login.backToLogin')} </HoverableText>
        </Link>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!isValid}>
            {t('pages.login.resetPassword')}
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
}
