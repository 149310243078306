import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import DepartmentCard from './DepartmentCard';

// ----------------------------------------------------------------------

DepartmentsList.propTypes = {
  departments: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
  itemsPerPage: PropTypes.number,
  page: PropTypes.number,
  editDepartment: PropTypes.func,
};

export default function DepartmentsList({ departments, handleDelete, itemsPerPage, page, editDepartment, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {departments.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map((department) => (
        <Grid key={department.id} item xs={12} sm={6} md={4}>
          <DepartmentCard department={department} handleDelete={handleDelete} editDepartment={editDepartment} />
        </Grid>
      ))}
    </Grid>
  );
}
