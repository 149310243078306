import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

UpdateMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  time: PropTypes.string,
  statusesList: PropTypes.array,
  user: PropTypes.object,
};
export default function UpdateMessage({ time, statusesList, user, message }) {
  const { t } = useTranslation();
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    if (message) {
      const messageObject = JSON.parse(message);

      const getStatusName = (statusId) => {
        const status = statusesList.find((status) => status.statusId === statusId);
        return parseInt(statusId, 10) <= 5 ? t(`status.${status?.name}`) : status?.name;
      };

      const updatesList = Object.keys(messageObject).map((key) => {
        const entry = messageObject[key];
        let prevVal = entry.previousValue;
        let newVal = entry.newValue;

        switch (key) {
          case 'Status':
            prevVal = getStatusName(prevVal);
            newVal = getStatusName(newVal);
            if (prevVal === 'status.undefined' || newVal === 'status.undefined') {
              return undefined;
            }
            break;
          case 'Priority':
            prevVal = t(`priority.${prevVal}`);
            newVal = t(`priority.${newVal}`);
            break;
          case 'Description':
            return t('pages.ticketDetails.ticketUpdateDescriptionLog', { user: user?.username || '' });
          default:
            break;
        }

        return t('pages.ticketDetails.ticketUpdateLog', {
          user: user?.username || '',
          attribute: t(`attributes.${key.toLowerCase()}`),
          prevValue: prevVal,
          newValue: newVal,
        });
      });

      setUpdates(updatesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, statusesList, t]);

  return (
    <Stack justifyContent="space-between">
      <Box textAlign="center">
        {!(updates.length === 0) && (
          <Typography color="#4a4a4a" variant="caption" textAlign="center">
            {time}
          </Typography>
        )}
        {updates &&
          updates.map((updateMessage, index) => (
            <div key={index}>
              <Typography variant="caption">{updateMessage}</Typography>
            </div>
          ))}
      </Box>
    </Stack>
  );
}
