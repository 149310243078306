import axios from 'axios';
import { getAuthorizationHeader } from '../utils/getAuthHeader';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8081'}`;

const url = `${API_URL}/forms`;

// get forms
export const fetchForms = async () => {
  const result = await axios.get(url, { headers: getAuthorizationHeader() });
  return result.data;
};

// Create Form
export const createForm = async ({ title, description, createdBy, departmentId, formData }) => {
  const result = await axios.post(
    `${url}/add`,
    { title, description, createdBy, departmentId, formData },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

// update Form
export const modifyForm = async ({ id, title, description, createdBy, departmentId, formData }) => {
  const result = await axios.put(
    `${url}/${id}`,
    { title, description, createdBy, departmentId, formData },
    { headers: getAuthorizationHeader() }
  );
  return result.data;
};

export const deleteForm = async (id) => {
  const result = await axios.delete(`${url}/${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};

// Get form by department
export const fetchFormsByDepartment = async (id) => {
  const result = await axios.get(`${url}/by-department/${id}`, { headers: getAuthorizationHeader() });
  return result.data;
};
